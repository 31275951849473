window.openRecoveryPass = () => window.openModal(".modal.recovery-pass");

const recoveryPassModal = document.querySelector(".modal.recovery-pass");

function onRecovery(event) {
    event.preventDefault();
    const recoveryButton = recoveryPassModal.querySelector(".recovery-pass__recovery");
    recoveryButton.disabled = true;
    recoveryPassModal.classList.add("recovery-pass--success");
    recoveryButton.disabled = false;
}

export function initRecoveryPass() {
    if (!recoveryPassModal) return;

    const backButton = recoveryPassModal.querySelector(".recovery-pass__back");

    const recoveryForm = recoveryPassModal.querySelector(".recovery-pass__form");

    backButton.addEventListener("click", window.openSignIn);

    recoveryForm.addEventListener("submit", onRecovery);
}
