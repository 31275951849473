import { checker } from "./ai-agrees-checker/index.js";
import { anketa } from "./ai-anketa/index.js";
import { aiModal } from "./ai-modal/index.js";
import { result } from "./ai-result/index.js";

export function initAiSkinPageModules() {
    checker();
    anketa();
    result();
    aiModal();
}
