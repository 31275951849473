export function initSegmentControl() {
    const tabsContainer = document.querySelector(".segmented-control");

    if (!tabsContainer) return;

    tabsContainer.addEventListener("click", (event) => {
        const clickedTab = event.target.closest(".segmented-control__button");
        if (!clickedTab) return;

        const tabId = clickedTab.dataset.tab;
        const tabsButtons = tabsContainer.querySelectorAll(
            ".segmented-control__button"
        );
        const tabsPanels = tabsContainer.querySelectorAll(
            ".segmented-control__panel"
        );

        tabsButtons.forEach((button) => {
            button.classList.toggle(
                "segmented-control__button--active",
                button === clickedTab
            );
        });

        tabsPanels.forEach((panel) => {
            panel.classList.toggle(
                "segmented-control__panel--active",
                panel.dataset.tabContent === tabId
            );
        });
    });
}

