/* eslint-disable indent */
import { generateProductCardHTML } from "./getProduct.js";

/**
 *
 * @typedef {{
*          title: string,
*          categories: {
*              [key: string]: {id:number, products: Product[]}
*          }
*      }} CatalogMorning
*/

/**
 *
 * @param {CatalogMorning} catalog
 */
export function getCatalogWithCategories(catalog) {
    // return `
    // <div class="skintest__recommend-list">
    //         <h2 class="h2">${catalog.title}</h2>
    //         <div class="choose-cosmetics">
    //             <div class="tabs product-list-slider-with-tabs" id="product-list-slider-with-tabs-0">
    //                 <div class="tabs__tab-list-wrapper product-list-slider-with-tabs__tab-list-wrapper">
    //                     <ul class="tabs__tab-list product-list-slider-with-tabs__tab-list">
    //                         ${
    //                             Object.entries(catalog.categories).map(([key, value], index) => `
    //                                 <li data-value="${value.id}"
    //                                     class="product-list-slider-with-tabs__tab-list-item ${index === 0 ? "tabs__tab-list-item--selected" : ""}">
    //                                     <button class="tabs__tab-button">
    //                                         ${key}
    //                                     </button>
    //                                 </li>
    //                             `).join(" ")
    //                         }
    //                     </ul>
    //                     <i class="tabs__indicator product-list-slider-with-tabs__indicator"
    //                         style="width: 191.5px; translate: 0px;"></i>
    //                 </div>

    //                 <ul
    //                     class="tabs__tab-panel-list product-list-slider-with-tabs__tab-panel-list @@panel_list_class">
                        // ${
                        //     Object.entries(catalog.categories).map(([key, value]) => `
                        //     <li data-value="${value.id}"
                        //         class="tabs__tab-panel-item--show product-list-slider-with-tabs__tab-panel-item"
                        //         id="product-list-slider-with-tabs__tab-panel-item-0">
                        //         <div class="product-list-slider-with-tabs__tab-panel-item-wrapper">
                        //             <ul
                        //                 class="swiper product-list-slider-with-tabs__product-list swiper-initialized swiper-horizontal swiper-backface-hidden">
                        //                 <div class="swiper-wrapper product-list-slider-with-tabs__product-list-wrapper"
                        //                     style="transition-duration: 0ms; transform: translate3d(0px, 0px, 0px); transition-delay: 0ms;">
                        //                     ${value.products.map((product) => generateProductCardHTML({
                        //                         ...product,
                        //                         class: "product-list-slider-with-tabs__product-list-item catalog-medicine-product__item",
                        //                     })).join(" ")}
                        //                 </div>
                        //             </ul>
                        //             <div class="slider-navigation product-list-slider-with-tabs__arrows">
                        //                 <button
                        //                     class="slider-navigation-prev product-list-slider-with-tabs-arrows-left swiper-button-disabled"
                        //                     disabled="">
                        //                     <i class="icon-arrow-left"></i>
                        //                 </button>
                        //                 <button
                        //                     class="slider-navigation-next product-list-slider-with-tabs-arrows-right">
                        //                     <i class="icon-arrow-right"></i>
                        //                 </button>
                        //             </div>
                        //         </div>
                        //     </li>
                            
                        //     `).join(" ")
                        // }
    //                 </ul>
    //             </div>

    //         </div>

    //     </div>
    // `;
    return `
    <div class="skintest__recommend-list">
            <h2 class="h2">${catalog.title}</h2>
            <div class="choose-cosmetics">
                <div class="tabs product-list-slider-with-tabs @@class" id="product-list-slider-with-tabs-0">
                    <div class="tabs__tab-list-wrapper product-list-slider-with-tabs__tab-list-wrapper">
                        <ul class="tabs__tab-list product-list-slider-with-tabs__tab-list">
                            ${
                                Object.entries(catalog.categories).map(([key, value], index) => `
                                    <li data-value="${value.id}"
                                        class="product-list-slider-with-tabs__tab-list-item tabs__tab-list-item ${index === 0 ? "tabs__tab-list-item--selected" : ""}">
                                        <button class="tabs__tab-button">
                                            ${key}
                                        </button>
                                    </li>
                                `).join(" ")
                            }
                        </ul>
                        <i class="tabs__indicator product-list-slider-with-tabs__indicator"
                            style="width: 191.5px; translate: 0px;"></i>
                    </div>

                    <ul
                        style="margin-top: 24px;" class="tabs__tab-panel-list product-list-slider-with-tabs__tab-panel-list @@panel_list_class">

                        ${
                            Object.entries(catalog.categories).map(([key, value], index) => `
                            <li data-value="${value.id}"
                                class="tabs__tab-panel-item ${index === 0 ? "tabs__tab-panel-item--show" : ""} product-list-slider-with-tabs__tab-panel-item"
                                id="product-list-slider-with-tabs__tab-panel-item-0">
                                <div class="product-list-slider-with-tabs__tab-panel-item-wrapper">
                                    <ul
                                        class="swiper product-list-slider-with-tabs__product-list swiper-initialized swiper-horizontal swiper-backface-hidden">
                                        <div class="swiper-wrapper product-list-slider-with-tabs__product-list-wrapper"
                                            style="transition-duration: 0ms; transform: translate3d(0px, 0px, 0px); transition-delay: 0ms;">
                                            ${value.products.length > 0? value.products.map((product) => generateProductCardHTML({
                                                ...product,
                                                class: "product-list-slider-with-tabs__product-list-item catalog-medicine-product__item",
                                            })).join(" ")
                                            : ""
                                        }
                                        </div>
                                    </ul>
                                    <div class="slider-navigation product-list-slider-with-tabs__arrows">
                                        <button
                                            class="slider-navigation-prev product-list-slider-with-tabs-arrows-left"
                                            >
                                            <i class="icon-arrow-left"></i>
                                        </button>
                                        <button
                                            class="slider-navigation-next product-list-slider-with-tabs-arrows-right">
                                            <i class="icon-arrow-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </li>
                            
                            `).join(" ")
                        }
                    </ul>
                </div>

            </div>

        </div>
    `;
}
