import { BaseYMap } from "../../modules/ymap/index.js";

/**
 * на страницу с картой добавить тег
    <script src="https://api-maps.yandex.ru/1.1/index.xml" type="text/javascript"></script>
 *
 */
export function initContactsMap() {
    const mapContainer = document.querySelector("#contacts__map");

    if (!mapContainer) return;

    const map = new BaseYMap(mapContainer, {
        center: [37.62616, 55.646685],
        zoom: 14,
    });

    map.addMark([37.62616, 55.646685]);
    map.addBallon([37.62615, 55.647280], "1-ый Варшавский проезд, дом 2, стр. 8, офис 411");
}
