import Swiper from "swiper";
import clamp from "clamp-js";

/**
 *
 * @param {HTMLElement} wrapper element with class "category-articles-slider__article"
 */
function clampArticleLines(article) {
    function clampText(maxLinesCount) {
        const titleEl = article.querySelector(".category-articles-slider__article-title");

        if (!titleEl) return;

        const lineHeight = Number(window.getComputedStyle(titleEl).lineHeight.slice(0, -2));

        const titleLinesCount = titleEl.offsetHeight / lineHeight;

        const descEl = article.querySelector(".category-articles-slider__article-description");

        if (!descEl) return;

        const clampCount = maxLinesCount - titleLinesCount;

        if (clamp <= 0) return;

        clamp(descEl, { clamp: clampCount });
    }

    function mediaChange(mediaQuery) {
        if (mediaQuery.matches) {
            clampText(4);
        } else {
            clampText(8);
        }
    }

    const mediaQuery = window.matchMedia("(min-width: 992px)");

    mediaQuery.addEventListener("change", mediaChange);

    mediaChange(mediaQuery);
}

/**
 *
 * @param {HTMLElement} wrapper element with class "category-articles-slider"
 */
function initSlider(wrapper) {
    const id = wrapper.id;

    const articles = wrapper.querySelectorAll(".category-articles-slider__article");

    articles.forEach(clampArticleLines);

    return new Swiper(`#${id} .category-articles-slider__articles-list`, {
        slidesPerView: "auto",
        spaceBetween: 12,
        slideClass: "category-articles-slider__article",
        navigation: {
            prevEl: `#${id} .category-articles-slider-arrows-left`,
            nextEl: `#${id} .category-articles-slider-arrows-right`,
        },
        breakpoints: {
            992: {
                spaceBetween: 24,
                slidesPerView: 3,
            },
        },
    });
}

export function initCategoryArticlesSliders() {
    const wrappers = document.querySelectorAll(".category-articles-slider");

    wrappers.forEach((wrapper, index) => {
        wrapper.id = `category-articles-slider-${index}`;
        initSlider(wrapper);
    });
}
