/**
 *
 * @param {HTMLElement} group element with class "toggle-button-group";
 */
export function initToggleButtonGroup(group) {
    if (!group.tagName || !group.classList.contains("toggle-button-group")) return;
    const buttons = group.querySelectorAll(".toggle-button-group__button");
    const hiddenCountElem = group.querySelector(".toggle-button-group__hidden-count");

    let hiddenButtonsCount = 0;
    /**
     *
     * @param {Event} event
     */
    function onClick(event) {
        const currButton = event.currentTarget;

        const changeEvent = new CustomEvent("toggle-button-group-change", {
            detail: {
                targetButton: currButton,
            },
        });

        group.dispatchEvent(changeEvent);

        buttons.forEach((btn) => {
            if (btn === currButton) {
                btn.classList.add("toggle-button-group__button--selected");
            } else {
                btn.classList.remove("toggle-button-group__button--selected");
            }
        });
    }

    // function will appear counter of hidden buttons
    // if count of hidden buttons is 0, counter is not displayed
    function changeHiddenButtonsCount(e) {
        if (!hiddenCountElem) return;

        hiddenButtonsCount = 0;

        buttons.forEach((btn) => {
            if (!btn.classList.contains("toggle-button-group__hidden-count")) {
                const isHidden = getComputedStyle(btn).display === "none";
                if (isHidden) {
                    hiddenButtonsCount += 1;
                }
            }
        });
        if (hiddenButtonsCount === 0) {
            hiddenCountElem.style.display = "none";
        } else {
            hiddenCountElem.style.display = "flex";
            hiddenCountElem.querySelector("span").innerText = `+${hiddenButtonsCount}`;
        }
    }

    buttons.forEach((btn) => {
        btn.addEventListener("click", onClick);
    });

    const mediaQuery = window.matchMedia("(min-width: 992px)");

    mediaQuery.addEventListener("change", changeHiddenButtonsCount);

    changeHiddenButtonsCount();
}

export function initToggleButtonGroups() {
    const groups = document.querySelectorAll(".toggle-button-group");

    groups.forEach(initToggleButtonGroup);
}
