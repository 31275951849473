import toastr from "toastr";
import Cookies from "js-cookie";

const closeButton = `
    <button class="button--ghost--light notification__close">
        <i class="icon-close"></i>
    </button>
`;

const defaultOptions = {
    closeButton: true,
    toastClass: "notification",
    positionClass: "toast-bottom-right",
    titleClass: "notification__title",
    iconClasses: {
        error: "notification--error",
        info: "notification--info",
        success: "notification--success",
        warning: "notification--warning",
    },
    closeHtml: closeButton,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "5000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
};

function notifyAboutCookies() {
    if (Cookies.get("cookie-agree")) return;

    const optionsOverride = {
        tapToDismiss: false,
        timeOut: 0,
        extendedTimeOut: 0,
    };

    const notificationCookie = toastr.info(
        `
            <div class="notification--cookie-consent">
                <p class="p notification__description">
                    Мы используем файлы cookie, чтобы улучшить ваше впечатление от сайта и сделать его комфортным.
                </p>
                <button class="button--ghost notification__button">
                    Принять и закрыть
                </button>
            </div>
        `,
        "Используем куки и рекомендательные технологии",
        optionsOverride,
    );

    function accept() {
        toastr.remove(notificationCookie);
        notificationCookie.remove();
        Cookies.set("cookie-agree", "true");
    }

    const acceptButton = notificationCookie[0].querySelector(".notification__button");

    acceptButton.addEventListener("click", accept);
}

function notifyAboutCartAdd() {
    const optionsOverride = {
        timeOut: 3000,
        extendedTimeOut: 3000,
        positionClass: "toast-top-right",
        containerId: "toast-top-right",
        closeHtml: null,
    };

    const cartBtns = document.querySelectorAll(".add-to-cart");

    cartBtns.forEach((cartBtn) => {
        cartBtn.addEventListener("click", () => {
            const notificationCardAdd = toastr.success(
                "",
                " Товар добавлен в корзину",
                optionsOverride,
            );
        });
    });
}

function notifyProfileOrder() {
    const optionsOverride = {
        timeOut: 3000,
        extendedTimeOut: 3000,
        positionClass: "toast-top-right",
        containerId: "toast-top-right",
        closeHtml: null,
        closeButton: false,
    };

    const btns = document.querySelectorAll(".notification-btn-success");
    if (!btns) return;
    btns.forEach((btn) => {
        btn.addEventListener("click", () => {
            const title = btn.getAttribute('data-title');
            const subTitle = btn.getAttribute('data-subTitle');
            const notificationCardAdd = toastr.success(
                subTitle,
                title,
                optionsOverride,
            );
        });
    });
}

export function notifyAboutSuccessAppointment(text) {
    const optionsOverride = {
        timeOut: 3000,
        extendedTimeOut: 3000,
        positionClass: "toast-top-right",
        containerId: "toast-top-right",
        closeHtml: null,
    };

    const notificationAppointmentSuccess = toastr.success(
        text,
        "Вы записались на приём",
        optionsOverride,
    );
}

function notifyAboutCartClear() {
    const optionsOverride = {
        timeOut: 3000,
        extendedTimeOut: 3000,
        positionClass: "toast-top-right",
        containerId: "toast-top-right",
        closeHtml: null,
    };

    const deleteBtn = document.querySelector("#PopupCartDeleteAll .button.delete");

    deleteBtn?.addEventListener("click", () => {
        const notificationAboutCartClear = toastr.success(
            "",
            "Все товары удалены из корзины",
            optionsOverride,
        );
    });
}
function notifyAboutCartFavourite() {
    const optionsOverride = {
        timeOut: 3000,
        extendedTimeOut: 3000,
        positionClass: "toast-top-right",
        containerId: "toast-top-right",
        closeHtml: null,
    };

    const deleteBtn = document.querySelector("#PopupCartDeleteAll .button.favourite");

    deleteBtn?.addEventListener("click", () => {
        const notificationAboutCartFavourite = toastr.success(
            "",
            "Товары перемещены в избранное",
            optionsOverride,
        );
    });
}
function notifyAboutCartItemDelete() {
    const optionsOverride = {
        timeOut: 3000,
        extendedTimeOut: 3000,
        positionClass: "toast-top-right",
        containerId: "toast-top-right",
        closeHtml: null,
    };

    const deleteBtn = document.querySelector("#PopupCartDeleteItem .button.delete");

    deleteBtn?.addEventListener("click", () => {
        const notificationAboutCartItemDelete = toastr.success(
            "",
            "Товар удалён из корзины",
            optionsOverride,
        );
    });
}
function notifyAboutCartItemFavourite() {
    const optionsOverride = {
        timeOut: 3000,
        extendedTimeOut: 3000,
        positionClass: "toast-top-right",
        containerId: "toast-top-right",
        closeHtml: null,
    };

    const deleteBtn = document.querySelector("#PopupCartDeleteItem .button.favourite");

    deleteBtn?.addEventListener("click", () => {
        const notificationAboutCartItemFavourite = toastr.success(
            "",
            "Товар перемещен в избранное",
            optionsOverride,
        );
    });
}

export function notifyAboutProductSubscription() {
    const optionsOverride = {
        timeOut: 3000,
        extendedTimeOut: 3000,
        positionClass: "toast-top-right",
        containerId: "toast-top-right",
        closeHtml: null,
    };

    toastr.success(
        "Мы пришлём уведомление о поступлении товара в продажу на Mariya1984@gmail.com",
        "Вы подписаны на товар",
        optionsOverride,
    );
}

export function notifyAboutMasterClassSubscription() {
    const optionsOverride = {
        timeOut: 3000,
        extendedTimeOut: 3000,
        positionClass: "toast-top-right",
        containerId: "toast-top-right",
        closeHtml: null,
    };

    toastr.success(
        "Мы перезвоним по указанному номеру телефона для подтверждения регистрации в течении часа.",
        "Вы записались на мастер-класс",
        optionsOverride,
    );
}

export function notifyAboutReserveMasterClassSubscription() {
    const optionsOverride = {
        timeOut: 3000,
        extendedTimeOut: 3000,
        positionClass: "toast-top-right",
        containerId: "toast-top-right",
        closeHtml: null,
    };

    toastr.success(
        "Мы перезвоним по указанному номеру телефона, если место освободиться",
        "Вы записались в резерв на мастер-класс",
        optionsOverride,
    );
}

export function notifyWithText(text) {
    const optionsOverride = {
        timeOut: 3000,
        extendedTimeOut: 3000,
        positionClass: "toast-top-right",
        containerId: "toast-top-right",
        closeHtml: null,
    };

    const notification = toastr.success(
        "",
        text,
        optionsOverride,
    );
}

export function notifyErrorWithText(text) {
    const optionsOverride = {
        timeOut: 3000,
        extendedTimeOut: 3000,
        positionClass: "toast-top-right",
        containerId: "toast-top-right",
        closeHtml: null,
    };

    const notification = toastr.error(
        "",
        text,
        optionsOverride,
    );
}
export function initNotifications() {
    toastr.options = defaultOptions;

    notifyAboutCookies();
    notifyAboutCartAdd();
    notifyProfileOrder();
    notifyAboutCartClear();
    notifyAboutCartFavourite();
    notifyAboutCartItemDelete();
    notifyAboutCartItemFavourite();
}
