export const initSwitchBtn = () => {
  const modal = document.getElementById("profile-add-address");
  if (!modal) return;

  const switchBtn = modal.querySelector(".js-input-switch");
  const building = modal.querySelector(".js-building");

  switchBtn.addEventListener("click", function () {

    if (switchBtn.checked) {
      building.style.display = "none";
    } else {
      building.style.display = "grid";
    }
  });
};