/* eslint-disable no-restricted-syntax */
export function initDetailAccordion() {
    const accordionContainer = document.querySelector(".detail__accordion");

    if (!accordionContainer) return;

    const accordionItems = accordionContainer.querySelectorAll(".detail__accordion-item");

    accordionItems.forEach((item) => {
        const name = item.querySelector(".detail__accordion-item-name");
        // const icon = item.querySelector("detail__accordion-item-icon");
        const content = item.querySelector(".detail__accordion-item-content");

        const inner = content.querySelector(".detail__accordion-item-inner");

        let active = item.classList.contains("detail__accordion-item--active");

        const resizeObserver = new ResizeObserver((entries) => {
            active = item.classList.contains("detail__accordion-item--active");

            if (active && window.innerWidth < 768) content.style.maxHeight = `${content.scrollHeight}px`;
        });

        if (inner) {
            resizeObserver.observe(inner);
        }
        // let timer = null;

        if (active && window.innerWidth < 768) content.style.maxHeight = `${content.scrollHeight}px`;

        name.addEventListener("click", (event) => {
            event.preventDefault();

            if (window.innerWidth >= 768) return;

            // clearTimeout(timer);

            active = !active;

            if (active) {
                content.style.maxHeight = `${content.scrollHeight}px`;
            } else {
                content.style.maxHeight = 0;
            }
        });
    });
}
