import Swiper from "swiper";

export function initSupportItems() {
    const slider = document.querySelector(".support-distribution__items .swiper");

    if (!slider) return;

    return new Swiper(slider, {
        slidesPerView: "auto",
        spaceBetween: 16,
        breakpoints: {
            992: {
                spaceBetween: 22
            }
        }
    });
}