/**
 *
 * @param {import("../../../services/api/endpoints/map").MapResponseFeature} feature
 * @returns
 */
export function generateGallerySlidesByFeature(feature) {
    const galleryEl = document.querySelector(".slider.js-slider-photos-slider .slider-wrapper");

    if (!galleryEl) return null;

    return feature.gallery.map((imageSrc, i) => {
        const reg = /(?:\.([^.]+))?$/;
        const extension = reg.exec(imageSrc.toLowerCase())[1];

        if (extension === "mp4") {
            return `
                <li class="card swiper-slide slider-slide js-slider-photos-slid">
                    <video controls>
                        <source src="${imageSrc}">
                    </video>
                </li>
            `;
        }
        return `
            <li class="card swiper-slide slider-slide js-slider-photos-slid">
                <picture>
                    <img src="${imageSrc}" alt="Slide ${i}" class="img">
                </picture>
            </li>
        `;
    }).join(" ");
}
