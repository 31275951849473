/* eslint-disable no-use-before-define */
import { SignIn } from "../../../sign-in/index.js";

window.openSignIn = () => window.openModal(".modal.sign-in");

const signInModal = document.querySelector(".modal.sign-in");

export function initSignInModal() {
    if (!signInModal) return;

    const codeVerificationContainer = signInModal.querySelector(".modal-center__content");

    SignIn.bySmsInit(
        signInModal,
        codeVerificationContainer,
    );

    SignIn.byPassInit(
        signInModal,
    );
    // bySmsInit();
}
