function deskHeader() {
    const clinicHeader = document.querySelector(".header-clinic");

    if (!clinicHeader) return;

    const menuLinks = clinicHeader.querySelectorAll(".header-clinic__menu-link");
    let activeLink = null;
    let activeSubmenu = null;
    const overlay = document.querySelector(".header-clinic-overlay");

    menuLinks.forEach(link => {
        const dfor = link.getAttribute("data-for");

        link.addEventListener("mouseenter", e => {
            if (dfor) {
                activeLink?.classList.remove("header-clinic__menu-link_active");
                activeSubmenu?.classList.remove("header-clinic__submenu_active");

                activeLink = link;
                activeSubmenu = document.querySelector(dfor);

                activeLink.classList.add("header-clinic__menu-link_active");
                activeSubmenu.classList.add("header-clinic__submenu_active");
                overlay.classList.add("header-clinic-overlay_active");
            }
            else {
                activeLink?.classList.remove("header-clinic__menu-link_active");
                activeSubmenu?.classList.remove("header-clinic__submenu_active");
                overlay.classList.remove("header-clinic-overlay_active");
            }
        });

        link.addEventListener("click", e => {
            if (dfor) {
                e.preventDefault();
            }
        });
    });

    overlay.addEventListener("mousemove", () => {
        activeLink?.classList.remove("header-clinic__menu-link_active");
        activeSubmenu?.classList.remove("header-clinic__submenu_active");
        overlay.classList.remove("header-clinic-overlay_active");
    });
}

function mobHeader() {
    const clinicHeader = document.querySelector(".header-clinic");

    if (!clinicHeader) return;

    const burger = clinicHeader.querySelector(".header-clinic__burger");
    const overlay = document.querySelector(".header-clinic-overlay");
    const menu = document.querySelector(".header-clinic__mob-menu");
    const menuItemsInner = document.querySelectorAll(".header-clinic__mob-menu-list-item-inner");
    const submenuItemsInner = document.querySelectorAll(".header-clinic__mob-submenu-list-item-inner");

    burger.addEventListener("click", () => {
        overlay.classList.toggle("header-clinic-overlay_active");
        menu.classList.toggle("header-clinic__mob-menu_active");
    });

    overlay.addEventListener("click", () => {
        overlay.classList.remove("header-clinic-overlay_active");
        menu.classList.remove("header-clinic__mob-menu_active");
    });

    menuItemsInner.forEach(item => {
        const parent = item.parentElement;
        const childMenu = parent.querySelector('.header-clinic__mob-submenu');
        let active = false;

        item.addEventListener('click', () => {
            active = !active;
            parent.classList.toggle('header-clinic__mob-menu-list-item_open', active);
            if (active) {
                childMenu.style.height = childMenu.scrollHeight + 'px';
                // if ('')
                setTimeout(() => {
                    childMenu.style.height = 'auto';
                }, 200);
            }
            else {
                childMenu.style.height = childMenu.scrollHeight + 'px';
                setTimeout(() => {
                    childMenu.style.height = '';
                }, 10);
            }
        });
    })

    submenuItemsInner.forEach(item => {
        const parent = item.parentElement;
        const childMenu = parent.querySelector('.header-clinic__mob-childmenu');
        let active = false;

        item.addEventListener('click', () => {
            active = !active;
            item.parentElement.classList.toggle('header-clinic__mob-submenu-list-item_open');
            if (active) {
                childMenu.style.height = childMenu.scrollHeight + 'px';
            }
            else {
                childMenu.style.height = 0;
            }
            // parent.parentElement.parentElement.style.height = parent.parentElement.parentElement.scrollHeight + 'px';
        })
    })
}

export function initClinicHeader() {
    deskHeader();
    mobHeader();
}
