/* eslint-disable no-restricted-syntax */
/* eslint-disable quote-props */
function declineCity(city) {
    if (!city) return "";

    const exceptions = {
        "Москва": "Москве",
        "Санкт-Петербург": "Санкт-Петербурге",
        "Нижний Новгород": "Нижнем Новгороде",
        "Ростов-на-Дону": "Ростове-на-Дону",
        // можно добавить дополнительные исключения
    };

    if (exceptions[city]) return exceptions[city];

    // Основные окончания и их склонения
    const endings = [
        { ending: "а", result: `${city.slice(0, -1)}е` },
        { ending: "я", result: `${city.slice(0, -1)}е` },
        { ending: "ь", result: `${city.slice(0, -1)}и` },
        { ending: "и", result: `${city}` }, // города, которые уже оканчиваются на "и"
    ];

    // Проверка по окончаниям
    for (const { ending, result } of endings) {
        if (city.endsWith(ending)) {
            return result;
        }
    }

    // По умолчанию для городов без особых окончаний
    return `${city}е`;
}

export function setSkinDiagnostictCenterTitles(city, serviceType) {
    const centerEl = document.querySelector(`#center-${serviceType}`);
    const lastBreadcrumbItem = document.querySelector(`#center-${serviceType} .breadcrumbs__item:last-child`);
    const mainTitleEl = document.querySelector(`#center-${serviceType} .skin-diagnostic-center__main-title`);
    const addressTitle = document.querySelector(`#center-${serviceType} .skin-diagnostic-center__address-title`);

    const [cityName, address] = city.split(",");

    const newAddress = `${declineCity(cityName)}${address ? `, ${address}` : ""}`;

    const newMainTitle = `Центр диагностики кожи в ${newAddress}`;

    const newAddressTitle = `Адрес и график работы центра в ${newAddress}`;

    mainTitleEl.innerText = newMainTitle;

    lastBreadcrumbItem.innerText = newMainTitle;

    if (centerEl.previousElementSibling) {
        document.querySelector(`#center-${serviceType} .breadcrumbs`).remove();
        const firstBreadcrumbs = document.querySelector(".skin-diagnostic-center__content-city:first-child .breadcrumbs");
        firstBreadcrumbs.querySelector(".breadcrumbs__item:last-child").innerText += `; в ${newAddress}`;
        document.querySelectorAll(".section__back").forEach((back, index) => {
            if (index) {
                back.remove();
            }
        });
    }


    addressTitle.innerText = newAddressTitle;
}
