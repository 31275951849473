export function initCopyToClipboardButton({ button, copyFromEl = undefined, callback = () => {} }) {
    const copyFromId = button.dataset.copyFrom;

    if (!copyFromId) return;

    let targetInputEl = copyFromEl;

    if (!targetInputEl) {
        targetInputEl = document.querySelector(`#${copyFromId}`);
    }

    if (!targetInputEl || targetInputEl.tagName !== "INPUT") return;

    button.addEventListener("click", () => {
        targetInputEl.select();
        targetInputEl.setSelectionRange(0, 99999);

        navigator.clipboard.writeText(targetInputEl.value);

        if (callback) callback();
    });
}

export function initCopyToClipBoardButtons() {
    const copyToClipBoardButtons = document.querySelectorAll(".clipboard");

    copyToClipBoardButtons.forEach((button) => initCopyToClipboardButton({ button }));
}
