import { MainSlider } from "./main-slider/index.js";
import { initAboutSlider } from "./about-slider/index.js";
import { initChooseCosmeticsProductSlider } from "./choose-cosmetics-product-slider/index.js";
import { initLearningBlockSlider } from "./learning-block-slider/index.js";
import { initOurLinesSlider } from "./our-lines-slider/index.js";
import { initOurProductsCategoriesSlider } from "./our-products-slider/index.js";

export function initMainPageModules() {
    // eslint-disable-next-line no-unused-vars
    const mainSlider = new MainSlider();

    initChooseCosmeticsProductSlider();
    initOurLinesSlider();
    initAboutSlider();
    initLearningBlockSlider();
    initOurProductsCategoriesSlider();
}
