export function executeScriptsInElement(element) {
    Array.from(element.querySelectorAll("script"))
        .forEach((oldScriptEl) => {
            const newScriptEl = document.createElement("script");

            Array.from(oldScriptEl.attributes).forEach(attr => {
                newScriptEl.setAttribute(attr.name, attr.value);
            });

            const scriptText = document.createTextNode(oldScriptEl.innerHTML);
            newScriptEl.appendChild(scriptText);

            oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
        });
}

export function htmlDecode(input) {
    const doc = new DOMParser().parseFromString(input, "text/html");

    Array.from(doc.querySelectorAll("script"))
        .forEach((oldScriptEl) => {
            const newScriptEl = document.createElement("script");

            Array.from(oldScriptEl.attributes).forEach(attr => {
                newScriptEl.setAttribute(attr.name, attr.value);
            });

            const scriptText = document.createTextNode(oldScriptEl.innerHTML);
            newScriptEl.appendChild(scriptText);

            oldScriptEl.parentNode.replaceChild(newScriptEl, oldScriptEl);
        });
    return doc.documentElement.textContent;
}
