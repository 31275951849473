import { baseFetch } from "../../base/index.js";

const headers = {
    Accept: "application/json, text/javascript, */*; q=0.01",
    "Accept-Language": "ru-RU,ru;q=0.8,en-US;q=0.5,en;q=0.3",
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    "X-Requested-With": "XMLHttpRequest",
    Priority: "u=0",
    Pragma: "no-cache",
    "Cache-Control": "no-cache",
    "Sec-Fetch-Dest": "empty",
    "Sec-Fetch-Mode": "cors",
    "Sec-Fetch-Site": "same-origin",
};

const endpoints = {
    quiz: async (body = "") => baseFetch.post("/local/ajax/quiz.php", {
        headers,
        body,
    }),
    skinTest: async (body = "") => baseFetch.post("/local/ajax/skin-test.php", {
        headers,
        body,
    }),
};

export default endpoints;
