import flatpickr from 'flatpickr';
import { Russian } from "flatpickr/dist/l10n/ru.js"

export function initInputDate() {
    const els = document.querySelectorAll('.input-date');

    if (!els.length) return;

    flatpickr(".input-date", {
        disableMobile: "true",
        "locale": Russian // locale for this instance only
    });
}