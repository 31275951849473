import Swiper from "swiper";
import { Autoplay } from "swiper/modules";

Swiper.use([Autoplay]);
export class MainSlider {
    static #instance = null;

    constructor() {
        // singleton
        // eslint-disable-next-line no-constructor-return
        if (MainSlider.#instance) return MainSlider.#instance;
        MainSlider.#instance = this;

        this.slider = document.querySelector(".main-section__slider");

        if (this.slider) {
            this.swiper = new Swiper(".main-section__slider", {
                modules: [Autoplay],
                autoplay: {
                    delay: 15000,
                    disableOnInteraction: false,
                },
            });

            this.slides = this.slider.querySelectorAll(".main-section__slide");

            this.currentDot = null;

            this.dotsItem = document.createElement("div");

            this.dotsList = [];

            this.indicator = document.createElement("i");

            this.sliderSizeObserver = new ResizeObserver(this.#onSizeChange.bind(this))
                .observe(this.slider);

            this.#init();
        }
    }

    #init() {
        this.#initSlides();
        this.#initDots();
        this.#initIndicator();
    }

    #initSlides() {
        this.slides.forEach((slide, index) => {
            const imageSource = slide.querySelector(".main-section__slide-image");
            // slide.style.backgroundImage = `url(${imageSource.dataset.src})`;

            function mediaChange(mediaQuery) {
                if (mediaQuery.matches) {
                    slide.style.backgroundImage = `url(${imageSource.dataset.src})`;
                } else if (imageSource.dataset.srcMobile) {
                    slide.style.backgroundImage = `url(${imageSource.dataset.srcMobile})`;
                    slide.style.backgroundPosition = "auto";
                } else {
                    slide.style.backgroundImage = `url(${imageSource.dataset.src})`;
                }
            }

            const mediaQuery = window.matchMedia("(min-width: 992px)");
            mediaQuery.addEventListener("change", mediaChange);
            mediaChange(mediaQuery);

            slide.setAttribute("id", index);
        });

        this.swiper.on("slideChange", () => {
            this.currentDot = this.dotsList.find((dot) => (
                Number(dot.dataset.slide) === this.swiper.activeIndex
            ));

            this.indicator.style.transition = "left .3s ease-in-out";

            this.#moveIndicator(this.currentDot);
        });
    }

    #initDots() {
        const dotsWrapper = document.createElement("div");

        dotsWrapper.classList.add("main-section__slider-dots");

        this.dotsItem.classList.add("main-section__slider-dots-wrapper");

        this.indicator.classList.add("main-section__slide-indicator");

        this.dotsItem.appendChild(this.indicator);

        this.slides.forEach((slide) => {
            const dot = document.createElement("div");

            dot.setAttribute("data-slide", slide.id);

            dot.classList.add("main-section__slider-dots-item");

            dot.addEventListener("click", this.dotClick.bind(this));

            this.dotsList.push(dot);

            this.dotsItem.appendChild(dot);
        });

        this.currentDot = this.dotsList[0];

        window.addEventListener("resize", this.#onSizeChange.bind(this));

        dotsWrapper.appendChild(this.dotsItem);

        this.slider.appendChild(dotsWrapper);
    }

    #moveIndicator(dot) {
        const dotRect = dot.getBoundingClientRect();

        const indicatorRect = this.indicator.getBoundingClientRect();

        this.indicator.style.left = `${dotRect.left - ((indicatorRect.width - dotRect.width) / 2)}px`;
    }

    #onSizeChange() {
        if (this.indicator) {
            this.indicator.style.transition = "none";
            this.#moveIndicator(this.currentDot);
        }
    }

    #initIndicator() {
        this.#moveIndicator(this.dotsList[0]);
    }

    dotClick(event) {
        if (this.currentDot === event.target) return;

        this.currentDot = event.target;

        this.indicator.style.transition = "left .3s ease-in-out";

        this.#moveIndicator(event.target);

        this.slides.forEach((slide) => {
            if (slide.id === this.currentDot.dataset.slide) {
                this.swiper.slideTo(slide.id);
            }
        });
    }
}
