/* eslint-disable no-use-before-define */

import { adaptTestResult, adaptTestResultWithCatgeories } from "./test-result-adapter.js";

/* eslint-disable indent */

/**
 * @typedef {{
 *      id: string,
*      question: string,
*      answers: {[key: number]: string},
*      count: number,
*      count_current: number,
*      state: string,
* }} Question
 */

/**
 *
 * @param {Question} content
 */
function getQuestionHTMLFromJSON(content) {
    const question = document.createElement("div");

    question.classList.add("question");

    question.setAttribute("data-question", content.id);
    question.setAttribute("data-current", content.count_current - 1);

    question.innerHTML = `
            <div class="question__wrapper">
                <h4 class="h4 question__title">
                    ${content.question}
                </h4>
                ${content.img_1 ? `
                    <picture class="picture question__image">
                        <img class="img" src="https://v2024.geltek.ru${content.img_1}"
                            alt="${content.question}">
                    </picture>
                    `
            : ""
        }
                <fieldset class="question__variants">
                    <legend class="p">Выберите один из подходящих вам вариантов</legend>
                    <div class="question__variants-wrapper">
                        ${Object.keys(content.answers).map((answerKey) => (
            `
                                <div class="input input--radio question__variants-item">
                                    <input id="q-${answerKey}" type="radio" name="answer" value="${answerKey}">
                                    <label for="q-${answerKey}">${content.answers[answerKey]}</label>
                                </div>
                                `
        )).join("")
        }
                    </div>
                </fieldset>
            </div>
    
    `;

    return question;
}

/**
 *
 * @param {typeof testFetch} testFetch
 * @param {RequestInit} options
 * @returns {Promise<Question>}
 */
async function handleQuestion(testFetch, requestBody = "") {
    const rawResponse = await testFetch(requestBody);

    if (rawResponse.status === 200) return rawResponse.json();

    throw new Error(rawResponse.statusText);
}

/**
 * @typedef {{
*      id: string,
*      title: string,
*      detailPageUrl: string,
*      imageUrl: string,
*      price: number,
*      fullprice: number,
*      addToCartUrl: string,
*      marketplaces: {
*          [key: string]: {
*              title: string,
*              link: string,
*          }
*      }
* }} Product
*/

/**
 * @typedef {{
*      name: string,
*      morning: {
*          title: string,
*          categories: {
*              [key: string]: {id:number, products: Product[]}
*          }
*      },
*      evening: {
*          title: string,
*          categories: {
*              [key: string]: {id:number, products: Product[]}
*          }
*      },
*      all: {
*          title: string,
*          categories: {
*              [key: string]: {id:number, products: Product[]}
*          }
*      }
* }} AdaptedTestResultWithCategories
*/

/**
 * @typedef {{
*      name: string,
*      detail:string,
*      products: Product[],
* }} AdaptedTestResult
*/

/**
 *
 * @param {keyof typeof testFetch} testFetch
 * @param {(data: AdaptedTestResult | AdaptedTestResultWithCategories) => void} onTestFinish
 */
export async function initDynamicTestByAPI(queryClass, testFetch, onTestFinish = () => { }) {
    const testLayout = document.querySelector(`.test-layout.${queryClass}`);

    const testParent = testLayout.parentNode.parentNode;

    const isPopup = testParent.classList.contains("popup__wrapper");

    const popupBotton = document.createElement("div");

    popupBotton.classList.add("popup__bottom");

    if (isPopup) {
        testParent.append(popupBotton);
    }

    if (!testLayout) return;

    const progressBar = testLayout.querySelector(".test-layout__progress-bar");

    progressBar.style.width = 0;

    const prevButton = testLayout.querySelector(".test-layout__prev");

    prevButton.style.display = "none";

    const nextButton = document.createElement("button");

    const finalButton = document.createElement("button");

    nextButton.classList.add("btn--M", "btn-primary-light", "test-layout__next");

    nextButton.type = "button";

    nextButton.innerText = "Следующий вопрос";

    finalButton.classList.add("btn--M", "btn-primary-light", "test-layout__next");

    finalButton.type = "button";

    finalButton.innerText = "Посмотреть результат теста";

    const questionsWrapper = testLayout.querySelector(".test-layout__questions");

    const firstQuestion = await handleQuestion(testFetch);

    const questionsCount = firstQuestion.count;

    let currentQuestion = null;

    const selectedAnswers = {};

    function handlePrevQuestion() {
        delete selectedAnswers[currentQuestion.dataset.question];
        currentQuestion.remove();

        const questions = testLayout.querySelectorAll(".question");

        if (questions.length === 1) {
            prevButton.style.display = "none";
        }

        currentQuestion = questions[questions.length - 1];
        progressBar.style.width = `${Math.ceil((Number(currentQuestion.dataset.current) * 100) / questionsCount)}%`;

        if (questionsCount > Number(currentQuestion.dataset.current)) {
            finalButton.remove();
            nextButton.addEventListener("click", handleNextQuestion);

            if (isPopup) {
                popupBotton.append(nextButton);
            } else {
                testLayout.append(nextButton);
            }
        }

        currentQuestion.style.display = "block";
    }

    async function handleNextQuestion(event) {
        const requestBody = `photo=N&answer=${Object.values(selectedAnswers).join(",")}`;
        if (requestBody) {
            const nextQuestion = await handleQuestion(testFetch, requestBody);

            if (currentQuestion) {
                currentQuestion.style.display = "none";
            }

            insertQuestion(nextQuestion);

            progressBar.style.width = `${Math.ceil(((nextQuestion.count_current - 1) * 100) / questionsCount)}%`;
        }
    }

    async function handleFinish() {
        const requestBody = `photo=N&answer=${Object.values(selectedAnswers).join(",")}`;

        if (requestBody) {
            const testResult = await handleQuestion(testFetch, requestBody);

            if (Array.isArray(testResult.recom)) {
                onTestFinish(adaptTestResult(testResult));
            } else {
                onTestFinish(adaptTestResultWithCatgeories(testResult));
            }
        }
    }

    /**
     *
     * @param {Question} question
     */
    function insertQuestion(question) {
        if (question.question) {
            nextButton.disabled = true;

            currentQuestion = getQuestionHTMLFromJSON(question);

            questionsWrapper.append(currentQuestion);

            const variants = currentQuestion.querySelectorAll("input[name='answer']");

            variants.forEach((variant) => {
                variant.addEventListener("change", (event) => {
                    if (event.target.checked) {
                        finalButton.disabled = null;
                        nextButton.disabled = null;
                        selectedAnswers[question.id] = event.target.value;
                    }
                });
            });

            if (question.count_current > 1) {
                prevButton.style.display = "flex";
                prevButton.addEventListener("click", handlePrevQuestion);
            }

            if (question.count > question.count_current) {
                nextButton.addEventListener("click", handleNextQuestion);

                if (isPopup) {
                    popupBotton.append(nextButton);
                } else {
                    testLayout.append(nextButton);
                }
            } else {
                finalButton.disabled = true;
                nextButton.remove();
                finalButton.addEventListener("click", handleFinish);
                if (isPopup) {
                    popupBotton.append(finalButton);
                } else {
                    testLayout.append(finalButton);
                }
            }
        }
    }

    insertQuestion(firstQuestion);
}
