import { BodyScroll } from "../../utils/index.js";
import { openCatalogMenu } from "../catalog-menu/index.js";

const header = document.querySelector("header");

const mainMenu = document.querySelector(".main-menu");

const mainMenuContent = document.querySelector(".main-menu__content");

const menuButtons = document.querySelectorAll(".header-full__top-menu-button, .header-full__bottom-center-mobile-menu-button");

const mainMenuBackdrop = document.querySelector(".main-menu__backdrop");

const mainMenuCloseButton = document.querySelector(".main-menu__close-button");

const mainMenuCatalog = document.querySelector(".main-menu__catalog");

export function openMainMenu() {
    BodyScroll.lock();

    header.classList.add("header-full--menu-open");

    mainMenu.classList.add("main-menu--open");

    header.style.zIndex = 600;
}

export function closeMainMenu() {
    BodyScroll.unlock();

    header.classList.remove("header-full--menu-open");

    mainMenu.classList.remove("main-menu--open");

    header.style.removeProperty("z-index");
}

function mainMenuButtonClick() {
    if (mainMenu.classList.contains("main-menu--open")) closeMainMenu();
    else openMainMenu();
}

function catalogClick(event) {
    event.preventDefault();

    closeMainMenu();
    openCatalogMenu();
}

function initCards() {
    const cards = mainMenu?.querySelectorAll(".main-menu__card");
    const backButton = mainMenu?.querySelector(".main-menu__back");
    const nestedLists = mainMenu?.querySelectorAll(".main-menu__cards-nested");

    function categoryChange(event) {
        const card = event.currentTarget;
        if (backButton.classList.contains("nested-list__back--hidden")) {
            mainMenuContent.style.overflowY = "auto";
            mainMenuCloseButton.style.right = null;
            mainMenuCloseButton.style.top = null;
            backButton.style.top = null;
            nestedLists.forEach((nl) => {
                nl.style.paddingTop = null;
            });
        } else {
            if (backButton.clientHeight > 63) {
                backButton.style.top = "8px";

                nestedLists.forEach((nl) => {
                    nl.style.paddingTop = `${Math.ceil((backButton.clientHeight * 25) / 63)}px`;
                });
            }
            mainMenuContent.style.overflowY = "hidden";
            mainMenuCloseButton.style.top = "23px";
            mainMenuCloseButton.style.right = 0;
        }


        mainMenuContent.scrollTo({ top: 0 });
    }

    backButton?.addEventListener("click", categoryChange);

    cards?.forEach((card) => {
        card?.addEventListener("click", categoryChange);
    });
}

export function initMainMenu() {
    mainMenuBackdrop?.addEventListener("click", closeMainMenu);

    mainMenuCloseButton?.addEventListener("click", closeMainMenu);

    menuButtons.forEach((button) => {
        button.addEventListener("click", mainMenuButtonClick);
    });

    mainMenuCatalog?.addEventListener("click", catalogClick);

    initCards();
}
