export function initSurveyPageModules() {
    if (!document.querySelector('.survey')) return;

    let anotherItems = document.querySelector('.survey__another-items');

    if (anotherItems) {
        let anotherItem = document.querySelector('.survey__another-item');

        anotherItems.addEventListener('click', e => {
            if (e.target.classList.contains('survey__another-item-add_add')) {
                let anotherItemTemplate = anotherItem.cloneNode(true);
                anotherItems.appendChild(anotherItemTemplate);
            }
            if (e.target.classList.contains('survey__another-item-add_remove')) {
                let anotherItemTemplate = e.target.parentElement;
                anotherItemTemplate.remove();
            }
        })
    }

    let tableMob = document.querySelector('.survey__table-inputs');

    if (tableMob) {
        tableMob.addEventListener('click', e => {
            if (e.target.classList.contains('survey__table-inputs-label')) {
                e.target.parentElement.parentElement.classList.toggle('survey__table-inputs-row_active');
            }
        })
    }

    let checkers = document.querySelectorAll('.survey__radios .custom-checker__input');

    if (checkers) {
        checkers.forEach(item => {
            item.addEventListener('change', e => {
                if (item.value !== "Нет") {
                    item.parentElement.parentElement.classList.add('survey__radios_yes')
                }
                else {
                    item.parentElement.parentElement.classList.remove('survey__radios_yes')
                }
            })
        })
    }
}