import Choices from 'choices.js';

export function initSelect() {
    const els = document.querySelectorAll(".select");

    if (!els.length) return;

    // const choices = [];

    els.forEach((item, index) => {
        const select = item.querySelector("select");

        const isSearchable = item.classList.contains('select--searchable');

        // select.setAttribute('data-select', index);

        select.choices = new Choices(select, {
            itemSelectText: "",
            position: "bottom",
            searchEnabled: isSearchable,
            shouldSort: false,
            allowHTML: false,
            placeholder: true,
            loadingText: 'Загрузка...',
            noResultsText: 'Элементы не найдены',
            searchPlaceholderValue: 'Поиск',
            searchResultLimit: 100,
        });

        select.addEventListener('change', function() {
            if (select.choices.getValue().value) {
                item.classList.add('select_is-selected')
            }
            else {
                item.classList.remove('select_is-selected')
            }
        }, false);

        const observer = new MutationObserver(() => {
            if (!select.choices.getValue().value) {
                item.classList.remove('select_is-selected');
            }
            else {
                item.classList.add('select_is-selected');
            }
        });
        
        observer.observe(select, { childList: true, subtree: true });

        item.choices = select.choices;
    });
}
