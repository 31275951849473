import { generateProductCardHTML } from "./getProduct.js";

/* eslint-disable indent */

/**
 *
 * @param {import("../test-result-adapter.js").AdaptedTestResult} catalog
 * @returns {string}
 */
export function getCatalogWithoutCategories(catalog) {
    return `
    <div class="skintest__recommend-list">
        <div class="product-slider popular-products__slider">

            <h2 class="h2 product-slider__heading">${catalog.name}</h2>

            <div class="product-slider__products">
                <ul class="swiper product-slider__list swiper-initialized swiper-horizontal swiper-backface-hidden">
                    <div class="swiper-wrapper product-slider__list-wrapper">

                        ${catalog.products.map((product) => generateProductCardHTML({
                            ...product,
                            classStr: "popular-products__list-item product-slider__list-item",
                        })).join(" ")}

                    </div>
                </ul>
                <div class="slider-navigation product-slider__arrows">
                    <button
                        class="slider-navigation-prev product-slider__arrows-left swiper-button-disabled swiper-button-lock"
                        disabled="">
                        <i class="icon-arrow-left"></i>
                    </button>
                    <button
                        class="slider-navigation-next product-slider__arrows-right swiper-button-disabled swiper-button-lock"
                        disabled="">
                        <i class="icon-arrow-right"></i>
                    </button>
                </div>
            </div>
        </div>

    </div>
    `;
}
