const catalogFiltersEl = document.querySelector(".catalog-filters");

function initToggleButtons() {
    const listViewButton = catalogFiltersEl.querySelector(".catalog-filters__toggle-list");
    const gridViewButton = catalogFiltersEl.querySelector(".catalog-filters__toggle-grid");

    const gridSection = document.querySelector(".catalog-list");
    const tableSection = document.querySelector(".catalog-table");
    let view = "grid";

    if (!listViewButton || !gridViewButton || !tableSection || !gridSection) return;

    listViewButton.addEventListener("click", () => {
        gridSection.parentElement.style.display = "none";
        tableSection.parentElement.style.display = "block";
        view = "list";
    });

    gridViewButton.addEventListener("click", () => {
        gridSection.parentElement.style.display = "block";
        tableSection.parentElement.style.display = "none";
        view = "grid";
    });

    function mediaChange(mediaQuery) {
        if (mediaQuery.matches) {
            if (view === "grid") {
                gridSection.parentElement.style.display = "block";
                tableSection.parentElement.style.display = "none";
            } else {
                gridSection.parentElement.style.display = "none";
                tableSection.parentElement.style.display = "block";
            }
        } else {
            gridSection.parentElement.style.display = "block";
            tableSection.parentElement.style.display = "none";
        }
    }

    const mediaQuery = window.matchMedia("(min-width: 992px)");

    mediaQuery.addEventListener("change", mediaChange);

    mediaChange(mediaQuery);
}

export function initCatalogFilters() {
    if (!catalogFiltersEl) return;
    const categoriesSlider = document.querySelector(".categories-slider__wrapper");

    if (categoriesSlider) {
        categoriesSlider.classList.add("no-sticky");
    }

    const syncedCheckboxes = {};
    const appliedFilters = {};

    const catalogFilterWrappers = [...catalogFiltersEl.querySelectorAll(".catalog-filters__filter")];

    const allCatalogFilterWrappers = [...catalogFiltersEl.querySelectorAll(".all-catalog-filters__filter")];

    const allFiltersButton = catalogFiltersEl.querySelector(".catalog-filters__all-filters");

    const acceptAllFiltersButton = catalogFiltersEl.querySelector(".all-catalog-filters__accept");

    const tagsContainer = catalogFiltersEl.querySelector(".catalog-filters__tags");

    const resetTagsButton = catalogFiltersEl.querySelector(".catalog-filters__reset");

    function findFilterWrapperAllAlternative(filterWrapperEl) {
        return allCatalogFilterWrappers.find((allCatalogFilterWrapper) => {
            const altTitle = allCatalogFilterWrapper
                .querySelector(".all-catalog-filters__filter-button").innerText;

            const title = filterWrapperEl
                .querySelector(".catalog-filters__filter-title").innerText;

            if (title.trim() === altTitle.trim()) {
                return allCatalogFilterWrappers;
            }
            return undefined;
        });
    }

    function syncCheckboxes(filterWrapper, altFilterWrapper) {
        const filterCheckboxes = [...filterWrapper.querySelectorAll(".catalog-filters__checkbox")];
        const title = filterWrapper.querySelector(".catalog-filters__filter-title").innerText;

        const altCheckboxes = [...altFilterWrapper.querySelectorAll(".all-catalog-filters__checkbox")];

        function addCheckbox(checkbox) {
            const checkboxLabel = checkbox.parentElement.innerText.trim();
            if (syncedCheckboxes[title]) {
                if (syncedCheckboxes[title][checkboxLabel]) {
                    syncedCheckboxes[title][checkboxLabel].push(checkbox);
                } else {
                    syncedCheckboxes[title][checkboxLabel] = [checkbox];
                }
            } else {
                syncedCheckboxes[title] = {};
                syncedCheckboxes[title][checkboxLabel] = [checkbox];
            }
        }

        function checkboxChange(event) {
            const checkbox = event.currentTarget;
            const checkboxLabel = checkbox.parentElement.innerText.trim();

            if (
                syncedCheckboxes[title]
                && syncedCheckboxes[title][checkboxLabel]
                && syncedCheckboxes[title][checkboxLabel].length > 1
            ) {
                const altCheckbox = syncedCheckboxes[title][checkboxLabel]
                    .find((syncedCheckbox) => (
                        syncedCheckbox !== checkbox
                    ));

                altCheckbox.checked = checkbox.checked;
            }
        }

        filterCheckboxes.forEach((checkbox) => {
            addCheckbox(checkbox);
            checkbox.addEventListener("change", checkboxChange);
        });

        altCheckboxes.forEach((checkbox) => {
            addCheckbox(checkbox);
            checkbox.addEventListener("change", checkboxChange);
        });
    }

    function updateFilterCounters() {
        catalogFilterWrappers.forEach((filterWrapper) => {
            const titleEl = filterWrapper.querySelector(".catalog-filters__filter-title");
            const filterCategory = appliedFilters[titleEl.innerText.trim()];

            if (filterCategory && filterCategory.length) {
                titleEl.dataset.filterCount = filterCategory.length;
            } else {
                delete titleEl.dataset.filterCount;
            }
        });

        let allFiltersCount = 0;

        allCatalogFilterWrappers.forEach((filterWrapper) => {
            const titleEl = filterWrapper.querySelector(".all-catalog-filters__filter-button");
            const counter = filterWrapper.querySelector(".all-catalog-filters__counter");
            const filterCategory = appliedFilters[titleEl.innerText.trim()];

            if (filterCategory && filterCategory.length) {
                counter.dataset.filterCount = filterCategory.length;
                allFiltersCount += filterCategory.length;
            } else {
                delete counter.dataset.filterCount;
            }
        });

        if (allFiltersCount) {
            allFiltersButton.dataset.filterCount = allFiltersCount;
        } else {
            delete allFiltersButton.dataset.filterCount;
        }
    }

    function resetTags() {
        Object.entries(appliedFilters).map(([key, _]) => {
            appliedFilters[key] = [];
        });
        updateFilterCounters();
        // eslint-disable-next-line no-use-before-define
        updateTags();
    }

    function onTagRemove(tag) {
        const tagText = tag.querySelector("span").innerText.trim();

        Object.entries(appliedFilters).map(([key, value]) => {
            const isInCategory = value.includes(tagText);
            if (isInCategory) {
                appliedFilters[key] = value.filter((f) => f !== tagText);

                if (syncedCheckboxes[key] && syncedCheckboxes[key][tagText]) {
                    syncedCheckboxes[key][tagText].forEach((checkbox) => {
                        checkbox.checked = false;
                    });
                }
            }
        });

        updateFilterCounters();
        // eslint-disable-next-line no-use-before-define
        updateTags();
    }

    function updateTags() {
        const tagstoInsert = [
            ...new Set(Object.entries(appliedFilters).map(([_, value]) => value).flat()),
        ];

        const placedTags = tagsContainer.querySelectorAll(".catalog-filters__tag");

        placedTags.forEach((placedTag) => placedTag.remove());

        if (tagstoInsert.length) {
            tagsContainer.classList.add("catalog-filters__tags--open");
            resetTagsButton.style.display = "flex";
        } else {
            tagsContainer.classList.remove("catalog-filters__tags--open");
            resetTagsButton.style.display = "none";
        }

        tagstoInsert.forEach((tag) => {
            const tagEl = document.createElement("div");
            tagEl.classList.add("tag", "catalog-filters__tag");

            const tagTextEl = document.createElement("span");
            tagTextEl.innerText = tag;

            const tagRemoveButton = document.createElement("i");
            tagRemoveButton.classList.add("icon-close", "catalog-filters__tag-remove");
            tagRemoveButton.addEventListener("click", () => onTagRemove(tagEl));

            tagEl.appendChild(tagTextEl);
            tagEl.appendChild(tagRemoveButton);

            tagsContainer.prepend(tagEl);
        });
    }

    function allFiltersAcceptClick() {
        allCatalogFilterWrappers.forEach((filterWrapper) => {
            const title = filterWrapper.querySelector(".all-catalog-filters__filter-button").innerText.trim();

            function getCheckedCheckboxes() {
                const checkboxes = [...filterWrapper.querySelectorAll(".all-catalog-filters__checkbox")];
                return checkboxes.filter((checkbox) => checkbox.checked);
            }

            const checkedCheckboxes = getCheckedCheckboxes(filterWrapper);

            appliedFilters[title] = [];

            checkedCheckboxes.forEach((checkbox) => {
                const checkboxLabel = checkbox.parentElement.innerText.trim();

                if (appliedFilters[title]) {
                    if (!appliedFilters[title].includes(checkboxLabel)) {
                        appliedFilters[title].push(checkboxLabel);
                    }
                } else {
                    appliedFilters[title] = [checkboxLabel];
                }
            });

            updateFilterCounters();
            updateTags();
        });
    }

    /**
     *
     * @param {HTMLElement} wrapper element with class "categories-slider__wrapper"
     * @param {Number} top
     */
    function changeCatalogFiltersOnScroll(wrapper, top) {
        return function handleScroll() {
            if (window.scrollY > top && window.scrollY < this.oldScroll) {
                wrapper.classList.add("catalog-filters--scroll");
            } else if (!wrapper.querySelector('.modal_active')) {
                wrapper.classList.remove("catalog-filters--scroll");
            }
            this.oldScroll = window.scrollY;
        };
    }

    /**
     *
     * @param {HTMLElement} filterWrapperEl
     */
    function initFilter(filterWrapperEl) {
        const allCatalogFilterWrapperAlt = findFilterWrapperAllAlternative(filterWrapperEl);

        const acceptButton = filterWrapperEl.querySelector(".catalog-filters__accept-button");

        function getCheckedCheckboxes(filterEl) {
            const checkboxes = [...filterEl.querySelectorAll(".catalog-filters__checkbox")];
            return checkboxes.filter((checkbox) => checkbox.checked);
        }

        function acceptFilters() {
            const title = filterWrapperEl.querySelector(".catalog-filters__filter-title").innerText.trim();
            const checkedCheckboxes = getCheckedCheckboxes(filterWrapperEl);

            if (appliedFilters[title]) {
                appliedFilters[title] = [];
            }

            checkedCheckboxes.forEach((checkbox) => {
                const checkboxLabel = checkbox.parentElement.innerText.trim();

                if (appliedFilters[title]) {
                    if (!appliedFilters[title].includes(checkboxLabel)) {
                        appliedFilters[title].push(checkboxLabel);
                    }
                } else {
                    appliedFilters[title] = [checkboxLabel];
                }
            });

            updateFilterCounters();
            updateTags();
        }

        if (allCatalogFilterWrapperAlt) {
            syncCheckboxes(filterWrapperEl, allCatalogFilterWrapperAlt);
        }

        acceptButton.addEventListener("click", acceptFilters);
    }

    catalogFilterWrappers.forEach(initFilter);

    if (acceptAllFiltersButton) acceptAllFiltersButton.addEventListener("click", allFiltersAcceptClick);

    if (resetTagsButton) resetTagsButton.addEventListener("click", resetTags);

    if (catalogFiltersEl) window.addEventListener("scroll", changeCatalogFiltersOnScroll(catalogFiltersEl, catalogFiltersEl.offsetTop));

    initToggleButtons();
}
