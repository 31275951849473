import Swiper from "swiper";

/**
 *
 * @param {HTMLElement} wrapper element with class "product-list-slider-with-tabs"
 */
function initSliders(wrapper) {
    const tabPanels = wrapper.querySelectorAll(".product-list-slider-with-tabs__tab-panel-item");

    if (!tabPanels.length) return;

    tabPanels.forEach((tabPanel) => {
        const dataValue = tabPanel.getAttribute("data-value");

        if (!dataValue) throw new Error("tab panel must contain data-value");

        tabPanel.id = `product-list-slider-with-tabs__tab-panel-item-${dataValue}`;

        const slider = new Swiper(`#${tabPanel.id} .product-list-slider-with-tabs__product-list`, {
            slidesPerView: "auto",
            spaceBetween: 12,
            slideClass: "product-list-slider-with-tabs__product-list-item",
            navigation: {
                prevEl: `#${tabPanel.id} .product-list-slider-with-tabs-arrows-left`,
                nextEl: `#${tabPanel.id} .product-list-slider-with-tabs-arrows-right`,
            },
            breakpoints: {
                992: {
                    spaceBetween: 24,
                },
            },
        });
    });
}

export function initProductListSlidersWithTabs() {
    const wrappers = document.querySelectorAll(".product-list-slider-with-tabs");

    if (!wrappers.length) return;

    wrappers.forEach((wrapper, index) => {
        wrapper.id = `product-list-slider-with-tabs-${index}`;

        initSliders(wrapper);
    });
}
