import Swiper from "swiper";
import { EffectFade, Thumbs, Pagination } from "swiper/modules";
import CEvent from "../../../vendor/Event.js";
import Magnifier from "../../../vendor/Magnifier.js";

/**
 *
 * @param {HTMLElement} sliderWrapper element with class "detail__slider"
 */
function initMainSlider(sliderWrapper) {
    const thumbsEl = sliderWrapper.querySelector(".detail__slider-thumbs");

    const mainSliderEl = sliderWrapper.querySelector(".detail__slider-main");

    const nextButton = sliderWrapper.querySelector(".slider-navigation-next");

    if (!thumbsEl || !mainSliderEl) return;

    const sliderThumbs = new Swiper(`#${thumbsEl.id} .swiper`, {
        modules: [Thumbs],
        loop: true,
        slidesPerView: "auto",
        spaceBetween: 16,
        watchSlidesProgress: true,
        observer: true,
        observeParents: true,
    });

    const sliderMain = new Swiper(`#${mainSliderEl.id} .swiper`, {
        modules: [EffectFade, Thumbs, Pagination],
        loop: true,
        effect: "fade",
        fadeEffect: {
            crossFade: true,
        },
        thumbs: {
            swiper: sliderThumbs,
        },
        observer: true,
        observeParents: true,
        autoHeight: true,
    });

    nextButton.addEventListener("click", () => {
        if (sliderMain.activeIndex === sliderMain.slides.length - 1) {
            sliderMain.slideTo(0);
        } else {
            sliderMain.slideTo(sliderMain.activeIndex + 1);
        }
    });
}

function initMobileSlider(slider) {
    return new Swiper(`#${slider.id} .swiper`, {
        modules: [Pagination],
        slidesPerView: 1,
        spaceBetween: 16,
        pagination: {
            el: `#${slider.id} .swiper-pagination`,
            clickable: true,
        },
        autoHeight: true,
    });
}

export function initDetailSlider() {
    const evt = new CEvent();
    const m = new Magnifier(evt);

    try {
        m.attach({
            thumb: ".detail__slider-main-img",
            largeWrapper: "zoomContainer",
            zoom: 5,
        });
    } catch (error) {
        console.log(error);
    }

    const sliderWrappers = document.querySelectorAll(".detail__slider");

    const mobileSliders = document.querySelectorAll(".detail__mob-slider");

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
            if (entry.intersectionRatio > 0) {
                window.dispatchEvent(new Event("resize"));
                observer.unobserve(entry.target);
                if (entry.target && entry.target.classList.contains("detail__slider-main")) {
                    initMainSlider(entry.target.parentNode);
                } else if (entry.target && entry.target.classList.contains("detail__mob-slider")) {
                    initMobileSlider(entry.target);
                }
            }
        });
    }, null);

    sliderWrappers.forEach((sliderWrapper, index) => {
        const thumbsEl = sliderWrapper.querySelector(".detail__slider-thumbs");

        const mainSliderEl = sliderWrapper.querySelector(".detail__slider-main");

        if (thumbsEl && mainSliderEl) {
            mainSliderEl.id = `detail__slider-main-${index}`;
            thumbsEl.id = `detail__slider-thumbs-${index}`;

            initMainSlider(sliderWrapper);
            observer.observe(mainSliderEl);
        }
    });

    mobileSliders.forEach((slider, index) => {
        slider.id = `detail__mob-slider-${index}`;

        initMobileSlider(slider);
    });
}
