import { BaseYMap } from "../../../modules/ymap/index.js";

/**
 * на страницу с картой добавить тег
    <script src="https://api-maps.yandex.ru/1.1/index.xml" type="text/javascript"></script>
 *
 */
export function initMasterClassesMap() {
    const mapContainer = document.querySelector("#master-classes-address__map");

    if (!mapContainer) return;

    const map = new BaseYMap(mapContainer, {
        center: [37.627786, 55.733219],
        zoom: 17,
    });

    map.addMark([37.627700669311466, 55.733442556958806]);
    map.addBallon([37.627700669311466, 55.733442556958806], "Пятницкая, 59/19, стр. 5");
}
