export function checker() {
    const el = document.querySelector('.ai-argrees-check');

    if (!el) return;

    const checks = el.querySelectorAll('.custom-checkbox__input');
    const checksCount = checks.length;

    const btn = document.querySelector('.ai-argrees-next');

    let checkedCount = 0;

    checks.forEach(item => {
        item.addEventListener('input', () => {
            if (item.checked) {
                checkedCount++;
            }
            else {
                checkedCount--;
            }
            
            if (checkedCount == checksCount) {
                btn.removeAttribute('disabled')
            }
            else {
                btn.setAttribute('disabled', 'disabled')
            }
        })
    })
}