import { initCopyToClipboardButton } from "../../modules/copy-to-clipboard/index.js";
import { notifyWithText } from "../../modules/notifications/index.js";

export function getPresentsHTML() {
    const htmlString = `
        <div class="container">
            <div class="section__wrapper skintest__prizes">
                <h2 class="h2">Ваши подарки</h2>
                <div class="skintest__prizes-list">
                    <div class="card card--bordered skintest__prizes-item">
                        <div class="skintest__prizes-row">
                            <i class="icon icon-doctor"></i>
                            <p class="p">Бесплатная консультация опытного косметолога-дерматолога</p>
                        </div>
                        <div class="skintest__prizes-row">
                            <a href="/skin-diagnostic-center/" class="button button--primary--light">Записаться на диагностику</a>
                        </div>

                    </div>
                    <div class="card card--bordered skintest__prizes-item">
                        <div class="skintest__prizes-row">
                            <i class="icon icon-percent"></i>
                            <p class="p">Промокод на скидку 15% для будущих покупок</p>
                        </div>
                        <div class="skintest__prizes-row">
                            <label class="input input--text">
                                <input id="skintest-promocode" type="text" name="promo" value="GELTEK15" placeholder="GELTEK15" readonly="">
                            </label>
                            <button class="button button--primary--light clipboard" data-copy-from="skintest-promocode" type="button">Скопировать промокод</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `;

    const element = document.createElement("div");

    element.classList.add("section");

    element.innerHTML = htmlString;

    const copyToClipboardButtonEl = element.querySelector(".clipboard");

    const copyFromEl = element.querySelector("#skintest-promocode");

    initCopyToClipboardButton({
        button: copyToClipboardButtonEl,
        copyFromEl,
        callback: () => {
            notifyWithText("Промокод успешно скопирован");
        },
    });

    return element;
}
