export function result() {
    let swiper;
    const problems = document.querySelectorAll('.ai-result__problems .ai-checker__input');
    let activeProblem = document.querySelector('.ai-result__details-problem-desc_active');

    const tabs = function() {
        const els = document.querySelectorAll('.ai-result__tab');

        if (!els.length) return;

        let elsContainer = document.querySelector('.ai-result__tabs');
        let activeEl = elsContainer.querySelector('.ai-result__tab_active');

        let contentItems = document.querySelectorAll('.ai-result__content');
        let contentActiveItem = document.querySelector('.ai-result__content_active');

        function recBlocks(el) {
            const blocks = ['ai-consultation', 'ai-specialists'];

            if (el.getAttribute('data-for') == '#recomendations') {
                blocks.forEach(block => {
                        document.querySelector('.' + block)?.classList.add(block + '_active');
                })
            }
            else {
                blocks.forEach(block => {
                    document.querySelector('.' + block)?.classList.remove(block + '_active');
                })
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }

        els.forEach(el => {
            el.addEventListener('click', () => {
                if (el.classList.contains('ai-result__tab_active')) return;

                activeEl.classList.remove('ai-result__tab_active')
                activeEl = el;
                activeEl.classList.add('ai-result__tab_active')

                contentActiveItem.classList.remove('ai-result__content_active')
                contentActiveItem = document.querySelector(el.getAttribute('data-for'));
                contentActiveItem.classList.add('ai-result__content_active')

                recBlocks(el);

                calculateProductMore();
            })
        })

        let toTabItems = document.querySelectorAll('.ai-to-tab');

        toTabItems.forEach(el => {
            el.addEventListener('click', () => {
                activeEl.classList.remove('ai-result__tab_active')
                activeEl = document.querySelector('.ai-result__tab[data-for="' + el.getAttribute('data-for') + '"]');
                activeEl.classList.add('ai-result__tab_active')

                contentActiveItem.classList.remove('ai-result__content_active')
                contentActiveItem = document.querySelector(el.getAttribute('data-for'));
                contentActiveItem.classList.add('ai-result__content_active')

                calculateProductMore();
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
        })
    }()

    const slider = function() {
        const el = document.querySelector('.ai-result__details-slider');

        if (!el) return;

        swiper = new Swiper('.ai-result__details-slider .swiper', {
            loop: true,
            pagination: {
                el: '.ai-result__details-slider-pagination',
                clickable: true
            },
        
            navigation: {
                nextEl: '.ai-result__details-slider-btn_next',
                prevEl: '.ai-result__details-slider-btn_prev',
            },
        });

        swiper.on('slideChange', function (swiper) {
            if (problems.length) {
                problems[swiper.realIndex].checked = true;

                activeProblem.classList.remove('ai-result__details-problem-desc_active')
                activeProblem = document.querySelector('#' + problems[swiper.realIndex].value);
                activeProblem.classList.add('ai-result__details-problem-desc_active')
            };
        });
    }()

    const checkMain = function() {
        const els = document.querySelectorAll('.product__check-main');

        if (!els.length) return;

        els.forEach(el => {
            const product = document.querySelector(el.getAttribute('data-for'));
            product.classList.add('product_not-main');

            el.addEventListener('click', () => {
                product.classList.toggle('product_not-main');
            })
        })
    }()

    const moreTabs = function() {
        const els = document.querySelectorAll('.ai-result__more-products-tab');

        if (!els.length) return;

        let elsContainer = document.querySelector('.ai-result__more-products-tabs');
        let activeEl = elsContainer.querySelector('.ai-result__more-products-tab_active');

        let contentItems = document.querySelectorAll('.ai-result__more-products-items');
        let contentActiveItem = document.querySelector('.ai-result__more-products-items_active');
        
        els.forEach(el => {
            el.addEventListener('click', () => {
                if (el.classList.contains('ai-result__more-products-tab_active')) return;

                activeEl.classList.remove('ai-result__more-products-tab_active')
                activeEl = el;
                activeEl.classList.add('ai-result__more-products-tab_active')

                //contentActiveItem.classList.remove('ai-result__more-products-items_active')
                document.querySelector('.ai-result__more-products-items_active').classList.remove('ai-result__more-products-items_active')
                contentActiveItem = document.querySelector(el.getAttribute('data-for'));
                contentActiveItem.classList.add('ai-result__more-products-items_active')

                //calculateProductMore();
            })
        })
    }()

    const problemsChange = function() {
        if (!problems.length) return;


        problems.forEach((el, idx) => {
            el.addEventListener('change', () => {
                swiper.slideTo(idx + 1);
                activeProblem.classList.remove('ai-result__details-problem-desc_active')
                activeProblem = document.querySelector('#' + el.value);
                activeProblem.classList.add('ai-result__details-problem-desc_active')
            })
        })
    }()

    const consultationTabs = function() {
        const els = document.querySelectorAll('.ai-consultation__tab');

        if (!els.length) return;

        let activeTab = document.querySelector('.ai-consultation__tab_active');
        let activeContent = document.querySelector('.ai-consultation__content-info_active');

        els.forEach(el => {
            el.addEventListener('click', () => {
                activeTab.classList.remove('ai-consultation__tab_active')
                activeTab = el;
                activeTab.classList.add('ai-consultation__tab_active')

                activeContent.classList.remove('ai-consultation__content-info_active')
                activeContent = document.querySelector(el.getAttribute('data-for'))
                activeContent.classList.add('ai-consultation__content-info_active')
            })
        })
    }()

    const map = function() {
        const els = document.querySelectorAll('.ai-map');

        if (!els.length) return;

        els.forEach(el => {
            initMap(el);
        });

        async function initMap(el) {
            try {
                // Промис `ymaps3.ready` будет зарезолвлен, когда загрузятся все компоненты основного модуля API
                await ymaps3.ready;

                const {YMap, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapMarker} = ymaps3;

                // Иницилиазируем карту
                const map = new YMap(
                    // Передаём ссылку на HTMLElement контейнера
                    el,

                    // Передаём параметры инициализации карты
                    {
                        location: {
                            // Координаты центра карты
                            center: [el.getAttribute('data-lng'), el.getAttribute('data-lat')],
                            // Уровень масштабирования
                            zoom: 17
                        }
                    }
                );
                

                // Добавляем слой для отображения схематической карты

                map
                    .addChild(new YMapDefaultSchemeLayer())
                    .addChild(new YMapDefaultFeaturesLayer({zIndex: 1800}))

                const content = document.createElement('div');
                content.innerHTML = '<div class="ai-consultation__content-map-icon"><div class="ai-consultation__content-map-icon-text">' + el.getAttribute('data-text') + '</div></div>';

                // // Добавление маркера на карту
                const marker  = new YMapMarker({coordinates: [el.getAttribute('data-lng'), el.getAttribute('data-lat')]}, content);

                map.addChild(marker);

            } catch (error) {
                console.error('Ошибка при инициализации карты:', error);
            }
        }
}();

    const specialists = function() {
        const el = document.querySelector('.ai-specialists__slider');

        if (!el) return;

        const slider = new Swiper('.ai-specialists__slider .swiper', {
            slidesPerView: 'auto',
            spaceBetween: 24,
            navigation: {
                nextEl: '.ai-specialists__slider .swiper-button-next',
                prevEl: '.ai-specialists__slider .swiper-button-prev',
            },
            breakpoints: {
                1250: {
                    slidesPerView: 3,
                }
            }
        });
    }()

    const moreProds = function() {
        const el = document.querySelector('.ai-result__more-products-tabs');

        if (!el) return;
        
        const swiper = new Swiper('.ai-result__more-products-tabs .swiper', {
            freeMode: true,
            slidesPerView: 'auto'
        });
        el.swiper = swiper;
    }()

    const consultCities = function() {
        const el = document.querySelector('.ai-consultation__tabs');

        if (!el) return;
        
        const swiper = new Swiper('.ai-consultation__tabs .swiper', {
            freeMode: true,
            slidesPerView: 'auto'
        });
    }()

    const prodsSlider = function() {
        const els = document.querySelectorAll('.ai-result__products-items');

        if (!els.length) return;

        els.forEach(el => {
            const swiper = new Swiper(el.querySelector('.swiper'), {
                slidesPerView: 'auto',
                spaceBetween: 25,
                watchSlidesProgress: true,
                breakpoints: {
                    992: {
                        allowTouchMove: false,
                        spaceBetween: 0,
                    }
                }
            });
            el.swiper = swiper;
        })
    }()

    const prodsMoreSlider = function() {
        const els = document.querySelectorAll('.ai-result__more-products-items');

        if (!els.length) return;

        els.forEach(el => {
            const swiper = new Swiper(el.querySelector('.swiper'), {
                slidesPerView: 'auto',
                spaceBetween: 25,
                watchSlidesProgress: true,
                breakpoints: {
                    992: {
                        allowTouchMove: false,
                        spaceBetween: 0,
                    }
                }
            });
            el.swiper = swiper;
        })
    }()

    // const starsSync = function() {
    // 	const starsRes = document.querySelectorAll('.ai-result__evaluate-stars');
    // 	const starsModal = document.querySelector('.modal-custom-default__stars');

    // 	if ((!starsRes) || (!starsModal)) return;

    // 	const starsModalItems = starsModal.querySelectorAll('.stars__star');
    // 	const starsModalValueKeeper = starsModal.querySelector('.stars__star-value');
            
    // 	let modalInitiator = null;
    // 	// let modalInitiatorFuuid = modalInitiator.getAttribute('data-fuuid');


    // 	starsRes.forEach((stars, idx) => {
    // 		// stars.setAttribute('data-stars-idx', idx);

    // 		modalInitiator = stars.parentElement;
    // 		// let modalInitiatorFuuid = modalInitiator.getAttribute('data-fuuid');

    // 		const starsResItems = stars.querySelectorAll('.stars__star');
    // 		const starsResValueKeeper = stars.querySelector('.stars__star-value');

    // 		starsResItems.forEach(star => {
    // 			star.addEventListener('click', () => {
    // 				const value = star.getAttribute('data-value');
    // 				starsModalValueKeeper.value = value;

    // 				starsModalItems.forEach(s => {
    // 					s.classList.remove('selected');

    // 					if (s.getAttribute('data-value') == star.getAttribute('data-value')) {
    // 						s.classList.add('selected');
    // 					}
    // 				});
    // 			})
    // 		})
    // 	})

    // 	starsModalItems.forEach(star => {
    // 		star.addEventListener('click', () => {
    // 			const value = star.getAttribute('data-value');
    // 			const starsResValueKeeper = modalInitiator.querySelector('.stars__star-value');
    // 			const starsResItems = modalInitiator.querySelectorAll('.stars__star');

    // 			starsResValueKeeper.value = value;

    // 			starsResItems.forEach(s => {
    // 				s.classList.remove('selected');

    // 				if (s.getAttribute('data-value') == star.getAttribute('data-value')) {
    // 					s.classList.add('selected');
    // 				}
    // 			});
    // 		})
    // 	})
    // }()
}