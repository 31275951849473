export const yclientsCodes = {
    msk: {
        serviceType: "msk",
        serviceId: 9780695,
        companyId: 513860,
        waitingEmployeeId: 2847156,
        ym: "zapiskosmetologmsk",
        routes: [
            { coordinates: [55.74240055227125, 37.629249528082795] },
            { coordinates: [55.728998376950045, 37.622801086137166] },
            { coordinates: [55.72686359141693, 37.624820652910294] },
        ],
    },
    arbat: {
        serviceType: "arbat",
        serviceId: 17082758,
        companyId: 1130629,
        waitingEmployeeId: 3480410,
        ym: "zapiskosmetologarbat",
        routes: [
            { coordinates: [55.75239336382937, 37.60334197075219] },
            { coordinates: [55.74791675883498, 37.583713473178555] },
        ],
    },
    "moskva-arbatskaya": {
        serviceType: "arbat",
        serviceId: 17082758,
        companyId: 1130629,
        waitingEmployeeId: 3480410,
        ym: "zapiskosmetologarbat",
        routes: [
            { coordinates: [55.75239336382937, 37.60334197075219] },
            { coordinates: [55.74791675883498, 37.583713473178555] },
        ],
    },
    spb: {
        serviceType: "spb",
        serviceId: 13285208,
        companyId: 898200,
        waitingEmployeeId: 3153841,
        ym: "zapiskosmetologspb",
        routes: [
            { coordinates: [59.92082212681292, 30.35496667872799] },
            { coordinates: [59.92214128243348, 30.336002039117044] },
            { coordinates: [59.92069027090646, 30.32954398987001] },
        ],
    },
    kgd: {
        serviceType: "kgd",
        serviceId: 10132996,
        companyId: 710369,
        waitingEmployeeId: 3803748,
        ym: "zapiskosmetologkgd",
        routes: [
            { coordinates: [54.72080187879709, 20.5164027493515] },
            { coordinates: [54.72127930746956, 20.514613715938577] },
            { coordinates: [54.72033111748563, 20.513247258774037] },
        ],
    },
    ekb: {
        serviceType: "ekb",
        serviceId: 12107253,
        companyId: 833611,
        waitingEmployeeId: 3235964,
        ym: "zapiskosmetologekb",
        routes: [
            { coordinates: [56.836268904323276, 60.59967207261656] },
            { coordinates: [56.82729159078693, 60.602086635480475] },
            { coordinates: [56.842881362414666, 60.632605699825376] },
        ],
    },
    kzn: {
        serviceType: "kzn",
        serviceId: 14953538,
        companyId: 992338,
        waitingEmployeeId: 3081376,
        ym: "zapiskosmetologkzn",
        routes: [
            { coordinates: [55.78520406883622, 49.12594698812264] },
            { coordinates: [55.777582140641144, 49.14114750062091] },
        ],
    },
    nsk: {
        serviceType: "nsk",
        companyId: 1150640,
        serviceId: 17213255,
        waitingEmployeeId: 3550148,
        ym: "zapiskosmetolognsk",
        routes: [
            { coordinates: [55.04230738524153, 82.91668180274112] },
            { coordinates: [55.042842090331725, 82.92049883109412] },
        ],
    },
    online: {
        serviceType: "online",
        serviceId: 11887750,
        companyId: 820982,
        waitingEmployeeId: undefined,
        ym: "SKIN_REQUEST",
    },
};
