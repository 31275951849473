import Swiper from "swiper";
/**
 *
 * @param {HTMLElement} wrapper element with class "banner-slider__wrapper"
 */
function initSlider(wrapper) {
    const id = wrapper.id;

    return new Swiper(`#${id} > .banner-slider`, {
        slidesPerView: "auto",
        spaceBetween: 8,
        slideClass: "banner-slider__slide",
        navigation: {
            prevEl: `#${id} .banner-slider-arrows-left`,
            nextEl: `#${id} .banner-slider-arrows-right`,
        },
        breakpoints: {
            992: {
                slidesPerView: 1,
            },
        },
    });
}

export function initBannerSliders() {
    const slidersWrappers = document.querySelectorAll(".banner-slider__wrapper");

    if (!slidersWrappers.length) return;

    slidersWrappers.forEach((wrapper, index) => {
        wrapper.id = `banner-slider_wrapper-${index}`;

        initSlider(wrapper);
    });
}
