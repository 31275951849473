export function initModals() {
    const openEvent = new Event("openmodal");

    const closeEvent = new Event("closemodal");

    const closeModals = function () {
        document.querySelectorAll(".modal").forEach((element) => {
            element.classList.remove("modal_active");
            element.dispatchEvent(closeEvent);
        });
    };

    const openModal = function (modal, initiator = null) {
        closeModals();
        const modalElement = document.querySelector(modal);
        modalElement.classList.add("modal_active");
        modalElement.dispatchEvent(openEvent);

        if ((modal == '#clinic-modal') && (initiator ? (initiator.hasAttribute('data-not-reset-clinic')?false:true) : true)) {
            const openService = initiator?initiator.getAttribute('data-service-id'):null;
            const openSpecialist = initiator?initiator.getAttribute('data-specialist-id'):null;

            const dynamicPart = modalElement.querySelector('.modal-clinic__dynamic-part');
            dynamicPart.classList.remove('modal-clinic__dynamic-part--specialist');

            const calendarEl = modalElement.querySelector('.modal-clinic__calendar');
            
            const clinicConsult = document.querySelector('#clinic-consult');

            const timeContainer = document.querySelector('#clinic-time-block');
            const doctorsContainer = document.querySelector('#clinic-doctors-block');
            const servicesContainer = document.querySelector('#clinic-service-block');

            const specialists = modalElement.querySelector('#clinic-doctors');
            const services = modalElement.querySelector('#clinic-service');

            const serviceSelect = servicesContainer.querySelector('#clinic-service');
            serviceSelect.choices.destroy();
            serviceSelect.choices.init(serviceSelect.choicesOptions);

            const doctorsSelect = doctorsContainer.querySelector('#clinic-doctors');
            doctorsSelect.choices.destroy();
            doctorsSelect.choices.init(doctorsSelect.choicesOptions);

            const timeSelect = timeContainer.querySelector('#clinic-time');
            timeSelect.choices.destroy();
            timeSelect.choices.init(timeSelect.choicesOptions);

            servicesContainer.classList.remove('visually-hidden');
            doctorsContainer.classList.remove('visually-hidden');

            let isSpecialist = !!initiator.getAttribute('data-specialist-id');


            if (isSpecialist) {
                servicesContainer.classList.add('visually-hidden');
            } else {
                doctorsContainer.classList.add('visually-hidden');
            }

            timeContainer.classList.add('visually-hidden');
            clinicConsult.classList.add('visually-hidden');

            if (isSpecialist) {
                dynamicPart.classList.add('modal-clinic__dynamic-part--specialist');
                specialists.openSpecialist = openSpecialist;

                if (specialists.querySelector('.choices__placeholder')) {
                    specialists.querySelector('.choices__placeholder').textContent = 'Загрузка специалистов...';
                }

                if (openSpecialist) {
                    specialists.choices.setChoiceByValue(parseInt(openSpecialist));
                }
                else {
                    if (specialists.querySelector('.choices__placeholder'))
                        specialists.querySelector('.choices__placeholder').textContent = 'Выберите специалиста';
                }

                async function setSpecialists() {
                    async function fetchSpecialists() {
                        const url = '/clinic/api/client/users';
                        // const url = 'files/services.json';
                        
                        try {
                                const response = await fetch(url);
                                if (!response.ok) {
                                        throw new Error(`HTTP error! status: ${response.status}`);
                                }
                
                                const data = await response.json();
                                if (data.err === 0) {
                                    return data.data.map(data => {return {value: data.id, label: `${data.lastName} ${data.firstName}`}});
                                } else {
                                    console.error('Ошибка в ответе API:', data.err);
                                }
                        } catch (error) {
                            console.error('Ошибка при запросе:', error);
                        }
                    }

                    const specialistsList = await fetchSpecialists();
                    

                    specialists.choices.clearChoices();
                    specialists.choices.setChoices(
                        specialistsList,
                        'value',
                        'label',
                        false,
                    );

                    // Это надо если услуги прогрузились уже после открытия модалки

                    const openSpecialist = specialists.openSpecialist;

                    if (specialists.querySelector('.choices__placeholder')) {
                        specialists.querySelector('.choices__placeholder').textContent = 'Загрузка специалистов...';
                    }

                    if (openSpecialist) {
                        specialists.choices.setChoiceByValue(parseInt(openSpecialist));
                    }
                    else {
                        if (specialists.querySelector('.choices__placeholder'))
                            specialists.querySelector('.choices__placeholder').textContent = 'Выберите специалиста';
                    }

                    function calendarRender() {
                        // console.log(services.choices.getValue().value);

                        if (!specialists.choices.getValue().value) {
                            calendarEl.classList.add('visually-hidden');
                        }
                        else {
                            calendarEl.classList.remove('visually-hidden');
                        }
                        servicesContainer.classList.remove('visually-hidden');
                        doctorsContainer.classList.remove('visually-hidden');
        
                        let isSpecialist = !!initiator.getAttribute('data-specialist-id');
        
                        if (isSpecialist) {
                                servicesContainer.classList.add('visually-hidden');
                        } else {
                                doctorsContainer.classList.add('visually-hidden');
                        }
        
                        timeContainer.classList.add('visually-hidden');
                        
                        if (calendarEl) {
                            function renderCalendar() {
                                return new Promise((resolve) => {
                                    const checkRender = setInterval(() => {
                                        try {
                                            calendarEl.calendar.destroy();
                                            calendarEl.calendar.render();
                                            if (calendarEl.querySelector('.fc')) {
                                                clearInterval(checkRender);
                                                
                                                resolve();
                                            }
                                        } catch (error) {
                                            console.error('Не получается отрендерить календарь');
                                        }
                                        
                                    }, 100); // Проверка каждые 100 мс
                                });
                            }
                            
                            renderCalendar().then(() => {
                                console.log('Календарь успешно отрендерен!');
                            });
                        }
                    }

                    services.choices.passedElement.element.removeEventListener('change', services.calendarRender);
                    specialists.choices.passedElement.element.removeEventListener('change', specialists.calendarRender);

                    specialists.calendarRender = calendarRender;

                    calendarRender();

                    specialists.choices.passedElement.element.addEventListener('change', specialists.calendarRender);
                }

                setSpecialists();
            }
            else {
                services.openService = openService;

                if (services.querySelector('.choices__placeholder')) {
                    services.querySelector('.choices__placeholder').textContent = 'Загрузка услуг...';
                }

                if (openService) {
                    services.choices.setChoiceByValue(parseInt(openService));
                }
                else {
                    if (services.querySelector('.choices__placeholder'))
                        services.querySelector('.choices__placeholder').textContent = 'Выберите услугу';
                }

                async function setServices() {
                    async function fetchServices() {
                        const url = '/clinic/api/client/services';
                        // const url = 'files/services.json';
                        
                        try {
                                const response = await fetch(url);
                                if (!response.ok) {
                                        throw new Error(`HTTP error! status: ${response.status}`);
                                }
                
                                const data = await response.json();
                                if (data.err === 0) {
                                    return data.data.map(data => {return {value: data.id, label: data.name}});
                                } else {
                                    console.error('Ошибка в ответе API:', data.err);
                                }
                        } catch (error) {
                            console.error('Ошибка при запросе:', error);
                        }
                    }

                    const servicesList = await fetchServices();

                    services.choices.clearChoices();
                    services.choices.setChoices(
                        servicesList,
                        'value',
                        'label',
                        false,
                    );

                    // Это надо если услуги прогрузились уже после открытия модалки

                    const openService = services.openService;

                    if (services.querySelector('.choices__placeholder')) {
                        services.querySelector('.choices__placeholder').textContent = 'Загрузка услуг...';
                    }

                    if (openService) {
                        services.choices.setChoiceByValue(parseInt(openService));
                    }
                    else {
                        if (services.querySelector('.choices__placeholder'))
                            services.querySelector('.choices__placeholder').textContent = 'Выберите услугу';
                    }

                    function calendarRender(e) {
                        // console.log(services.choices.getValue().value);

                        if (!services.choices.getValue().value) {
                            calendarEl.classList.add('visually-hidden');
                        }
                        else {
                            calendarEl.classList.remove('visually-hidden');
                        }
                        servicesContainer.classList.remove('visually-hidden');
                        doctorsContainer.classList.remove('visually-hidden');
        
                        let isSpecialist = !!initiator.getAttribute('data-specialist-id');
        
                        if (isSpecialist) {
                            servicesContainer.classList.add('visually-hidden');
                        } else {
                            doctorsContainer.classList.add('visually-hidden');
                        }
        
                        timeContainer.classList.add('visually-hidden');

                        if (!isSpecialist) {
                            if (e) {
                                const selectedValue = e.target.value; // Получаем значение выбранного элемента
                               
                                clinicConsult.classList.add('visually-hidden')

                                if (selectedValue == 10 || selectedValue == 15) {
                                    clinicConsult.classList.remove('visually-hidden')
                                }
                            }
                            
                        }

                        
                        if (calendarEl) {
                            function renderCalendar() {
                                return new Promise((resolve) => {
                                    const checkRender = setInterval(() => {
                                        try {
                                            calendarEl.calendar.destroy();
                                            calendarEl.calendar.render();
                                            if (calendarEl.querySelector('.fc')) {
                                                clearInterval(checkRender);
                                                
                                                resolve();
                                            }
                                        } catch (error) {
                                            console.error('Не получается отрендерить календарь');
                                        }
                                        
                                    }, 100); // Проверка каждые 100 мс
                                });
                            }
                            
                            renderCalendar().then(() => {
                                console.log('Календарь успешно отрендерен!');
                            });
                        }
                    }

                    services.choices.passedElement.element.removeEventListener('change', services.calendarRender);
                    specialists.choices.passedElement.element.removeEventListener('change', specialists.calendarRender);

                    services.calendarRender = calendarRender;

                    calendarRender();

                    services.choices.passedElement.element.addEventListener('change', services.calendarRender);
                }

                setServices();
            }

            const formName = initiator.hasAttribute('data-form-name')?initiator.getAttribute('data-form-name'):'Неизвестная форма';
            const formNameInput = modalElement.querySelector('[name="form-name"]');

            if (formNameInput) {
                formNameInput.value = formName;
            }
        }
    };

    window.openModal = openModal;
    window.closeModals = closeModals;

    const els = document.querySelectorAll("[data-open-modal]");

    els.forEach((element) => {
        element.addEventListener("click", (e) => {
            e.preventDefault();
            openModal(e.currentTarget.getAttribute("data-open-modal"), element);
        });
    });

    const close = document.querySelectorAll("[data-close-modal]");

    if (close) {
        close.forEach((el) => {
            el.addEventListener("click", (e) => {
                closeModals();
            });
        });
    }
}
