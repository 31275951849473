import { API } from "../../../services/api/endpoints/index.js";
import { generateSearchCardHTML } from "./getSearchCard.js";
import { generateHintListHTML } from "./hints.js";

const DEBOUNCE_TIME = 500;

let debounceTimer = null;

const loader = document.createElement("div");

loader.classList.add("loader");

loader.style.width = "20px";
loader.style.height = "20px";
loader.style.position = "absolute";

const searchCardList = document.querySelector(".search-card-list");

function changeLoadingStatus(isLoading = false) {
    if (!searchCardList) return;

    searchCardList.innerHTML = "";

    if (isLoading) {
        searchCardList.append(loader);
        searchCardList.style.display = "flex";
        searchCardList.style.justifyContent = "center";
        searchCardList.style.alignItems = "center";
        searchCardList.style.position = "relative";
    } else {
        searchCardList.style.display = null;
        searchCardList.style.justifyContent = null;
        searchCardList.style.alignItems = null;
        searchCardList.style.position = null;
    }
}

function notFound() {
    if (!searchCardList) return;

    searchCardList.innerHTML = "<div class='search-card-list__not-found'>По вашему запросу ничего не найдено</div>";
}

async function getSearchCardsAndHintsFromAPI(queryString) {
    const response = await API.search.searchProducts(queryString);

    const responseData = await response.json();

    const { data, suggest } = responseData;

    const result = { cards: [] };

    if (data) {
        const cards = data.map(generateSearchCardHTML);

        result.cards = cards;
    }

    if (suggest) {
        const { popular, categs } = suggest;

        const popularHints = generateHintListHTML(popular, "Часто ищут");

        const categsHints = generateHintListHTML(categs, "Популярные категории");

        result.hints = { popularHints, categsHints };
    }

    return result;
}

export function handleSearch(queryString) {

    const hintsWrapper = document.querySelector(".search-hints");
    
    const hintsWrapperContent = hintsWrapper.innerHTML;
    
    const emptySearchCardItem = document.createElement("li");
    
    emptySearchCardItem.classList.add("search-card-list__item");
    
    if (debounceTimer) clearTimeout(debounceTimer);

    if (!searchCardList) return;

    // input debounce
    debounceTimer = setTimeout(async () => {
        changeLoadingStatus(true);

        const {
            cards,
            hints: { popularHints, categsHints },
        } = await getSearchCardsAndHintsFromAPI(queryString);

        hintsWrapper.innerHTML = "";

        if (popularHints) {
            hintsWrapper.append(popularHints);
        }

        if (categsHints) {
            hintsWrapper.append(categsHints);
        }

        if (hintsWrapper.innerHTML === "") {
            hintsWrapper.innerHTML = hintsWrapperContent;
        }

        changeLoadingStatus(false);

        if (queryString === "") {
            searchCardList.innerHTML = "";
            hintsWrapper.style.display = "flex";
        } else {
            if (cards.length === 0) {
                notFound();
            } else {
                searchCardList.append(...cards, emptySearchCardItem);
            }
            hintsWrapper.style.display = "none";
        }
    }, [DEBOUNCE_TIME]);
}
