import { initJournalSectionSlides } from "./journal-section-products/index.js";
import { initJournalArticleTab } from "./article-tab/index.js";
import { slides } from './settings.js';

export function initJournalPageModules() {
    slides.forEach(el => {
        initJournalSectionSlides({
            list: `.js-slider-${el.name}-list`,
            slideClass: `js-slider-${el.name}-slid`,
            nextEl: `.js-slider-${el.name}-next`,
            prevEl: `.js-slider-${el.name}-prev`,
            slidesPerView: el.slidesPerView,
        });
    })
    initJournalArticleTab()

}
