import Swiper from "swiper";

function initCaseSlider() {
    const slider = new Swiper("#horeca-slider", {
        slidesPerView: 1,
        spaceBetween: 0,
        navigation: {
            prevEl: "#horeca-slider + .slider-navigation > .slider-navigation-prev",
            nextEl: "#horeca-slider + .slider-navigation > .slider-navigation-next",
        },
    });
}

function initProdsSlider() {
    const slider = new Swiper("#horeca-prods__slider", {
        slidesPerView: "auto",
        spaceBetween: 16,
        navigation: {
            prevEl: "#horeca-prods__slider + .slider-navigation > .slider-navigation-prev",
            nextEl: "#horeca-prods__slider + .slider-navigation > .slider-navigation-next",
        },
        breakpoints: {
            576: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            },
        }
    });
}

export function initHorecaPageModules() {
    const horecaCaseSlider = document.getElementById("horeca-slider");

    const horecaProdsSlider = document.getElementById("horeca-prods__slider");

    if (horecaCaseSlider) {
        initCaseSlider();
    }

    if (horecaProdsSlider) {
        initProdsSlider();
    }
}
