export function initDetailMarkets() {
    const marketsContainer = document.querySelector(".detail__marketplaces");

    if (!marketsContainer) return;

    const markets = marketsContainer.querySelectorAll(".detail__marketplace");
    const hiddenMarkets = marketsContainer.querySelectorAll(".detail__marketplace--hidden");
    const hiddenMarketsLength = hiddenMarkets.length;
    const moreBtn = marketsContainer.querySelector(".detail__show-marketplaces");
    const moreBtnArrow = moreBtn.querySelector(".detail__show-marketplaces-arrow");
    const moreBtnTextContainer = moreBtn.querySelector(".detail__show-marketplaces-text");
    const moreBtnText = moreBtnTextContainer.textContent;
    const moreBtnAltText = moreBtn.getAttribute("data-alternate");
    let isShow = false;

    function toggleBtn(original) {
        if (original) {
            moreBtnTextContainer.textContent = moreBtnText;
        } else {
            moreBtnTextContainer.textContent = moreBtnAltText;
        }

        moreBtnArrow.classList.toggle("detail__show-marketplaces-arrow--alternative", !original);
    }

    function toggleMarkets(show) {
        toggleBtn(!show);

        hiddenMarkets.forEach((hiddenMarket, index) => {
            let delay = index * 20;

            if (!show) {
                delay = hiddenMarketsLength * 20 - delay;
                // это чтобы элементы с конца прятались
            }

            setTimeout(() => {
                hiddenMarket.classList.toggle("detail__marketplace--hidden", !show);
            }, delay);
        });
    }

    moreBtn.addEventListener("click", () => {
        isShow = !isShow;
        toggleMarkets(isShow);
    });
}
