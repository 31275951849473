import Swiper from "swiper";
import { API } from "../../../services/api/endpoints/index.js";
import { parseCenterInfoFromHTMLStr } from "../../skin-centers/util.js";
import { generateDoctorCardHTML } from "../../skin-centers/doctor.js";

const doctorsListEl = document.querySelector(".training__team .js-slider-doctors-list .swiper-wrapper");

function initSlider() {
    return new Swiper(".js-slider-doctors-list", {
        slidesPerView: "auto",
        navigation: {
            prevEl: "#DoctorsSlider .slider-navigation-prev",
            nextEl: "#DoctorsSlider .slider-navigation-next",
        },
        breakpoints: {
            992: {
                allowTouchMove: true,
            },
        },
    });
}

export async function initTrainingTeamSectionSlides() {
    if (!doctorsListEl) return;

    const isProd = window.location.hostname !== "localhost";

    const baseImgDir = isProd ? "/local/templates/geltek2024/frontend" : "";

    const doctors = [
        {
            id: 0,
            name: "Поярков Никита Александрович",
            position: {
                title: "Врач-дерматолог, косметолог",
            },
            avatar_big: `${baseImgDir}/img/upload/training/team/Poyarkov-Nikita-Aleksandrovich.jpg`,
            description: "Эксперт в области терапевтической, аппаратной и инъекционной косметологии. Тренер компании Гельтек.",
        },
        {
            id: 1,
            name: "Бачище Никита Владленович",
            position: {
                title: "Практикующий косметолог",
            },
            avatar_big: `${baseImgDir}/img/upload/training/team/Bachishche-Nikita-Vladlenovich.png`,
            description: "Сертифицированный тренер компании Гельтек по профессиональной косметике.",
        },
        {
            id: 2,
            name: "Егорова Ирина Викторовна",
            position: {
                title: "Врач-косметолог, физиотерапевт.",
            },
            avatar_big: `${baseImgDir}/img/upload/training/team/Egorova-Irina-Viktorovna.png`,
            description: "Сертифицированный тренер-методист компании Гельтек по профессиональной косметике.",
        },
        {
            id: 3,
            name: "Шапранова Татьяна Николаевна",
            position: {
                title: "Практикующий косметолог",
            },
            avatar_big: `${baseImgDir}/img/upload/training/team/Shapranova-Tatyana-Nikolaevna.png`,
            description: "Сертифицированный тренер компании Гельтек. Специалист по безоперационному омоложению.",
        },
    ];

    doctors.reverse().forEach((doctor) => {
        const reviews = undefined;

        const doctorCard = generateDoctorCardHTML({
            doctor,
            city: "online",
            serviceType: "online",
            reviews,
            appointment: false,
        });

        doctorsListEl.prepend(doctorCard);
    });
    initSlider();
}
