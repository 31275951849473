export function initToCart() {
    const mainContainer = document.querySelector(".detail__to-cart-controls");

    if (!mainContainer) return;

    const fixer = mainContainer.querySelector(".detail__to-cart-controls-fixer");
    const bottomMenu = document.querySelector(".footer__mobile-bottom-menu");

    function action() {
        if (window.innerWidth >= 992) {
            fixer.classList.remove("detail__to-cart-controls-fixer--fixed");

            return;
        }

        const topLimit = mainContainer.getBoundingClientRect().top;

        if (bottomMenu) {
            fixer.style.setProperty("--fixer-indent", bottomMenu.offsetHeight + "px");
        }

        if (topLimit <= 0) {
            mainContainer.style.height = fixer.offsetHeight;
            fixer.classList.add("detail__to-cart-controls-fixer--fixed");
        } else {
            mainContainer.style.height = "";
            fixer.classList.remove("detail__to-cart-controls-fixer--fixed");
        }
    }

    action();

    window.addEventListener("scroll", () => {
        action();
    });
}
