import { BodyScroll } from "../../../utils/body-scroll.js";
import { openMainMenu } from "../../main-menu/index.js";
import { closeSearchMenu } from "../../search-menu/index.js";
import { closeCatalogMenu, openCatalogMenu } from "../index.js";

const catalogMenuMobile = document.querySelector(".catalog-menu--mobile");

const catalogMobileCloseButton = catalogMenuMobile?.querySelector(".catalog-menu__close");

const cosmeticsMenu = document.querySelector(".catalog-menu--cosmetics-mobile");

const medicineMenu = document.querySelector(".catalog-menu--medicine-mobile");

const cosmeticsCard = document.querySelector(".catalog-menu__card--cosmetics");

const medicineCard = document.querySelector(".catalog-menu__card--medicine");

const searchMenu = document.querySelector(".search-menu");

const catalogBack = catalogMenuMobile?.querySelector(".catalog-menu__back");

export function closeCatalogMenuMobile() {
    closeCatalogMenu();
}

export function openCatalog(menuContainer) {
    closeCatalogMenuMobile();
    menuContainer.classList.add("catalog-menu--open");

    if (searchMenu && searchMenu.classList.contains("search-menu--open")) {
        closeSearchMenu();
    }
    BodyScroll.lock();
}

export function closeCatalog(menuContainer) {
    closeCatalogMenuMobile();
    menuContainer.classList.remove("catalog-menu--open");
    BodyScroll.lock();
}

function initCatalog({
    menuContainer,
    handleClose,
}) {
    const catalogCloseButton = menuContainer?.querySelector(".catalog-menu__close");

    const catalogMenuContent = menuContainer.querySelector(".catalog-menu__content");

    const catalogMenuTitle = menuContainer?.querySelector(".catalog-menu__title");

    const catalogCategories = menuContainer?.querySelector(".catalog-menu__card-categories-wrapper");

    const backButton = catalogCategories?.querySelector(".catalog-menu__card-categories-back");

    const backToCatalog = menuContainer.querySelector(".catalog-menu__back");

    function handleCategoryChange(event) {
        if (backButton.classList.contains("nested-list__back--hidden")) {
            backButton.replaceWith(catalogMenuTitle);
            catalogMenuContent.style.overflowY = "auto";
        } else {
            catalogMenuTitle.replaceWith(backButton);
            catalogMenuContent.style.overflowY = "hidden";
        }

        catalogMenuContent.scrollTo({ top: 0 });
    }

    backButton?.addEventListener("click", handleCategoryChange);
    backButton?.addEventListener("dblclick", handleCategoryChange);

    try {
        backToCatalog.addEventListener("click", () => {
            closeCatalog(menuContainer);
            openCatalogMenu();
        });
    } catch (e) {
        console.log("backToCatalog catch");
        console.log(e);
    }

    const catalogCategoriesItems = catalogCategories?.querySelectorAll(".nested-list__node");

    catalogCategoriesItems?.forEach((category) => {
        category?.addEventListener("click", handleCategoryChange);
    });

    catalogCloseButton.addEventListener("click", handleClose);
}

export function initMobileCatalogMenu() {
    if (!catalogMenuMobile) return;

    catalogMobileCloseButton.addEventListener("click", closeCatalogMenuMobile);

    cosmeticsCard.addEventListener("click", () => openCatalog(cosmeticsMenu));

    medicineCard.addEventListener("click", () => openCatalog(medicineMenu));

    initCatalog({
        menuContainer: cosmeticsMenu,
        handleClose: () => closeCatalog(cosmeticsMenu),
    });

    initCatalog({
        menuContainer: medicineMenu,
        handleClose: () => closeCatalog(medicineMenu),
    });

    try {
        catalogBack.addEventListener("click", () => {
            closeCatalogMenuMobile();
            openMainMenu();
        });
    } catch (e) {
        console.log("catalogBack catch");
        console.log(e);
    }
}
