import Swiper from "swiper";

export function initTrainingResourcesSectionSlides() {
    const sliderElement = document.querySelector(".js-slider-resources");

    if (sliderElement) {
        const resourcesSwiper = new Swiper(sliderElement, {
            slidesPerView: "auto",
            spaceBetween: 16,
            slideClass: "resource-card",
            navigation: {
                prevEl: `.training-section__resources-slider-btns .slider-navigation-prev`,
                nextEl: `.training-section__resources-slider-btns .slider-navigation-next`,
            },
            breakpoints: {
                576: {
                    allowTouchMove: true,
                    spaceBetween: 16,
                },
                992: {
                    // allowTouchMove: false,
                    spaceBetween: 24,
                },
            },
        });
    }
}
