import IMask from "imask";

export function initPhoneMask() {
    const phoneInputs = document.querySelectorAll("input.mask-phone");

    phoneInputs.forEach((input) => {
        IMask(input, {
            mask: "+{7} 000 000 00 00",
        });
    });
}
