import { initDynamicTestByAPI } from "../../modules/dynamic-api-test/index.js";
import { initProductCards } from "../../modules/product-card/index.js";
import { initProductListSlidersWithTabs } from "../../modules/product-list-slider-with-tabs/index.js";
import { initAccordions } from "../../ui/accordion/index.js";
import { initTabs } from "../../ui/tabs/index.js";
import { initToggleButtonGroups } from "../../ui/toggle-button-group/index.js";
import { initProductSliders } from "../shared/product-slider/index.js";
import { getCatalogHTML } from "./getCatalog.js";
import { getPresentsHTML } from "./getPresents.js";
import { getSkinTypeHTML } from "../../modules/dynamic-api-test/getSkinType.js";
import { API } from "../../services/api/endpoints/index.js";

const startButton = document.querySelector("#StartSkinTest");
const closeButton = document.querySelector(".popup--skintest .popup__close");
const askQuestionBtn = document.querySelector(".ask-question");

const popup = document.querySelector(".popup--skintest");

function OpenPopup() {
    popup.classList.add("open");
}
function ClosePopup() {
    popup.classList.remove("open");
}

/**
 * @typedef {{
*      id: string,
*      title: string,
*      detailPageUrl: string,
*      imageUrl: string,
*      price: number,
*      fullprice: number,
*      addToCartUrl: string,
*      marketplaces: {
*          [key: string]: {
*              title: string,
*              link: string,
*          }
*      }
* }} Product
*/

/**
 * @typedef {{
*      count: number,
*      count_current: number,
*      state: string,
*      name: string,
*      detail: string,
*      recom: {
*          morning: {
*              title: string,
*              categories: {[key: string]: string[]}
*          },
*          evening: {
*              title: string,
*              categories: {[key: string]: string[]}
*           },
*          intensive: []
*          all: {
*              title: string,
*              categories: {[key: string]: string[]}
*           },
*      },
*      products: {
*          [key: number]: Product
*      }
* }} TestResult
*/

/**
 * @typedef {{
*      name: string,
*      morning: {
*          title: string,
*          categories: {
*              [key: string]: {id:number, products: Product[]}
*          }
*      },
*      evening: {
*          title: string,
*          categories: {
*              [key: string]: {id:number, products: Product[]}
*          }
*      },
*      all: {
*          title: string,
*          categories: {
*              [key: string]: {id:number, products: Product[]}
*          }
*      }
* }} AdaptedTestResultWithCategories
*/

/**
 *
 * @param {AdaptedTestResultWithCategories} data
 */
async function handleFinish(data) {
    const skinType = data.name;

    const main = document.querySelector("main");

	const about = document.querySelector(".section-about");

	if (about) {
		const aboutSection = document.querySelector(".section-about").closest(".section");

		const skintestBenefitsSection = document.querySelector(".section.section--skintest-benefits");

		const chooseCosmeticsSection = document.querySelector(".choose-cosmetics.choose-cosmetics--skintest");

		aboutSection.replaceWith(getSkinTypeHTML(skinType));

		skintestBenefitsSection.replaceWith(getPresentsHTML());

		main.insertBefore(getCatalogHTML(data), chooseCosmeticsSection);

		initProductCards();
		initAccordions();
		initTabs();
		initToggleButtonGroups();
		initProductListSlidersWithTabs();
		initProductSliders();
	} else {
		const aboutSectionResult = document.querySelector(".section.skintest");
		aboutSectionResult.replaceWith(getSkinTypeHTML(skinType));
	}


    ClosePopup();

	await initDynamicTestByAPI("test-layout--skintest", API.test.quiz, handleFinish);
	const startButtonAgain = document.querySelector("#StartSkinTest");
	startButtonAgain.addEventListener("click", OpenPopup);
}

export async function initSkintestPageModules() {
    if (!startButton || !closeButton) return;

    await initDynamicTestByAPI("test-layout--skintest", API.test.quiz, handleFinish);

    startButton.addEventListener("click", OpenPopup);
    closeButton.addEventListener("click", ClosePopup);

    if (askQuestionBtn) {
        askQuestionBtn.addEventListener("click", () => window.openModal("#feedback-modal-common"));
    }
}
