/* eslint-disable no-use-before-define */
import { createCodeVerification } from "../../../code-verificaton/index.js";

window.openSignIn = () => window.openModal(".modal.sign-in");

const signInModal = document.querySelector(".modal.sign-in");

function byPassInit() {
    const byPassForm = signInModal.querySelector(".sign-in__by-pass");

    const recoveryButton = byPassForm.querySelector(".sign-in__recovery-pass");

    const emailInput = byPassForm.querySelector("input[name='email']");

    const passwordlInput = byPassForm.querySelector("input[name='password']");

    const emailField = emailInput.parentElement.parentElement;

    const passwordField = passwordlInput.parentElement.parentElement;

    function correctCreds() {
        delete emailField.dataset.error;
        delete passwordField.dataset.error;
    }

    function wrongCreds() {
        emailField.dataset.error = " ";
        passwordField.dataset.error = "Данные введены неправильно или такого аккаунта не существует";
    }

    function login(event) {
        event.preventDefault();

        const email = emailInput.value;

        const password = passwordlInput.value;

        if (email !== "admin@mail.ru" || password !== "password") {
            wrongCreds();
        } else {
            window.openModal("#loyalty-program");
            correctCreds();
        }
    }

    byPassForm.addEventListener("submit", login);
    recoveryButton.addEventListener("click", window.openRecoveryPass);
}

function bySmsInit() {
    const signIncontent = signInModal.querySelector(".modal-center__content");

    const bySmsForm = signInModal.querySelector(".sign-in__by-sms");

    const phoneInput = bySmsForm.querySelector("input[name='phone']");

    const phoneField = phoneInput.parentElement.parentElement;

    let codeVerification = null;

    function correctPhone() {
        delete phoneField.dataset.error;
    }

    function wrongPhone() {
        phoneField.dataset.error = "Телефон указан неверно";
    }

    function resetSignInModal() {
        signInModal.classList.remove("sign-in--next");

        if (codeVerification) codeVerification.remove();
    }

    function codeVerified() {
        if (codeVerification) codeVerification.remove();

        resetSignInModal();

        window.closeModals();
    }

    function codeVerificationStart() {
        signInModal.classList.add("sign-in--next");

        codeVerification = createCodeVerification({
            code: "0000",
            onCodeVerified: codeVerified,
            onReset: resetSignInModal,
            title: "Вход по СМС",
            successText: "Авторизация прошла успешно",
            number: phoneInput.value,
        });

        signIncontent.append(codeVerification);
    }

    function getCodeSubmit(event) {
        event.preventDefault();
        const phone = phoneInput.value.replace(/\s/g, "");

        const isPhoneValid = phone.length === 12;

        if (!isPhoneValid) wrongPhone();
        else {
            correctPhone();
            codeVerificationStart();
        }
    }

    bySmsForm.addEventListener("submit", getCodeSubmit);
}

export function initSignIn() {
    if (!signInModal) return;

    byPassInit();
    bySmsInit();
}
