export function initProductOrderTabs() {
    if (!document.querySelector('.product-order__tabs')) return;

    document.querySelector('.product-order__tabs').addEventListener('click', function(e) {
        if (e.target.classList.contains('product-order__tabs-name')) {
            document.querySelectorAll('.product-order__tabs-name').forEach(tab => {
                tab.classList.remove('product-order__tabs-name_active');
                document.querySelector(tab.getAttribute('data-for')).classList.remove('product-order__tabs-content_active');
            })

            e.target.classList.add('product-order__tabs-name_active');
            document.querySelector(e.target.getAttribute('data-for')).classList.add('product-order__tabs-content_active');
        }
    })
}