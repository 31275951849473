export const yclientsCodes = {
    msk: {
        serviceId: 9780695,
        companyId: 513860,
        waitingEmployeeId: 2847156,
    },
    arbat: {
        serviceId: 17082758,
        companyId: 1130629,
        waitingEmployeeId: undefined,
    },
    "moskva-arbatskaya": {
        serviceId: 17082758,
        companyId: 1130629,
        waitingEmployeeId: undefined,
    },
    spb: {
        serviceId: 13285208,
        companyId: 898200,
        waitingEmployeeId: 3153841,
    },
    kgd: {
        serviceId: 10132996,
        companyId: 710369,
        waitingEmployeeId: undefined,
    },
    ekb: {
        serviceId: 12107253,
        companyId: 833611,
        waitingEmployeeId: undefined,
    },
    kzn: {
        serviceId: 14953538,
        companyId: 992338,
        waitingEmployeeId: undefined,
    },
    online: {
        serviceId: 11887750,
        companyId: 820982,
        waitingEmployeeId: undefined,
    },
};
