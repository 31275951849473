import { validFormatConfirmPhone } from "./validFormatConfirmPhone.js";
let codeResendIntervalId;
let codeServer = 1112;
let count = 0;
export const initConfirmPhone = () => {
    const modal = document.getElementById('profile-update-phone-confirm');
    const moreBtn = modal.querySelector(".js-more-code");
    phoneConfirmValid();

    moreBtn.addEventListener("click", function () {
        count++;
        phoneConfirmValid();

    })
}

const phoneConfirmValid = async () => {

    const modal = document.getElementById('profile-update-phone-confirm');
    const OK = document.getElementById('btn-profile-www');
    const inputsWrapper = modal.querySelector('.js-profile-confirm-code-wrapper');
    const inputs = modal.querySelectorAll('.js-profile-confirm-code');
    const moreBtn = modal.querySelector(".js-more-code");
    const loader = modal.querySelector(".js-phone-loader");
    const errorMessage = modal.querySelector(".js-confirm-error-message");
    const info = modal.querySelector(".js-profile-confirm-info");

    if (count === 3) {
        info.classList.remove("profile-hidden")
        startInterval(moreBtn, 250);
        inputs.forEach((input) => {
            input.disabled = true;
        });
        count = 0;

    } else {
        startInterval(moreBtn, 25);
    }

    inputs.forEach((input) => {
        input.value = '';
        inputsWrapper.classList.remove("modal-update-phone-confirm-content__inputs--error");
        errorMessage.style.display = "none";
    });

    try {
        const code = await validFormatConfirmPhone(inputsWrapper);
        moreBtn.style.display = 'none';
        loader.style.display = 'block';
        clearInterval(codeResendIntervalId);

        setTimeout(() => {
            moreBtn.style.display = 'block';
            loader.style.display = 'none';
            if (code !== codeServer) {
                moreBtn.textContent = 'Отправить код повторно';
                moreBtn.disabled = false;
                moreBtn.classList.add('button--primary--light');
                errorMessage.style.display = "block"
                inputsWrapper.classList.add("modal-update-phone-confirm-content__inputs--error");
            } else {
                OK.click();
                btnMoreCode.classList.add('profile-modal__confirm-btn');
                clearInterval(codeResendIntervalId);
                count = 0;
            }
        }, 1000)

    } catch (error) {
        console.log(error)
    }
}

const startInterval = (btnMoreCode, time) => {
    if (codeResendIntervalId) {
        clearInterval(codeResendIntervalId);
    }
    codeResendIntervalId = setInterval(() => {
        btnMoreCode.disabled = true;
        btnMoreCode.classList.remove('button--primary--light');
        time--;
        let minutes = Math.floor(time / 60);
        let seconds = time % 60;

        btnMoreCode.textContent = `Отправить код повторно через ${minutes.toString().padStart(2, '0')}: ${seconds.toString().padStart(2, '0')}`;
        if (time < 0) {
            clearInterval(codeResendIntervalId);
            btnMoreCode.disabled = false;
            btnMoreCode.textContent = "Отправить код повторно";
            btnMoreCode.classList.add('button--primary--light');

        }
    }, 1000);
};

