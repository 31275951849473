export function anketa() {
    const el = document.querySelector('.ai-anketa__anketa');

    if (!el) return;

    const checks = el.querySelectorAll('.custom-checkbox__input');
    const ageInput = el.querySelector('.ai-anketa-age');

    const btn = document.querySelector('.ai-anketa-next');

    let checked = false;

    // тут упрощенноя логика. атрибут disabled не удаляется, тк если радио нажали, то убрать не смогут

    checks.forEach(item => {
        item.addEventListener('input', () => {
            checked = true;

            if ((parseInt(ageInput.value) >= 16) && checked) {
                btn.removeAttribute('disabled')
            }
        })
    })

    ageInput.addEventListener('input', () => {
        ageInput.value = ageInput.value.replace(/[^0-9]/g, "");

        if ((parseInt(ageInput.value) >= 16)) {
            ageInput.classList.remove('err');
        }
        else if ((parseInt(ageInput.value) >= 10)) {
            ageInput.classList.add('err');
        }


        if ((parseInt(ageInput.value) >= 16) && checked) {
            btn.removeAttribute('disabled');
        }
        else {
            btn.setAttribute('disabled', 'disabled');
        }
    })
}