const body = document.querySelector(".body");

export const BodyScroll = {
    lock() {
        const paddingOffset = window.innerWidth - body.offsetWidth;
        body.classList.add("body--lock");
        body.style.paddingRight = `${paddingOffset}px`;
    },

    unlock() {
        body.classList.remove("body--lock");
        body.style.paddingRight = null;
    },
};
