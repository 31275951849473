import { yclientsCodes } from "../../services/api/endpoints/yclients/codes.js";
import { generateCenterCardByCenterInfo } from "./center-card.js";
import { generatePopUpBySkinCenterInfo, openAppointmentPopup } from "./popup.js";
import { parseCenterInfoFromHTMLStr } from "./util.js";

const tabsWrapper = document.querySelector(".skin-diagnostic-center__map-tabs .cities-tabs");

const main = document.querySelector("main");

/**
 *
 * @param {import("../../services/api/endpoints/map/index.js").MapResponseFeature} feature
 */
function generateSkinCenterTabPanelByFeature(feature) {
    const cityTab = document.querySelector(`.cities-slider__item[data-city="${feature.city}"]`);

    if (!cityTab) return null;

    const cityTabValue = cityTab.dataset.value;

    const tabPanel = document.createElement("li");

    const {
        address,
        phone,
        workHours,
        serviceType,
        href,
    } = parseCenterInfoFromHTMLStr(feature.properties.balloonContentBody);

    const card = generateCenterCardByCenterInfo({
        address: feature.address,
        phone: feature.phone,
        image: feature.image,
        workHours: feature.worktime,
        serviceType,
        city: feature.city,
        href,
        metro: feature.metro,
    });

    tabPanel.classList.add("tabs__tab-panel-item", "center");

    tabPanel.dataset.value = cityTabValue;

    tabPanel.append(card);

    return tabPanel;
}

/**
 *
 * @param {import("../../services/api/endpoints/map/index.js").MapResponse} geoPoints
 */
export async function initTabsCards(geoPoints) {
    const tabPanelsList = tabsWrapper.querySelector(".tabs__tab-panel-list");

    geoPoints.points.features.forEach((feature, i) => {
        const tabPanelHtml = generateSkinCenterTabPanelByFeature(feature);

        if (tabPanelHtml && i === 0) {
            tabPanelHtml.classList.add("tabs__tab-panel-item--show");
        }

        if (tabPanelHtml) {
            tabPanelsList.append(tabPanelHtml);
        }
    });

    const popup = generatePopUpBySkinCenterInfo({
        address: "",
        phone: "+7-985-840-08-12",
        city: "",
        companyId: yclientsCodes.online.companyId,
        serviceId: yclientsCodes.online.serviceId,
        serviceType: "online",
        workHours: "",
    });

    main.append(popup);

    Object.keys(yclientsCodes).forEach((serviceType) => {
        const appointmentButtons = document.querySelectorAll(`[data-appointment="${serviceType}"]`);

        appointmentButtons.forEach((appointmentButton) => {
            appointmentButton.addEventListener("click", () => openAppointmentPopup(serviceType));
        });
    });
}
