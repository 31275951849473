export function getSkinTypeHTML(skinTypeName) {
    const htmlString = `
        <div class="container">
            <div class="section__wrapper">
                <div class="page-title @@class">
                    <div class="breadcrumbs">
                        <ul class="breadcrumbs__list">
                            <li class="breadcrumbs__item breadcrumbs__item--divider">
                                <a href="/">Главная</a>
                            </li>

                            <li class="breadcrumbs__item breadcrumbs__item--divider">
                                <a href="/skin">Диагностика кожи и сервисы</a>
                            </li>

                            <li class="breadcrumbs__item">
                                <span>Подберите косметику для вашего типа кожи</span>
                            </li>


                        </ul>
                    </div>

                    <div class="page-title__link-wrapper">
                        <a href="/skin" class="lnk lnk--M lnk--light lnk--light-">
                            <i class="icon-arrow-left"></i>
                            <span class="lnk-standalone"> Назад </span>
                        </a>


                    </div>


                    <h1 class="h1">Ваш результат</h1>

                </div>

                <div class="card toast">
                    <i class="icon icon-info"></i>
                    <div class="toast-message">
                        <p class="p">Сохраняйте результаты теста и&nbsp;персональную подборку средств в вашем личном
                            кабинете, а при регистрации вы также получите персональные цены на нашу продукцию</p>
                        <button class="button button--ghost" type="button">Войти или зарегистрироваться</button>
                    </div>
                </div>

                <div class="card card--bordered skintest__result">
                    <div class="skintest__result-image">
                        <img src="/local/templates/geltek2024/frontend/img/upload/skintest/girl.png" alt="Skincare girl">
                    </div>
                    <div class="skintest__result-content">
                        <p class="p">Ваш тип кожи</p>
                        <h3 class="h3">${skinTypeName}</h3>
                        <p class="p">
                            <button type="button" class="button button--primary--light" id="StartSkinTest">Пройти тест заново<i class="icon-arrow-right"></i></button>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    `;

    const element = document.createElement("section");

    element.classList.add("section", "skintest");

    element.innerHTML = htmlString;

    return element;
}
