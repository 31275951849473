export function aiModal() {
    const closeModals = function() {
        document.querySelectorAll('.modal-custom_active').forEach(element => {
            element.classList.remove('modal-custom_active');

            const id = element.getAttribute('id');

            if (id == 'ai-evaluate') {
                const fuiid = element.querySelector('[name="fuuid"]').value;
                const initiator = document.querySelector('[data-fuuid = "' + fuiid + '"]')

                const valueKeeper = element.querySelector('.stars__star-value');
                const value = valueKeeper.value;

                initiator.querySelectorAll('.stars__star').forEach(s => {
                    s.classList.remove('selected');

                    if (s.getAttribute('data-value') == value) {
                        s.classList.add('selected');
                    }
                });

                initiator.querySelector('.stars__star-value').value = value;
            }
        });

        
    }
    
    const openModal = function(modal, initiator = null) {
        closeModals();
        const modalElement = document.querySelector('#' + modal);
        modalElement.classList.add('modal-custom_active');

        if (modal == 'ai-evaluate') {
            modalElement.querySelector('[name="fuuid"]').value = initiator.getAttribute('data-fuuid');

            const valueKeeper = initiator.querySelector('.stars__star-value');
            const value = valueKeeper.value;

            modalElement.querySelectorAll('.stars__star').forEach(s => {
                s.classList.remove('selected');

                if (s.getAttribute('data-value') == value) {
                    s.classList.add('selected');
                }
            });

            modalElement.querySelector('.stars__star-value').value = value;
        }
        if (modal == 'clinic') {
            

            const services = modalElement.querySelector('#clinic-service');
            const openService = initiator?initiator.getAttribute('data-service-id'):null;

            services.openService = openService;
            // console.log('asdasd');
            
            
            if (services.querySelector('.choices__placeholder')) {
                services.querySelector('.choices__placeholder').textContent = 'Загрузка услуг...';
            }

            if (openService) {
                services.choices.setChoiceByValue(parseInt(openService));
            }
            else {
                if (services.querySelector('.choices__placeholder'))
                    services.querySelector('.choices__placeholder').textContent = 'Выберите услугу';
            }



            const calendarEl = modalElement.querySelector('.calendar');

            if (calendarEl) {

                function renderCalendar() {
                    return new Promise((resolve) => {
                        
                            
                        const checkRender = setInterval(() => {
                            try {
                                calendarEl.calendar.render();
                                if (calendarEl.querySelector('.fc')) {
                                    clearInterval(checkRender);
                                    resolve();
                                }
                            } catch (error) {
                                console.error('Не получается отрендерить календарь');
                            }
                            
                        }, 100); // Проверка каждые 100 мс
                    });
                }
                
                renderCalendar().then(() => {
                    console.log('Календарь успешно отрендерен!');
                });
            }


        }
        
    }
    
    window.openAiModal = openModal;
    window.closeAiModals = closeModals;

    document.addEventListener('click', function (e) {
        // Проверяем, что клик произошел по элементу с атрибутом data-open-modal
        const target = e.target.closest('[data-open-ai-modal]');
        if (target) {
                e.preventDefault();
                openModal(target.getAttribute('data-open-ai-modal'), target);
        }
    });

    document.addEventListener('click', function (e) {
        // Проверяем, что клик произошел по элементу с атрибутом data-open-modal
        const target = e.target.closest('[data-close-ai-modal]');
        if (target) {
            closeModals();
        }
    });
}