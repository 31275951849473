/**
 *
 * @param {
 * import("../../services/api/endpoints/map").MapResponseFeature.properties.balloonContentBody
 * } text
 * @returns {string} HTMLstring
 */
export function parseCenterInfoFromHTMLStr(text) {
    // Регулярное выражение для адреса (исключаем тег <b> и любые теги перед ним)
    const addressPattern = /Адрес:<\/b>\s*(.*?)<br>/;
    const addressMatch = text.match(addressPattern);
    const address = addressMatch ? addressMatch[1].trim() : "";

    // Регулярное выражение для режима работы
    const workHoursPattern = /Режим работы<\/b><br>\s*(.*?)<br>/;
    const workHoursMatch = text.match(workHoursPattern);
    const workHours = workHoursMatch ? workHoursMatch[1].trim() : "";

    // Регулярное выражение для телефона
    const phonePattern = /Тел\.\s*([+0-9-\s()]+)<br>/;
    const phoneMatch = text.match(phonePattern);
    const phone = phoneMatch ? phoneMatch[1].replace(/\s+/g, "").trim() : "";

    // Регулярное выражение для извлечения serviceType
    const serviceTypePattern = /href="\/skin-diagnostic-center\/(.*?)\//;
    const serviceTypeMatch = text.match(serviceTypePattern);
    const serviceType = serviceTypeMatch
        ? serviceTypeMatch[1] === "moskva-arbatskaya"
            ? "arbat"
            : serviceTypeMatch[1]
        : "";

    // Регулярное выражение для извлечения href
    const hrefPattern = /href="(\/skin-diagnostic-center\/.*?\/)"/;
    const hrefMatch = text.match(hrefPattern);
    const href = hrefMatch ? hrefMatch[1] : "";

    console.log(href);

    return {
        address, phone, workHours, serviceType, href,
    };
}
