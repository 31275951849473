import Swiper from "swiper";
import { Navigation } from "swiper/modules";

import { BodyScroll } from "../../utils/index.js";
import { closeSearchMenu, openSearchMenu } from "../search-menu/index.js";

Swiper.use([Navigation]);

const header = document.querySelector(".header-full");

const catalogMenu = document.querySelector(".catalog-menu");

const catalogMenuMobile = document.querySelector(".catalog-menu--mobile");

const catalogMenuContent = document.querySelector(".catalog-menu__content");

const catalogMenuTitle = catalogMenu?.querySelector(".catalog-menu__title");

const catalogMenuBackdrop = catalogMenu?.querySelector(".catalog-menu__backdrop");

const catalogCloseButton = catalogMenu?.querySelector(".catalog-menu__close");

const catalogButton = document.querySelector(".header-full__bottom-center-catalog");

const catalogButtonIcon = catalogButton?.querySelector("i");

const bestsellers = catalogMenuContent?.querySelector(".catalog-menu__bestsellers");

const searchMenu = document.querySelector(".search-menu");

const searchMenuContent = document.querySelector(".search-menu__content");

const searchInputs = document.querySelectorAll(".catalog-menu__search");

export function openCatalogMenu() {
    if (window.innerWidth > 992) {
        catalogButtonIcon.classList.add("icon-close");
        catalogMenu.classList.add("catalog-menu--open");
        header.classList.add("header-full--white");

        if (searchMenu && searchMenu.classList.contains("search-menu--open")) {
            closeSearchMenu();
        }
    } else {
        catalogMenuMobile.classList.add("catalog-menu--open");
        header.style.zIndex = 600;
    }
    BodyScroll.lock();
}

export function closeCatalogMenu() {
    if (!catalogButton) return;

    BodyScroll.unlock();
    catalogButtonIcon.classList.remove("icon-close");
    catalogMenu.classList.remove("catalog-menu--open");
    catalogMenuMobile.classList.remove("catalog-menu--open");
    header.classList.remove("header-full--white");
    header.style.removeProperty("z-index");
}

function catalogButtonClick() {
    if (catalogMenu.classList.contains("catalog-menu--open")) {
        catalogMenuContent.style.maxHeight = null;
        closeCatalogMenu();
    } else openCatalogMenu();
}

/**
 *
 * @param {Event} event
 */
function catalogContentSizeObserver() {
    const maxNodesInPage = 13;
    const maxCatalogMenuContentHeight = 800;

    // store opened nested lists on the same node level
    let openedLists = [];

    const removeOpenedListByIndex = (index) => {
        openedLists = openedLists.slice(0, index);
    };

    // remove all lists from other node level
    function removeAllNodesOnNodeLevel(nodeElement) {
        if (!nodeElement.classList.contains("nested-list__node")) return;

        const parentList = nodeElement.parentElement.parentElement;

        if (!parentList || !parentList.classList.contains("nested-list")) return;

        const nestedListWrapper = parentList.parentElement;

        let openedNestedLists = [];

        if (nestedListWrapper.classList.contains("nested-list__wrapper")) {
            openedNestedLists = nestedListWrapper.querySelectorAll(".nested-list");
        } else {
            openedNestedLists = parentList.querySelectorAll(".nested-list");
        }


        if (openedNestedLists.length) {
            openedNestedLists.forEach((nl) => {
                if (nl.parentElement === nodeElement) return;

                if (openedLists.includes(nl)) {
                    const index = openedLists.indexOf(nl);
                    removeOpenedListByIndex(index);
                }
            });
        }
    }

    // returns the max count of nodes in one list from all opened nested lists
    const getMaxNodesCounts = (nestedLists) => {
        if (!nestedLists.length) return 0;

        return Math.max(
            ...nestedLists.map(
                (nl) => nl.querySelectorAll(":scope > .nested-list__items-wrapper > .nested-list__node").length,
            ),
        );
    };

    // changes catalog maxHeigth by provided nodes count
    // max available height is 800px with 13 nodes
    function changeCatalogContentHeight(nodesCount) {
        switch (true) {
            case nodesCount === 0:
                catalogMenuContent.style.maxHeight = "605px";
                break;

            case nodesCount > maxNodesInPage:
                catalogMenuContent.style.maxHeight = `${maxCatalogMenuContentHeight}px`;
                break;

            default:
                catalogMenuContent.style.maxHeight = `${(nodesCount * maxCatalogMenuContentHeight) / maxNodesInPage}px`;
                break;
        }
    }

    return (node) => {
        const isMobile = window.innerWidth < 992;

        if (isMobile) return;

        const nestedList = node.querySelector(".nested-list");

        if (!nestedList) return;

        removeAllNodesOnNodeLevel(node);

        if (openedLists.includes(nestedList)) {
            const nestedListIndex = openedLists.indexOf(nestedList);
            removeOpenedListByIndex(nestedListIndex);
        } else {
            openedLists.push(nestedList);
        }

        const maxNodesCount = getMaxNodesCounts(openedLists);

        changeCatalogContentHeight(maxNodesCount);
    };
}

function initCatalogCategories() {
    const catalogCategories = catalogMenu?.querySelector(".catalog-menu__card-categories-wrapper");

    const backButton = catalogCategories?.querySelector(".catalog-menu__card-categories-back");

    const catalogContentSizeHandler = catalogContentSizeObserver();

    function handleCategoryChange(event) {
        if (backButton.classList.contains("nested-list__back--hidden")) {
            backButton.replaceWith(catalogMenuTitle);
            catalogMenuContent.style.overflowY = "auto";
            bestsellers.style.display = null;
        } else {
            catalogMenuTitle.replaceWith(backButton);
            catalogMenuContent.style.overflowY = "hidden";
            bestsellers.style.display = "none";
        }

        catalogMenuContent.scrollTo({ top: 0 });

        catalogContentSizeHandler(event.currentTarget);
    }

    backButton?.addEventListener("click", handleCategoryChange);
    backButton?.addEventListener("dblclick", handleCategoryChange);

    const catalogCategoriesItems = catalogCategories?.querySelectorAll(".nested-list__node");

    catalogCategoriesItems?.forEach((category) => {
        category?.addEventListener("click", handleCategoryChange);
    });
}

function initBestsellersSlider() {
    return new Swiper(".catalog-menu__bestsellers-cards-list", {
        slidesPerView: 0,
        spaceBetween: 10,
        slideClass: "catalog-menu__bestsellers-cards-item",
        navigation: {
            prevEl: ".catalog-menu__bestsellers-cards-list-arrows-left",
            nextEl: ".catalog-menu__bestsellers-cards-list-arrows-right",
        },

        breakpoints: {
            576: {
                spaceBetween: 16,
            },
            768: {
                spaceBetween: 16,
            },
            992: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            1370: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
        },
    });
}

export function initCatalogMenu() {
    if (!catalogMenu) return;

    catalogCloseButton?.addEventListener("click", closeCatalogMenu);

    catalogMenuBackdrop?.addEventListener("click", closeCatalogMenu);

    catalogButton?.addEventListener("click", catalogButtonClick);

    initCatalogCategories();

    initBestsellersSlider();

    function mediaChange(mediaQuery) {
        if (mediaQuery.matches && catalogMenu.classList.contains("catalog-menu--open")) {
            closeCatalogMenu();
        } else {
            closeCatalogMenu();
        }
    }

    const mediaQuery = window.matchMedia("(max-width: 992px)");
    mediaQuery.addEventListener("change", mediaChange);
    mediaChange(mediaQuery);

    searchInputs.forEach((input) => {
        input.addEventListener("click", () => {
            closeCatalogMenu();
            searchMenuContent.style.transition = "none";
            openSearchMenu();
            searchMenuContent.style.transition = null;
        });
    });
}
