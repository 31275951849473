/* eslint-disable indent */

import { htmlDecode } from "../../utils/html-decode/index.js";

/**
 * 
 * @param {import("../../services/api/endpoints/yclients").Review} review
 */
export function generateReview(review) {
    const reviewEl = document.createElement("div");

    reviewEl.classList.add("review");

    const avatarType = review.user_avatar ? 'image' : 'letter';

    const avatarContent = review.user_avatar
        ? `<img src="${review.user_avatar}" alt="${review.user_name}">`
        : `<p class="p">${review.user_name.charAt(0).toUpperCase()}</p>`;

    const stars = Array(review.rating).fill('<i class="icon icon-star-filled" aria-label="Filled star"></i>').join('');

    const emptyStars = Array(5 - review.rating).fill('<i class="icon icon-star-framed" aria-label="Empty star"></i>').join('');

    const showMore = () => {
        const buttonEl = reviewEl.querySelector(".button--ghost");

        if (reviewEl.classList.contains("review--full")) {
            reviewEl.classList.remove("review--full");
            buttonEl.style.bottom = null;

            buttonEl.innerText = "ещё";
        } else {
            reviewEl.classList.add("review--full");
            buttonEl.style.bottom = "-20px";
            buttonEl.innerText = "скрыть";
        }
    };

    reviewEl.innerHTML = `
        <div class="review">
            <div class="review__head">
                <div class="review__author">
                    <div class="review__author-avatar">
                        ${avatarContent}
                    </div>
                    <h6 class="h6">${review.user_name}</h6>
                    <div class="review__rating rating--stars">
                        ${stars}${emptyStars}
                    </div>
                </div>
                <p class="p review__date">${new Date(review.date).toLocaleDateString()}</p>
            </div>
            <div class="review__text">
                <p class="p">${review.text}</p>
                <button class="button button--ghost" type="button">ещё</button>
            </div>
        </div>
    `;

    // Функция проверки, скрыт ли текст
    const checkClamping = () => {
        const textEl = reviewEl.querySelector(".review__text p");
        const buttonEl = reviewEl.querySelector(".button--ghost");

        if (textEl && buttonEl) {
            // Определяем, обрезан ли текст
            const isClamped = textEl.scrollHeight > textEl.clientHeight;
            console.log(textEl, textEl.scrollHeight, textEl.clientHeight);
            if (!isClamped) {
                buttonEl.style.display = "none"; // Скрываем кнопку, если текст не обрезан
            } else {
                buttonEl.addEventListener("click", showMore);
            }
        }
    };

    // Настраиваем MutationObserver для проверки после добавления в DOM
    // Используем IntersectionObserver для проверки, когда элемент становится видимым
    const observer = new IntersectionObserver((entries, obs) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                checkClamping(); // Проверяем обрезку текста
                obs.disconnect(); // Отключаем наблюдатель, так как больше не нужно следить
            }
        });
    });

    observer.observe(reviewEl);

    return reviewEl;
}

export function generateReviewsSectionByFeature(feature) {
    const reviews = document.createElement("section");

    reviews.classList.add("container", "section--reviews");

    reviews.innerHTML = `
        <h1 class="h1">Отзывы о филиале</h1>
        <div class="tabs">
            <div class="tabs__tab-list-wrapper">
                <ul class="tabs__tab-list">
                    ${Object.entries(feature.reviews).map(([key, value], i) => (
        value ? `<li data-value="${i}" class="tabs__tab-list-item${i === 0 ? "--selected" : ""}">
                                <button class="tabs__tab-button">
                                    ${key}
                                </button>
                            </li>` : ""
    )).join(" ")
        }
                </ul>
                <i class="tabs__indicator"></i>
            </div>
        
            <ul class="tabs__tab-panel-list">

                ${Object.entries(feature.reviews).map(([key, value], i) => (
            value ? `<li data-value="${i}" class="tabs__tab-panel-item">
                            ${htmlDecode(value.TEXT)}
                        </li>` : ""
        )).join(" ")
        }
            </ul>
        </div>
    `;

    return reviews;
}
