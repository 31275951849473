export function initPageTabs() {
    const tabsContainer = document.querySelector(".page-tabs");

    if (!tabsContainer) return;

    const fixer = tabsContainer.querySelector(".page-tabs__fixer");
    const header = document.querySelector(".header-full");


    function action() {
        let topPos = header.offsetHeight;

        let topLimit = tabsContainer.getBoundingClientRect().top - header.offsetHeight;

        if (topLimit < 0) {
            tabsContainer.style.height = fixer.offsetHeight + "px";

            fixer.classList.add("page-tabs__fixer_fixed");

            fixer.style.top = topPos + "px";
        } else {
            tabsContainer.style.height = "";

            fixer.classList.remove("page-tabs__fixer_fixed");

            fixer.style.top = "";
        }
    }

    action();

    window.addEventListener("scroll", () => {
        action();
    });
}
