import { initSliderClinic } from "./clinic-specialists-slider/index.js";
import { initSliderClinicDocuments } from "./clinic-documents-slider/index.js";
import { initClinicCalendar } from "./clinic-calendar/index.js";
import { initClinicOrder } from "./clinic-order/index.js";

export function initClinicPageModules() {
    initSliderClinic();
    initSliderClinicDocuments();
    initClinicCalendar();
    initClinicOrder();
}