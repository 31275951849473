/* eslint-disable no-use-before-define */
import Swiper from "swiper";

const PLAY_TIME_DELAY = 1000;

const PICTURE_SHOW_TIME = 5000;

const PICTURE_SHOW_PROGRESS_TICK = 100;

const storysetListEl = document.querySelector(".storyset-list");

function initSlider() {
    const slider = new Swiper(".storyset-list__slider", {
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 0,
        slideClass: "storyset__video-list",
        breakpoints: {
            576: {
                slidesPerView: "auto",
            },
        },
    });
    return slider;
}

/**
 *
 * @param {HTMLElement} storyset
 */

export function initStorysetList() {
    if (!storysetListEl) return;

    const nextcount = 0;

    const storysetListModal = document.querySelector(".modal.storyset-list__modal");

    const storysets = storysetListEl.querySelectorAll(".storyset");

    const slider = initSlider();

    let timersBeforePlayArr = [];

    let intervalsPictureShowingArr = [];

    function initStoryset(storyset, index) {
        const sliderWrapper = storysetListEl.querySelector(".storyset-list__slider-wrapper");
        const storiesListEl = storyset.querySelector(".storyset__video-list");
        const stories = [...storiesListEl.querySelectorAll(".storyset__story")];

        let timerBeforePlay;

        let intervalPictureShowing;

        storyset.id = `storyset-${index}`;
        storiesListEl.id = `storyset-${index}`;

        sliderWrapper.appendChild(storiesListEl);

        function initNavigation() {
            const navigationListEl = storiesListEl.querySelector(".storyset__navigation-list");

            navigationListEl.innerHTML = "";

            stories.forEach(() => {
                const navItem = document.createElement("li");
                const navItemIndicator = document.createElement("i");

                navItem.classList.add("storyset__navigation-item");
                navItemIndicator.classList.add("storyset__navigation-indicator");

                navItem.append(navItemIndicator);

                navigationListEl.append(navItem);
            });
        }

        function StorySetIndicator(indicator) {
            this.indicator = indicator;

            /**
             *
             * @param {number} progress
             */
            this.setProgress = (progress) => {
                const indicatorProgres = this.indicator.querySelector(".storyset__navigation-indicator");
                if (progress >= 100) {
                    indicatorProgres.style.width = "100%";
                } else if (progress < 0) {
                    indicatorProgres.style.width = "0%";
                } else {
                    indicatorProgres.style.width = `${progress}%`;
                }
            };
        }

        initNavigation();

        const indicatorEls = [...storiesListEl.querySelectorAll(".storyset__navigation-item")];

        const indicators = indicatorEls.map((i) => new StorySetIndicator(i));

        /**
         *
         * @param {HTMLElement} storyEl
         */
        function updateDate(storyEl) {
            const dateString = storyEl.dataset.date;
            const dateEl = storiesListEl.querySelector(".storyset__navigation-date");

            dateEl.innerHTML = "";

            if (dateString) {
                try {
                    const localeDateString = new Date(dateString).toLocaleString("ru", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                    }).replace(" г.", "");

                    dateEl.innerText = localeDateString;
                } catch (error) {
                    console.error("дата в истории была указана в неверном формате");
                }
            }
        }

        /**
         *
         * @param {HTMLElement} storyEl
         */
        function playStory(storyEl) {
            const storyIndex = stories.findIndex((el) => el === storyEl);
            const video = storyEl.querySelector(".storyset__story-video");
            const picture = storyEl.querySelector(".storyset__story-picture");

            updateDate(storyEl);

            // indicators reset
            for (let i = 0; i < storyIndex; i++) {
                indicators[i].setProgress(100);
            }

            for (let i = storyIndex; i < indicators.length; i++) {
                indicators[i].setProgress(0);
            }

            // video story
            if (video) {
                if (!video.src) {
                    const src = video.dataset.src;
                    video.src = src;
                    video.load();
                }
                video.play();

                video.ontimeupdate = (event) => {
                    const currentTime = event.target.currentTime;
                    const duration = event.target.duration;
                    const percentage = Math.round((currentTime * 100) / duration);

                    if (percentage !== 0) {
                        indicators[storyIndex].setProgress(percentage);
                    }

                    if (percentage >= 100) {
                        indicators[storyIndex].setProgress(100);
                        nextStory();

                        video.ontimeupdate = null;
                    }
                };
            }

            // picture story
            if (picture) {
                timerBeforePlay = setTimeout(() => {
                    let time = 0;
                    intervalPictureShowing = setInterval(() => {
                        const percentage = Math.round((time * 100) / PICTURE_SHOW_TIME);

                        if (percentage !== 0) {
                            indicators[storyIndex].setProgress(percentage);
                        }

                        if (percentage >= 100) {
                            indicators[storyIndex].setProgress(100);
                            nextStory();
                        }

                        time += PICTURE_SHOW_PROGRESS_TICK;
                    }, PICTURE_SHOW_PROGRESS_TICK);

                    intervalsPictureShowingArr.push(intervalPictureShowing);
                }, PLAY_TIME_DELAY);

                timersBeforePlayArr.push(timerBeforePlay);
            }
        }

        function resetStory(storyEl) {
            // if (intervalPictureShowing) {
            //     clearInterval(intervalPictureShowing);
            // }
            // if (timerBeforePlay) {
            //     clearTimeout(timerBeforePlay);
            // }
            const selectedStoryVideo = storyEl.querySelector(".storyset__story-video");

            if (selectedStoryVideo && !selectedStoryVideo.paused) {
                selectedStoryVideo.pause();
                selectedStoryVideo.load();
            }
        }
        function resetAllStories() {
            const storiesListElTemp = document.querySelectorAll(".storyset__video-list");

            timersBeforePlayArr.forEach((t) => clearTimeout(t));

            timersBeforePlayArr = [];

            intervalsPictureShowingArr.forEach((t) => clearInterval(t));

            intervalsPictureShowingArr = [];

            storiesListElTemp.forEach((storiesList) => {
                const storiesTemp = [...storiesList.querySelectorAll(".storyset__story")];

                storiesTemp.forEach((storyEl) => {
                    resetStory(storyEl);
                });
            });
        }
        function resetCurrentStory() {
            const currentSlide = slider.slides[slider.activeIndex];

            if (!currentSlide) return;

            const selectedStory = currentSlide.querySelector(".storyset__story--selected");

            if (selectedStory) resetStory(selectedStory);
        }

        function prevStory() {
            resetAllStories();
            const selectedStory = storiesListEl.querySelector(".storyset__story--selected");
            // resetStory(selectedStory);
            const prevStoryEl = selectedStory.previousElementSibling;

            if (prevStoryEl && prevStoryEl.classList.contains("storyset__story")) {
                selectedStory.classList.remove("storyset__story--selected");
                prevStoryEl.classList.add("storyset__story--selected");
                playStory(prevStoryEl);
            } else {
                slider.slidePrev();
            }
        }

        function nextStory() {
            resetAllStories();
            const selectedStory = storiesListEl.querySelector(".storyset__story--selected");
            // resetStory(selectedStory);
            const nextStoryEl = storiesListEl.querySelector(".storyset__story--selected + .storyset__story");

            if (nextStoryEl && nextStoryEl.classList.contains("storyset__story")) {
                selectedStory.classList.remove("storyset__story--selected");
                nextStoryEl.classList.add("storyset__story--selected");
                playStory(nextStoryEl);
            } else if (slider.activeIndex !== slider.slides.length - 1) {
                slider.slideNext();
            } else {
                window.closeModals();
            }
        }

        function storiesListClick(e) {
            const isTargetActiveSlide = index === slider.activeIndex;

            if (!isTargetActiveSlide) {
                resetCurrentStory();
                slider.slideTo(index);
            } else {
                const storiesListRect = e.currentTarget.getBoundingClientRect();
                const storiesListMiddleX = (storiesListRect.x + (storiesListRect.width / 2));
                const mousePosX = e.clientX;
                const mousePosY = e.clientY;

                const isRightSideClick = mousePosX >= storiesListMiddleX;

                if (mousePosY > storiesListRect.top + 60) {
                    if (isRightSideClick) {
                        nextStory();
                    } else {
                        prevStory();
                    }
                }
            }
        }

        function initStoriesList() {
            if (stories[0] && indicatorEls[0]) {
                stories[0].classList.add("storyset__story--selected");
            }

            storiesListEl.addEventListener("click", storiesListClick);
        }

        function storysetCircleClick() {
            window.openModal("#storyset-list");
            setTimeout(() => {
                const selectedStory = storiesListEl.querySelector(".storyset__story--selected");
                slider.slideTo(index);
                if (selectedStory) {
                    playStory(selectedStory);
                }
            }, 200);
        }

        initStoriesList();

        storyset.addEventListener("click", storysetCircleClick);

        storysetListModal.addEventListener("closemodal", (event) => {
            event.preventDefault();
            event.stopPropagation();
            resetAllStories();
        });

        slider.on("slideChange", (targetSlider) => {
            const prevSlide = targetSlider.slides[targetSlider.activeIndex - 1];
            const nextSlide = targetSlider.slides[targetSlider.activeIndex + 1];

            if (prevSlide) {
                const prevStories = prevSlide.querySelectorAll(".storyset__story");

                prevStories.forEach(resetStory);
            }

            if (nextSlide) {
                const nextStories = nextSlide.querySelectorAll(".storyset__story");

                nextStories.forEach(resetStory);
            }

            if (targetSlider.slides[targetSlider.activeIndex] === storiesListEl) {
                const currentSlide = targetSlider.slides[targetSlider.activeIndex];
                const selectedSlideStory = currentSlide.querySelector(".storyset__story--selected");
                playStory(selectedSlideStory);
            }
        });

        slider.update();
    }

    storysets.forEach(initStoryset);
}
