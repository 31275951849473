import IMask from "imask";

export function initPhoneMask() {
    const phoneMaskInputs = document.querySelectorAll("input.mask-phone");

    const phoneMaskFreeInputs = document.querySelectorAll("input.mask-phone-free");

    phoneMaskInputs.forEach((input) => {
        IMask(input, {
            mask: "+{7} 000 000 00 00",
        });
    });

    phoneMaskFreeInputs.forEach((input) => {
        IMask(input, {
            mask: "{0} 000 000 00 00",
        });
    });
}
