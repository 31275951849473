export function promotionForm() {
    const form = document.getElementById("promotionForm");
    const fileInput = document.getElementById("promotionResumeFile");
    const fileButton = document.querySelector(".promotion-form__file-btn");
    const fileName = document.querySelector(".promotion-form__file-name span");
    const fileNameContainer = document.querySelector(
        ".promotion-form__file-name"
    );
    const removeFileButton = document.querySelector(
        ".promotion-form__file-name .icon-X"
    );
    const fileSizeError = document.querySelector(".promotion-form__file-error");
    const maxFileSize = 9 * 1024 * 1024; // 9 МБ в байтах

    function showFileName(name) {
        fileName.textContent = name;
        fileNameContainer.style.display = "flex";
        fileButton.style.display = "none";
    }

    function hideFileName() {
        fileNameContainer.style.display = "none";
        fileButton.style.display = "flex";
        fileName.textContent = "";
    }

    if (!fileInput) return;
    console.log(fileInput);

    fileInput.addEventListener("change", function (e) {
        const file = e.target.files[0];
        if (file) {
            if (file.size > maxFileSize) {
                fileSizeError.style.display = "block";
                fileInput.value = ""; // Очищаем input
                hideFileName();
            } else {
                fileSizeError.style.display = "none";
                showFileName(file.name);
            }
        } else {
            hideFileName();
            fileSizeError.style.display = "none";
        }
    });

    if (!removeFileButton) return;
    removeFileButton.addEventListener("click", function () {
        fileInput.value = ""; // Очищаем input
        hideFileName();
        fileSizeError.style.display = "none";
    });

    if (!form) return;
    form.addEventListener("submit", function (e) {
        e.preventDefault();
        // Здесь можно добавить логику отправки формы
        console.log("Форма отправлена");
    });
}

