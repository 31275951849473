import Swiper from "swiper";
/* eslint-disable indent */
/**
 * @typedef {{
 *      id:number,
 *      title: string,
 *      url: string,
 *      defaultPhotoUrl: string,
 *      SKU: {
 *              variant: string,
 *              price: string,
 *              photoUrl: string,
 *          }[]
 * }} SearchProduct
 */

import { initToggleButtonGroup } from "../../../ui/toggle-button-group/index.js";
import { initSearchCard } from "../../search-card/index.js";

function initVariantsSlider(searchCardItem) {
    const searchCardVriants = searchCardItem.querySelector(".swiper");

    return new Swiper(searchCardVriants, {
        slidesPerView: "auto",
        slideClass: "toggle-button-group__button",
        breakpoints: {
            992: {
                allowTouchMove: true,
            },
        },
    });
}

/**
 *
 * @param {SearchProduct} product
 */
function handleChangeVariant(product, searchCardItem) {
    return (event) => {
        const searchCard = searchCardItem.querySelector(".search-card");

        const buttonWrapperEl = searchCard.querySelector(".search-card__button-wrapper");

        const newVariantText = event.detail.targetButton.innerText;

        const newVarinat = product.SKU.find((variant) => variant.variant === newVariantText);

        const imageEl = searchCardItem.querySelector(".search-card__image");

        imageEl.src = newVarinat.photoUrl;

        const priceEl = searchCardItem.querySelector(".search-card__price");

        if (typeof newVarinat.price === "string") {
            if (newVarinat.price === "нет в наличии") {
                searchCard.classList.add("search-card--not-available");

                buttonWrapperEl.innerHTML = `
                <button class="btn--S btn-secondary-light search-card__subscribe">
                    <span>Подписаться</span>
                </button>
                `;
            } else {
                searchCard.classList.remove("search-card--not-available");
                buttonWrapperEl.innerHTML = `
                <a href="${product.url}" class="btn--S btn-primary-light search-card__more">
                    <span>Подробнее</span>
                </a>
                `;
            }

            priceEl.innerText = newVarinat.price;
        } else {
            priceEl.innerText = `${newVarinat.price} ₽`;
        }

        initSearchCard(searchCard);
    };
}

/**
 *
 * @param {SearchProduct} product
 */
export function generateSearchCardHTML(product) {
    const searchCardListItem = document.createElement("li");

    searchCardListItem.classList.add("search-card-list__item");

    if (product.SKU.length === 0) {
        searchCardListItem.innerHTML = `
        <div id=${product.id} class="search-card">
                <div class="search-card__top">
                    <a href="${product.url}" class="search-card__link">
                        <div class="search-card__image-wrapper">
                            <img src="${product.defaultPhotoUrl}" alt="${product.title}" class="search-card__image">
                        </div>
                    </a>
                    <div class="search-card__info">
                        <a href="${product.url}" class="search-card__link">
                            <h5 class="search-card__title">${product.title}</h5>
                        </a>
                        <div class="swiper">
                            <div class="swiper-wrapper toggle-button-group search-card__variants">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="search-card__bottom">
                    <div class="search-card__price-wrapper">
                        <div class="search-card__price"></div>
                    </div>
                    <div class="search-card__button-wrapper">
                        
                        <a href="${product.url}" class="btn--S btn-primary-light search-card__more">
                            <span>Подробнее</span>
                        </a>
                        
                    </div>
                </div>
            </div>
        `;
    } else {
        const firstVariant = product.SKU[0];

        searchCardListItem.innerHTML = `
            <div id=${product.id} class="search-card ${firstVariant.price === "нет в наличии" ? "search-card--not-available" : ""}">
                <div class="search-card__top">
                    <a href="${product.url}" class="search-card__link">
                        <div class="search-card__image-wrapper">
                            <img src="${firstVariant.photoUrl}" alt="${product.title}" class="search-card__image">
                        </div>
                    </a>
                    <div class="search-card__info">
                        <a href="${product.url}" class="search-card__link">
                            <h5 class="search-card__title">${product.title}</h5>
                        </a>
                        <div class="swiper">
                            <div class="swiper-wrapper toggle-button-group search-card__variants">
                                ${product.SKU.map((variant, index) => (
                                                variant.variant === null || variant === "null" ? "" : `
                                                <button class="toggle-button-group__button ${index === 0 ? "toggle-button-group__button--selected" : ""} search-card__variant">
                                                    <span>${variant.variant}</span>
                                                </button>
                                                `
                                        )).join(" ")
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div class="search-card__bottom">
                    <div class="search-card__price-wrapper">
                        <div class="search-card__price">${firstVariant.price} ${typeof firstVariant.price === "string" ? "" : "₽"}</div>
                    </div>
                    <div class="search-card__button-wrapper">
                        ${firstVariant.price === "нет в наличии" ? `
                                <button class="btn--S btn-secondary-light search-card__subscribe">
                                    <span>Подписаться</span>
                                </button>
                            ` : ""
            }
                        
                        ${firstVariant.price !== "нет в наличии" ? `
                            <a href="${product.url}" class="btn--S btn-primary-light search-card__more">
                                <span>Подробнее</span>
                            </a>
                            ` : ""
            }
                    </div>
                </div>
            </div>
        `;
    }

    const toggleButtonGroup = searchCardListItem.querySelector(".toggle-button-group");

    initToggleButtonGroup(toggleButtonGroup);

    toggleButtonGroup.addEventListener("toggle-button-group-change", handleChangeVariant(product, searchCardListItem));

    initSearchCard(searchCardListItem);

    initVariantsSlider(searchCardListItem);

    return searchCardListItem;
}
