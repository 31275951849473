// test names enum
export const PasswordCriteries = {
    minLength: "minLength",
    lowerAndUppercaseLetter: "lowerAndUppercaseLetter",
    oneNumber: "oneNumber",
};

const passwordTests = {
    // any 6 characters
    [PasswordCriteries.minLength]: /^.{6,}/,
    // at least one lowercase character
    [PasswordCriteries.lowerAndUppercaseLetter]: /(?=.*[a-z])(?=.*[A-Z])/,
    // at least one number character
    [PasswordCriteries.oneNumber]: /(?=.*[0-9])/,
};

/**
 *
 * @param {string} password
 * @returns {{
 * isPasswordValid: boolean,
 * tests: Record<keyof typeof PasswordCriteries, boolean>
 * }}
 */
export function validatePassword(password) {
    const tests = {};

    Object.entries(passwordTests).forEach(([testName, test]) => {
        tests[testName] = password.match(test) !== null;
    });

    return {
        isPasswordValid: Object.values(tests).reduce((prev, curr) => prev && curr),
        tests,
    };
}
