import { initAddPhoneModal } from "./auth/add-phone/index.js";
import { initFeedbackModals } from "./auth/feedback/index.js";
import { initNewPassModal } from "./auth/new-pass/index.js";
import { initRecoveryPass } from "./auth/recovery-pass/index.js";
import { initSignInModal } from "./auth/sign-in/index.js";
import { initSignUp } from "./auth/sign-up/index.js";
import { initProductSubscribeModal } from "./subscribe-product/index.js";

export function initCommonModals() {
    initProductSubscribeModal();
    initSignInModal();
    initRecoveryPass();
    initNewPassModal();
    initFeedbackModals();
    initAddPhoneModal();
    initSignUp();
}
