import Swiper from "swiper";
import { Navigation } from "swiper/modules";

export function initGeltekJournalSliders() {
    const commonOptions = {
        breakpoints: {
            576: {
                spaceBetween: 16,
            },
            768: {
                spaceBetween: 16,
            },
            992: {
                spaceBetween: 24,
            },
            1250: {
                spaceBetween: 24,
            },
        },
    };

    const sliders = [
        {
            list: "geltek-journal__news-list",
            item: "geltek-journal__news-list-item",
            options: {
                ...commonOptions,
                breakpoints: {
                    ...commonOptions.breakpoints,
                    992: {
                        ...commonOptions.breakpoints[992],
                        allowTouchMove: false,
                    },
                    1250: {
                        allowTouchMove: false,
                    },
                },
            },
        },
    ];

    sliders.forEach((slider) => new Swiper(`.${slider.list}`, {
        modules: [Navigation],
        slidesPerView: "auto",
        spaceBetween: 12,
        slideClass: slider.item,

        navigation: {
            prevEl: `.${slider.list}-arrows-left`,
            nextEl: `.${slider.list}-arrows-right`,
        },

        ...slider.options,
    }));
}
