import Cookies from "js-cookie";
import { createCodeVerification } from "../../../code-verificaton/index.js";

window.openAddPhone = () => window.openModal("#add-phone");

export function initAddPhoneModal() {
    const addPhoneModal = document.querySelector(".modal.add-phone");

    if (!addPhoneModal) return;

    const addPhoneContent = addPhoneModal.querySelector(".modal-banner__content");

    const getCodeForm = addPhoneModal.querySelector(".add-phone__form");

    const phoneInput = addPhoneModal.querySelector("input[name='phone']");

    const phoneField = phoneInput.parentElement.parentElement;

    let codeVerification = null;

    function correctPhone() {
        delete phoneField.dataset.error;
    }

    function wrongPhone() {
        phoneField.dataset.error = "Телефон указан неверно";
    }

    function resetAddPhoneModal() {
        addPhoneModal.classList.remove("add-phone--code-verification");

        if (codeVerification) codeVerification.remove();
    }

    function codeVerified() {
        if (codeVerification) codeVerification.remove();

        resetAddPhoneModal();

        Cookies.set("phone", phoneInput.value);

        window.closeModals();
    }

    function codeVerificationStart() {
        addPhoneModal.classList.add("add-phone--code-verification");

        codeVerification = createCodeVerification({
            code: "0000",
            onCodeVerified: codeVerified,
            onReset: resetAddPhoneModal,
            title: "Подтверждение номера телефона",
            successText: "Номер телефона успешно подтвержден",
            number: phoneInput.value,
        });

        addPhoneContent.append(codeVerification);
    }

    function onSubmitNumber(event) {
        event.preventDefault();

        const phone = phoneInput.value.replace(/\s/g, "");

        const isPhoneValid = phone.length === 12;

        if (!isPhoneValid) wrongPhone();
        else {
            correctPhone();
            codeVerificationStart();
        }
    }

    getCodeForm.addEventListener("submit", onSubmitNumber);

    if (Cookies.get("phone") || window.location.pathname !== "/main-with-popup.html") return;

    window.openAddPhone();
}
