import { defaultOnInputChange, setDefaultInputListeners } from "../../ui/input/index.js";

export function CommonCardCounter(cardEl) {
    this.counter = cardEl.querySelector(".counter");

    if (!this.counter) throw new Error("Counter element not found");

    const clonedCounter = this.counter.cloneNode(true);
    this.counter.replaceWith(clonedCounter);
    this.counter = clonedCounter;

    // Инициализация элементов
    this.initializeElements = function () {
        this.minusButton = this.counter.querySelector(".counter__btn--minus");
        this.plusButton = this.counter.querySelector(".counter__btn--plus");
        this.input = this.counter.querySelector(".counter__input");

        if (!this.minusButton || !this.plusButton || !this.input) {
            throw new Error("Counter template elements are missing");
        }
    };

    // Привязка событий
    this.attachEventListeners = function () {
        // this.minusButton.addEventListener("click", () => {
        //     this.minValue = !isNaN(parseInt(this.input.min, 10))
        //         ? parseInt(this.input.min, 10)
        //         : -1;

        //     this.step = !isNaN(parseInt(this.input.step, 10))
        //         ? parseInt(this.input.step, 10)
        //         : 1;

        //     this.maxValue = !isNaN(parseInt(this.input.max, 10))
        //         ? parseInt(this.input.max, 10)
        //         : Infinity;

        //     const newValue = Math.max(parseInt(this.input.value, 10) + (-this.step), this.minValue);
        //     this.changeValue(newValue);
        //     if (newValue <= this.minValue) {
        //         this.close();
        //     }
        // });
        // this.plusButton.addEventListener("click", () => {
        //     this.minValue = !isNaN(parseInt(this.input.min, 10))
        //         ? parseInt(this.input.min, 10)
        //         : -1;

        //     this.step = !isNaN(parseInt(this.input.step, 10))
        //         ? parseInt(this.input.step, 10)
        //         : 1;

        //     this.maxValue = !isNaN(parseInt(this.input.max, 10))
        //         ? parseInt(this.input.max, 10)
        //         : Infinity;

        //     const newValue = Math.min(parseInt(this.input.value, 10) + this.step, this.maxValue);
        //     this.changeValue(newValue);
        // });
        this.input.addEventListener("input", this.handleInputChange.bind(this));

        // this.input.addEventListener("input", defaultOnInputChange);
        this.input.addEventListener("change", defaultOnInputChange);
    };

    // Метод для изменения значения в зависимости от шага
    this.changeValue = function (newValue) {
        if (newValue <= this.minValue) {
            this.updateCounter(this.minValue);
        } else if (newValue >= this.maxValue) {
            this.updateCounter(this.maxValue);
        } else {
            this.updateCounter(newValue);
        }
    };

    // Метод для обновления значения счетчика
    this.updateCounter = function (value) {
        this.count = value;
        this.input.value = value;
        this.setButtonsState();
        this.dispatchChangeEvent();
    };

    // Метод для обработки изменений вручную введенного значения
    this.handleInputChange = function (event) {
        event.preventDefault();
        this.input.value = this.count;
    };

    // Метод для сброса счетчика к начальному значению
    this.reset = function () {
        this.updateCounter(this.minValue);
        this.setButtonsState();
    };

    // Метод для установки состояния кнопок
    this.setButtonsState = function () {
        this.minusButton.disabled = this.count < this.minValue;
        this.plusButton.disabled = this.count >= this.maxValue;
    };

    // Метод для открытия счетчика (отображения)
    this.open = function () {
        // this.minValue = !isNaN(parseInt(this.input.min, 10))
        //     ? parseInt(this.input.min, 10)
        //     : -1;

        // this.step = !isNaN(parseInt(this.input.step, 10))
        //     ? parseInt(this.input.step, 10)
        //     : 1;

        // this.maxValue = !isNaN(parseInt(this.input.max, 10))
        //     ? parseInt(this.input.max, 10)
        //     : Infinity;

        // this.counter.style.display = "flex";
    };

    // Метод для закрытия счетчика (скрытия)
    this.close = function () {
        // this.minValue = parseInt(this.input.min, 10) || 1;
        // this.step = parseInt(this.input.step, 10) || 1;
        // this.maxValue = parseInt(this.input.max, 10) || Infinity;
        // this.counter.style.display = "none";
    };

    // Метод для генерации пользовательского события при изменении значения
    this.dispatchChangeEvent = function () {
        const event = new CustomEvent("counterChange", {
            detail: { value: this.count },
        });
        this.counter.dispatchEvent(event);
    };

    // Инициализация после определения всех методов
    this.initializeElements();
    this.attachEventListeners();
    this.minValue = !isNaN(parseInt(this.input.min, 10))
        ? parseInt(this.input.min, 10)
        : -1;

    this.step = !isNaN(parseInt(this.input.step, 10))
        ? parseInt(this.input.step, 10)
        : 1;

    this.maxValue = !isNaN(parseInt(this.input.max, 10))
        ? parseInt(this.input.max, 10)
        : Infinity;

    this.value = !isNaN(parseInt(this.input.value, 10))
        ? parseInt(this.input.value, 10)
        : this.minValue;

    this.count = this.value;

    // this.reset();
}
