/**
 * на страницу с картой добавить тег
    <script src="https://api-maps.yandex.ru/1.1/index.xml" type="text/javascript"></script>
 *
 */

/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */
/* eslint-disable no-undef */

function GeltekPlaceMarkLayout() {
    const element = YMaps.jQuery(`
        <div class="map-nav__pin">
            <img class="map-nav__pin-img" src="/img/icons/map-pin.svg">
        </div>    
    `);

    this.onAddToParent = function (parentNode) {
        element.appendTo(parentNode);
    };
    this.onRemoveFromParent = function () {
        element.remove();
    };
    this.update = function () { };
    this.getOffset = function () { return new YMaps.Point(-5, -5); };
    this.getRootNodes = function () { return element; };
}

export function GeltekBalloonLayout() {
    this.element = YMaps.jQuery(`
        <div class="map-nav__balloon">
            <div class="content">
            </div>
            <div class="close"></div>
            <div class="tail"></div>
        </div>
    `);

    this.close = this.element;
    this.content = this.element.find(".content");

    // Отключает кнопку закрытия балуна
    this.disableClose = function () {
        this.close.unbind("click").css("display", "none");
    };

    // Включает кнопку закрытия балуна
    this.enableClose = function (callback) {
        this.close.bind("click", callback).css("display", "");
        return false;
    };

    // Добавляет макет на страницу
    this.onAddToParent = function (parentNode) {
        YMaps.jQuery(parentNode).append(this.element);
    };

    // Удаляет макет со страницы
    this.onRemoveFromParent = function () {
        this.element.remove();
    };

    // Устанавливает содержимое балуна
    this.setContent = function (content) {
        this.content[0].innerHTML = "";
        content.onAddToParent(this.content[0]);
    };

    // Обновляет балун
    this.update = function () {
        // this.element.css("margin-top", `-${this.content.height() + 45}px`);
    };
}

// Стиль кастомной метки
function GeltekMapPinStyle() {
    const sampleBalloonTemplate = new YMaps.LayoutTemplate(GeltekBalloonLayout);

    const pinStyle = new YMaps.Style();

    pinStyle.balloonStyle = { template: sampleBalloonTemplate };

    pinStyle.balloonContentStyle = new YMaps.BalloonContentStyle(
        new YMaps.Template(`
            <div class="map-nav__balloon-header">
            $[name]
            </div>
            <div class="map-nav__balloon-description">
            $[description]
            </div>
            <div class="map-nav__balloon-content">
            $[balloonContentBody]
            </div>
        `),
    );

    pinStyle.iconStyle = new YMaps.IconStyle(new YMaps.LayoutTemplate(GeltekPlaceMarkLayout));

    return pinStyle;
}

// Стиль кастомного баллуна
export function GeltekBalloonStyle() {
    const sampleBalloonTemplate = new YMaps.LayoutTemplate(GeltekBalloonLayout);

    const balloonStyle = new YMaps.Style();

    balloonStyle.balloonContentStyle = new YMaps.BalloonContentStyle(
        new YMaps.Template("<div style=\"color:#0A0\">$[description]</div>"),
    );

    balloonStyle.balloonStyle = { template: sampleBalloonTemplate };

    return balloonStyle;
}

// Кастомная панель навигации
export function Navigation() {
    this.onAddToMap = function (map, position) {
        this.container = YMaps.jQuery("<ul class='map-nav'></ul>");

        this.map = map;

        this.position = position || new YMaps.ControlPosition(
            YMaps.ControlPosition.TOP_RIGHT,
            new YMaps.Size(36, 124),
        );

        this.generateZoomInBtn();

        this.generateZoomOutBtn();

        this.generateGeolocationBtn();

        // Добавляет элемент управления на карту
        this.container.appendTo(this.map.getContainer());
    };

    // Создает кнопку увеличения масштаба
    this.generateZoomInBtn = function () {
        const _this = this;

        const button = YMaps.jQuery(`
            <button class="map-nav__btn map-nav__plus">
                <i class="icon icon-plus"></i>
            </button>    
        `);

        button.bind("click", () => {
            const currZoom = _this.map.getZoom();
            _this.map.setZoom(currZoom + 1);
        });

        button.appendTo(_this.container);
    };

    // Создает кнопку уменьшения масштаба
    this.generateZoomOutBtn = function () {
        const _this = this;

        const button = YMaps.jQuery(`
            <button class="map-nav__btn map-nav__minus">
                <i class="icon icon-minus"></i>
            </button>    
        `);

        button.bind("click", () => {
            const currZoom = _this.map.getZoom();
            _this.map.setZoom(currZoom - 1);
        });

        button.appendTo(_this.container);
    };

    // Создает кнопку перехода к текущей геолокации
    this.generateGeolocationBtn = function () {
        const _this = this;

        const button = YMaps.jQuery(`
            <button class="map-nav__btn map-nav__geolocation">
                <i class="icon icon-map-geolocation"></i>
            </button>    
        `);

        button.bind("click", () => {
            if (YMaps.location) {
                const center = new YMaps.GeoPoint(
                    YMaps.location.longitude,
                    YMaps.location.latitude,
                );

                _this.map.openBalloon(center, `Место вашего предположительного местоположения:<br/>${YMaps.location.country || ""
                }${YMaps.location.region ? `, ${YMaps.location.region}` : ""
                }${YMaps.location.city ? `, ${YMaps.location.city}` : ""}`);
            }
        });

        button.appendTo(_this.container);
    };

    this.onRemoveFromMap = function () {
        if (this.container.parent()) {
            this.container.remove();
            this.container = null;
        }
        this.map = null;
    };
}

/**
 * @typedef
 * {[longitude:number, latitude:number]} Coords
 */

/**
 *
 * @param {string} mapContainerSelector - селекотор контейнера для карты
 * @param {{
 *  center: Coords,
 *  zoom: number
 * }} options - начальные параметры: координаты([ширина, долгота]) и масштаб
 */
export function BaseYMap(mapContainerSelector, options) {
    this.map = null;

    YMaps.jQuery(() => {
        this.map = new YMaps.Map(YMaps.jQuery(mapContainerSelector)[0]);

        // Устанавливает начальные параметры отображения карты:
        // центр карты и коэффициент масштабирования
        if (options) this.map.setCenter(new YMaps.GeoPoint(...options.center), options.zoom);

        // Добавляет кастомную панель навигации
        this.map.addControl(new Navigation());

        this.setCenter = (center, zoom = 17) => {
            this.map.setCenter(new YMaps.GeoPoint(...center), zoom);
        };

        /**
         *
         * @param {Coords} coords - Координаты
         * @param {{name: string, description:string, content:string}} content - Координаты
         * @param {any} pinStyle - Стиль метки(опционально)
         */
        this.addMark = (coords, content = { name: "", description: "", content: "" }, pinStyle = new GeltekMapPinStyle()) => {
            if (!this.map) return null;

            const placemark = new YMaps.Placemark(new YMaps.GeoPoint(...coords), {
                style: pinStyle,
                balloonContentHeader: "The placemark balloon",
            });

            placemark.name = content.name;

            placemark.description = content.description;

            placemark.balloonContentBody = content.content;

            this.map.addOverlay(placemark);

            return placemark;
        };

        /**
         * @param {Coords} coords - Координаты
         * @param {string} description - Текст баллуна
         * @param {any} balloonStyle - Стиль баллуна(опционально)
         */
        this.addBallon = (coords, description, balloonStyle = new GeltekBalloonStyle()) => {
            const placemark = new YMaps.Placemark(
                new YMaps.GeoPoint(...coords),
                { style: balloonStyle },
            );
            placemark.description = description;

            this.map.addOverlay(placemark);

            placemark.openBalloon();

            return placemark;
        };
    });
}
