import Swiper from "swiper";

export function initSliderClinic() {
    const slider = document.querySelector(".clinic-specialists__slider");
    
    if (!slider) return;
    
    return new Swiper(slider, {
        slidesPerView: "auto",
        spaceBetween: 8,
        slideClass: "clinic-specialists__slide", 
        navigation: {
            prevEl: `.clinic-specialists__slider-container .slider-navigation-prev`,
            nextEl: `.clinic-specialists__slider-container .slider-navigation-next`,
        },
        breakpoints: {
            992: {
                allowTouchMove: true,
                spaceBetween: 24,
            },
        },
    });
}