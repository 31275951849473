/**
 *
 * @param {HTMLElement} field
 */
function initInputPassword(field) {
    const input = field.querySelector("input");

    const toggleButton = field.querySelector(".input__button");

    if (!toggleButton) return;

    const icon = toggleButton.querySelector(".icon");

    function toggleShowHidePassword() {
        if (input.type === "password") {
            input.type = "text";
            icon.classList.replace("icon-eye-show", "icon-eye-show-off");
        } else {
            input.type = "password";
            icon.classList.replace("icon-eye-show-off", "icon-eye-show");
        }
    }

    toggleButton.addEventListener("click", toggleShowHidePassword);
}

export function initInputPasswords() {
    const textFields = document.querySelectorAll(".input--password");

    textFields.forEach(initInputPassword);
}
