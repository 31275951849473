/* eslint-disable no-restricted-syntax */

// change all cart counter on change header cart counter
function initCartCounterObserver() {
    const headerCartCounter = document.querySelector(".header-full__cart.cart-counter");

    const cartCounters = document.querySelectorAll(".cart-counter:not(.header-full__cart)");

    cartCounters.forEach((counterEl) => {
        counterEl.dataset.badgeValue = headerCartCounter.dataset.badgeValue;
        counterEl.classList.add("badge");
    });

    const config = { attributes: true };

    const observer = new MutationObserver((mutationsList, _obs) => {
        for (const mutation of mutationsList) {
            if (mutation.type === "attributes" && mutation.target.dataset.badgeValue) {
                cartCounters.forEach((counterEl) => {
                    counterEl.dataset.badgeValue = mutation.target.dataset.badgeValue;
                });
            }
        }
    });

    observer.observe(headerCartCounter, config);
}

function changeHeaderOnScroll() {
    const header = document.querySelector("header");

    const headerHeight = header.clientHeight;

    if (window.scrollY > headerHeight) {
        header.classList.add("header-full--scroll");
    } else {
        header.classList.remove("header-full--scroll");
    }
}

window.addEventListener("load", changeHeaderOnScroll);

export function initHeader() {
    window.addEventListener("scroll", changeHeaderOnScroll);

    initCartCounterObserver();
}
