import Swiper from "swiper";

export function initAboutSlider() {
    return new Swiper(".about__precious-benefits", {
        slidesPerView: "auto",
        spaceBetween: 24,
        slideClass: "about__precious-benefits-list-item",
        breakpoints: {
            576: {
                allowTouchMove: true,
                spaceBetween: 16,
            },
            768: {
                allowTouchMove: true,
                spaceBetween: 16,
            },
            992: {
                allowTouchMove: false,
                spaceBetween: 0,
            },
            1250: {
                allowTouchMove: false,
                spaceBetween: 0,
            },
        },
    });
}
