/* eslint-disable quote-props */
export const cityCoordionates = {
    "Москва": [
        55.76,
        37.64,
    ],
    "Санкт-Петербург": [
        59.939099,
        30.315877,
    ],
    "Белогорск": [
        50.921292,
        128.473926,
    ],
    "Абакан": [
        53.721152,
        91.442396,
    ],
    "Анапа": [
        44.894269,
        37.316906,
    ],
    "Аша": [
        54.990628,
        57.278469,
    ],
    "Белгород": [
        50.595414,
        36.587277,
    ],
    "Бологое": [
        57.885636,
        34.04959,
    ],
    "Братск": [
        56.151682,
        101.633505,
    ],
    "Брянск": [
        53.2434,
        34.363991,
    ],
    "Валуйки": [
        50.211332,
        38.100111,
    ],
    "Владивосток": [
        43.115542,
        131.885494,
    ],
    "Волгоград": [
        48.707067,
        44.516975,
    ],
    "Ишим": [
        56.109175,
        69.457073,
    ],
    "Казань": [
        55.796127,
        49.106414,
    ],
    "Вологда": [
        59.220501,
        39.891523,
    ],
    "Воронеж": [
        51.660781,
        39.200296,
    ],
    "Екатеринбург": [
        56.838011,
        60.597474,
    ],
    "Йошкар-Ола": [
        56.6316,
        47.886178,
    ],
    "Калининград": [
        54.710162,
        20.510137,
    ],
    "Каменск-Уральский": [
        56.415236,
        61.917818,
    ],
    "Карачев": [
        53.121594,
        34.982805,
    ],
    "Киров": [
        58.603595,
        49.668023,
    ],
    "Орск": [
        51.2293,
        58.474675,
    ],
    "Кострома": [
        57.767918,
        40.926894,
    ],
    "Краснодар": [
        45.03547,
        38.975313,
    ],
    "Кстово": [
        56.150665,
        44.206805,
    ],
    "Кузнецк": [
        53.119335,
        46.601174,
    ],
    "Кушнаренково": [
        54.735152,
        55.958736,
    ],
    "Магнитогорск": [
        53.407163,
        58.980291,
    ],
    "Миллерово": [
        48.92173,
        40.394849,
    ],
    "Нижний Новгород": [
        56.326797,
        44.006516,
    ],
    "Новомосковск": [
        54.010914,
        38.291057,
    ],
    "Новосибирск": [
        55.030204,
        82.92043,
    ],
    "Озерск": [
        55.763189,
        60.707608,
    ],
    "Оренбург": [
        51.768205,
        55.097,
    ],
    "Пенза": [
        53.195042,
        45.018316,
    ],
    "Пермь": [
        58.010455,
        56.229443,
    ],
    "ПЕТРОЗАВОДСК": [
        61.785021,
        34.346878,
    ],
    "Петушки": [
        55.931104,
        39.459352,
    ],
    "Псков": [
        57.819274,
        28.33246,
    ],
    "Ростов-на-Дону": [
        47.222109,
        39.718813,
    ],
    "Рубцовск": [
        51.527623,
        81.217673,
    ],
    "Самара": [
        53.195878,
        50.100202,
    ],
    "Саратов": [
        51.533562,
        46.034266,
    ],
    "Саров": [
        54.922793,
        43.344853,
    ],
    "Севастополь": [
        44.61602,
        33.524471,
    ],
    "Североуральск": [
        60.152394,
        59.952161,
    ],
    "Смоленск": [
        54.782495,
        32.048054,
    ],
    "Сочи": [
        43.585472,
        39.723098,
    ],
    "Стерлитамак": [
        53.630403,
        55.930825,
    ],
    "Таганрог": [
        47.208735,
        38.936694,
    ],
    "Тюмень": [
        57.152985,
        65.541227,
    ],
    "Улан-Удэ": [
        51.834809,
        107.584547,
    ],
    "Ульяновск": [
        54.314192,
        48.403132,
    ],
    "Усинск": [
        65.994148,
        57.55701,
    ],
    "Уфа": [
        54.735152,
        55.958736,
    ],
    "Челябинск": [
        55.159902,
        61.402554,
    ],
    "Энгельс": [
        51.50105,
        46.124052,
    ],
    "Южно-Сахалинск": [
        46.957771,
        142.729587,
    ],
    "Южноуральск": [
        54.442455,
        61.268237,
    ],
    "Ялуторовск": [
        56.654694,
        66.312215,
    ],
};

export function getCityCenterCoordinates(cityName) {
    const lowerCityName = cityName.toLowerCase().split(",")[0].trim();

    const foundCity = Object.keys(cityCoordionates).find((city) => (
        lowerCityName.includes(city.toLowerCase())
    ));

    return foundCity ? cityCoordionates[foundCity] : null;
}
