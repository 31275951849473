// Функция для установки текущего выделенного элемента изображения
const selectImage = (targetItem, currentSelectedImage) => {
    if (currentSelectedImage) {
        currentSelectedImage.classList.remove('card-circle__image--select');
    }

    const cardImage = targetItem.querySelector('.card-circle__image');
    cardImage.classList.add('card-circle__image--select');
    return cardImage;
};

// Функция для обновления значения data-value у списка заголовков
const updateTitleListDataValue = (titleList, targetItem) => {
    const selectedValue = targetItem.dataset.value;
    titleList.dataset.value = selectedValue;
    return selectedValue;
};

// Функция для смены выделенного контента
const selectContent = (contentList, selectedValue, currentSelectedContent) => {
    if (currentSelectedContent) {
        currentSelectedContent.classList.remove('js-tab-article__content-list-item--show');
    }

    const targetContent = contentList.querySelector(`.js-tab-article__content-list-item[data-value="${selectedValue}"]`);
    if (targetContent) {
        targetContent.classList.add('js-tab-article__content-list-item--show');
    }
    return targetContent;
};

// Инициализация работы табов для статьи журнала
export const initJournalArticleTab = () => {
    const main = document.querySelector('.js-tab-article');
    if (!main) return;
    const titleList = main.querySelector('.js-tab-article__title-list');
    const contentList = main.querySelector('.js-tab-article__content-list');

    // Сохранение ссылки на текущий выделенный элемент
    let currentSelectedImage = null;
    let currentSelectedContent = null;

    // Обработка клика по списку заголовков
    titleList.addEventListener('click', (e) => {
        const targetItem = e.target.closest('.js-tab-article__title-list-item');
        if (!targetItem || targetItem === currentSelectedImage) return;

        // Смена выделения в списке заголовков
        currentSelectedImage = selectImage(targetItem, currentSelectedImage);

        // Обновление значения data-value у списка заголовков
        const selectedValue = updateTitleListDataValue(titleList, targetItem);

        // Смена выделенного контента
        currentSelectedContent = selectContent(contentList, selectedValue, currentSelectedContent);
    });

    // Установка всех статей по умолчанию при загрузке страницы
    currentSelectedContent = selectContent(contentList, "all", currentSelectedContent);
};


