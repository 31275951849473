import { TourGuideClient } from "@sjmc11/tourguidejs";

export function initMedicineOnboarding() {
    if (!window.location.pathname.includes("catalog/medicina")) return;

    const toggleListBtn = document.querySelector(".catalog-filters__toggle-list");

    const toggleGridBtn = document.querySelector(".catalog-filters__toggle-grid");

    if (!toggleListBtn || !toggleGridBtn) return;

    const startOnboardingBtn = document.querySelector(".onboarding-start-btn");

    if (!startOnboardingBtn) return;

    const tg = new TourGuideClient({
        dialogClass: "c-onboarding",
        backdropClass: "c-onboarding-backdrop",
        showStepProgress: false,
        autoScroll: true,
        showStepDots: false,
        hidePrev: true,
        nextLabel: "Далее",
        finishLabel: "Понятно",
        backdropColor: "rgba(94, 104, 158, 0.40)",
        targetPadding: 0,
    });

    function showStartOnboardingBtn() {
        startOnboardingBtn.classList.add("onboarding-start-btn--show");
    }

    function hideStartOnboardingBtn() {
        startOnboardingBtn.classList.remove("onboarding-start-btn--show");
    }

    if (toggleListBtn.classList.contains("toggle-button-group__button--selected")) {
        showStartOnboardingBtn();
    }

    function startOnboarding() {
        const waitGeltekBodyEl = document.querySelector("#wait_geltekbody");

        if (waitGeltekBodyEl) return;

        const catalogTable = document.querySelector(".catalog-table");

        if (!catalogTable) return;

        if (!toggleListBtn.classList.contains("toggle-button-group__button--selected")) return;

        const firstRow = document.querySelector(".catalog-table .catalog-table__body-row");

        if (!firstRow) return;

        const rowButtons = firstRow.querySelector(".catalog-table__cell-buttons-wrapper");

        if (!rowButtons) return;

        const steps = [
            {
                content: "Медицинская продукция продаётся на сайте упаковками, на каждом товаре указано сколько единиц товара находится в одной упаковке",
                target: firstRow,
            },
            {
                content: "Для добавления нужного товара в корзину, нажмите кнопку «В корзину»",
                target: rowButtons,
            },
            {
                content: "Чтобы добавить в корзину большое количество упаковок, нажмите на поле ввода количества упаковок",
                target: rowButtons,
            },
            {
                content: "Используя плюс и минус, укажите нужное количество. В корзину можно добавить товар только кратно размеру упаковки.",
                target: rowButtons,
            },
        ];

        tg.setOptions({ steps });

        const counter = document.createElement("div");
        counter.classList.add("counter", "product-card__counter");
        counter.innerHTML = `
                          <button class="counter__btn counter__btn--minus">
                              minus
                          </button>
                          <input max="10000" value="1" min="-1" step="1" value="0" type="text" class="counter__input">
                          <button class="counter__btn counter__btn--plus add-to-cart-counter">
                              plus
                          </button>
                    `;
        const counterInput = counter.querySelector("input");

        const counterBtns = counter.querySelectorAll(".counter__btn");

        const rowButtonsBtn = rowButtons.querySelector(":scope > button");
        counter.style.display = "flex";

        tg.onBeforeStepChange(() => {
            const nextStepIndex = tg.activeStep + 1;

            if (steps.length > nextStepIndex) {
                switch (nextStepIndex) {
                    case 1:
                        tg.setOptions({ targetPadding: 20 });
                        break;
                    case 2:
                        rowButtons.prepend(counter);
                        rowButtonsBtn.style.display = "none";
                        break;
                    case 3:
                        counterInput.value = "120";
                        counterBtns.forEach((btn) => {
                            btn.classList.add("counter__btn--focused");
                        });
                        break;
                    default:
                        break;
                }
            }
        });

        tg.onAfterStepChange(() => {
            const btn = document.querySelector(".tg-dialog-btn");

            if (btn.textContent.trim() === "Понятно") {
                btn.classList.add("no-after");
            }
        });

        tg.onBeforeExit(() => {
            counter.remove();
            rowButtonsBtn.style.display = "flex";
        });

        setTimeout(() => {
            tg.start();
        }, 100);
    }

    toggleListBtn.addEventListener("click", showStartOnboardingBtn);

    toggleGridBtn.addEventListener("click", hideStartOnboardingBtn);

    startOnboardingBtn.addEventListener("click", startOnboarding);
}
