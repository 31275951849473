export function initMiniatures() {
    const main = document.querySelector(".skin-diagnostic-center.skin-diagnostic-center--cities");
    if (!main) return;

    const miniatureButtons = main.querySelectorAll(".card-with-miniature .button-open-popup[data-id]")

    miniatureButtons.forEach((miniatureButton) => {
        const popup = main.querySelector(`.popup--miniatures#${miniatureButton.dataset.id}`);

        if (popup) {
            miniatureButton.addEventListener("click", () => {
                popup.classList.add("open");
            });

            const popupCloseButtons = popup.querySelectorAll(".popup-close, .popup__close");

            popupCloseButtons.forEach((closeButton) => {
                closeButton.addEventListener("click", () => {
                    popup.classList.remove("open");
                });
            });
        }
    });
}
