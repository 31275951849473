import { CommonCardCounter } from "../common-card-counter/index.js";

export function SearchCardCounter(searchCard) {
    CommonCardCounter.apply(this, arguments);

    const parentOpen = this.open;

    const parentClose = this.close;

    this.open = () => {
        parentOpen.call(this);
        searchCard.classList.add("search-card--add-to-cart");
    };

    this.close = () => {
        parentClose.call(this);
        searchCard.classList.remove("search-card--add-to-cart");
    };
}
