import search from "./search/index.js";
import test from "./test/index.js";
import map from "./map/index.js";
import yclients from "./yclients/index.js";

export const API = {
    search,
    test,
    map,
    yclients,
};
