import Swiper from "swiper";
import { initSkinDiagnosticCenterCity } from "./city/index.js";

import { getQueryVariable } from "../../utils/query/get-query-variable.js";
import { initSkinDiagnosticCenterCities } from "./cities/index.js";
import { yclientsCodes } from "../../services/api/endpoints/yclients/codes.js";

const header = document.querySelector("header");
const skinDiagnosticCenterEl = document.querySelector(".skin-diagnostic-center");
const main = document.querySelector("main");

/**
 *
 * @param {'list' | 'detail'} page
 */
function handleContentDisplay(page) {
    const listContentEl = skinDiagnosticCenterEl.querySelector(".skin-diagnostic-center__content-cities");
    const detailContentEl = skinDiagnosticCenterEl.querySelector(".skin-diagnostic-center__content-city");
    switch (page) {
        case "list":
            detailContentEl.remove();
            skinDiagnosticCenterEl.classList.add("skin-diagnostic-center--cities");
            break;
        case "detail":
            main.classList.add("body_header");
            header.classList.remove("header-full--secondary");
            listContentEl.remove();
            skinDiagnosticCenterEl.classList.add("skin-diagnostic-center--city");
            break;
        default: {
            break;
        }
    }
}

function initDoctorsSliders() {
    return new Swiper(".js-slider-doctors-list", {
        slidesPerView: "auto",
        navigation: {
            prevEl: "#DoctorsSlider .slider-navigation-prev",
            nextEl: "#DoctorsSlider .slider-navigation-next",
        },
        breakpoints: {
            992: {
                allowTouchMove: true,
            },
        },
    });
}

export async function initCenterPageModules() {
    if (!skinDiagnosticCenterEl) return;

    let isDetail = false;

    let serviceType = null;

    const pathArray = window.location.pathname.split("/");

    const firstPathElement = pathArray.shift() || pathArray.shift();

    const lastPathElement = firstPathElement === "skin-diagnostic-center" ? pathArray.pop() || pathArray.pop() : null;

    const queryAddress = getQueryVariable("address");

    if (Object.keys(yclientsCodes).includes(queryAddress)) {
        isDetail = true;
        serviceType = queryAddress === "moskva-arbatskaya" ? "arbat" : queryAddress;
    } else if (Object.keys(yclientsCodes).includes(lastPathElement)) {
        isDetail = true;
        serviceType = lastPathElement === "moskva-arbatskaya" ? "arbat" : lastPathElement;
    }

    if (isDetail && serviceType) {
        handleContentDisplay("detail");
        await initSkinDiagnosticCenterCity(serviceType);
    } else {
        handleContentDisplay("list");
        await initSkinDiagnosticCenterCities();
    }
    initDoctorsSliders();
}
