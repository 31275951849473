export function initSmoothScroll() {
    const els = document.querySelectorAll(".smooth-scroll");

    if (!els.length) return;

    els.forEach((el) => {
        const targetSelector = el.getAttribute("data-to") || el.getAttribute("href");

        el.addEventListener("click", (e) => {
            let hash = targetSelector;
            let target = null;

            if (!targetSelector.startsWith("#")) {
                hash = `#${targetSelector}`;
            }

            target = document.querySelector(hash);

            if (!target) {
                return;
            }

            e.preventDefault();

            const header = document.querySelector("header");
            let offset = target.offsetTop;

            if (header) {
                offset -= header.offsetHeight;
            }

            const pageTabs = document.querySelector(".page-tabs");

            if (pageTabs) {
                offset -= pageTabs.offsetHeight;
            }

            window.scrollTo({
                top: offset,
                left: 0,
                behavior: "smooth",
            });
        });
    });
}
