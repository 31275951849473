import { baseFetch } from "../../base/index.js";
import { yclientsCodes } from "./codes.js";
/**
 * @typedef {Object} ScheduleBranchEmployee
 * @property {number} id - The employee's unique ID.
 * @property {?string} api_id - The API ID of the employee, if applicable.
 * @property {string} name - The name of the employee.
 * @property {string} specialization - The employee's specialization.
 * @property {number} rating - The employee's rating.
 * @property {number} show_rating - Whether the rating is shown (1) or not (0).
 * @property {?number} user_id - The user ID associated with the employee.
 * @property {string} avatar - URL of the small-sized avatar.
 * @property {string} avatar_big - URL of the large-sized avatar.
 * @property {number} comments_count - The number of comments for the employee.
 * @property {number} votes_count - The number of votes for the employee.
 * @property {boolean} bookable - Whether the employee can be booked.
 * @property {ImageGroup} image_group - The group of images related to the employee.
 * @property {string} information - Additional information about the employee.
 * @property {number} position_id - The ID of the employee's position.
 * @property {string} schedule_till - The date until the employee's schedule is available.
 * @property {number} weight - The weight or priority of the employee.
 * @property {number} fired - Whether the employee is fired (1) or not (0).
 * @property {number} status - The employee's status.
 * @property {number} hidden - Whether the employee is hidden (1) or not (0).
 * @property {Array.<Object>} user - Associated user details.
 * @property {string} prepaid - Prepaid status (e.g., "forbidden").
 * @property {Position} position - The position details of the employee.
 * @property {string} description - A description of the employee.
 */

/**
 * @typedef {Object} ImageGroup
 * @property {number} id - The image group's unique ID.
 * @property {string} entity - The type of entity associated with the image group.
 * @property {string} entity_id - The entity's ID.
 * @property {Images} images - Various sizes of images.
 */

/**
 * @typedef {Object} Images
 * @property {ImageVersion} sm - Small-sized image version.
 * @property {ImageVersion} norm - Normal-sized image version.
 * @property {ImageVersion} origin - Original-sized image version.
 */

/**
 * @typedef {Object} ImageVersion
 * @property {number} id - The image version's unique ID.
 * @property {string} path - The URL of the image.
 * @property {string} width - The width of the image.
 * @property {string} height - The height of the image.
 * @property {string} type - The image type (e.g., png).
 * @property {number} image_group_id - The associated image group ID.
 * @property {string} version - The version type of the image.
 */

/**
 * @typedef {Object} Position
 * @property {number} id - The position's unique ID.
 * @property {string} title - The title of the position.
 */

/**
 * @typedef {Object} Info
 * @property {string} company - The company ID.
 * @property {string} service - The service ID.
 * @property {string} type - The type of service (e.g., "msk").
 */

/**
 * @typedef {Object} ScheduleBranchItemSchedule
 * @property {string} time - The start time of the seance.
 * @property {number} seance_length - The length of the seance in seconds.
 * @property {number} sum_length - The total length of the seance in seconds.
 * @property {string} datetime - The date and time of the seance in ISO format.
 */

/**
 * @typedef {Object} Review
 * @property {number} id - The review's unique ID.
 * @property {number} salon_id - The ID of the salon.
 * @property {number} type - The type of the review (e.g., 1 for positive).
 * @property {number} master_id - The ID of the master (employee).
 * @property {string} text - The content of the review.
 * @property {string} date - The date of the review.
 * @property {number} rating - The rating provided in the review.
 * @property {number} user_id - The ID of the user who left the review.
 * @property {string} user_name - The name of the user who left the review.
 * @property {string} user_avatar - The URL of the user's avatar.
 * @property {number} record_id - The record ID of the appointment.
 * @property {string} user_email - The email of the user who left the review.
 * @property {string} user_phone - The phone number of the user who left the review.
 */

/**
 * @typedef {Object} ScheduleBranch
 * @property {number} current_time - The current time as a Unix timestamp.
 * @property {Object.<string, string>} menu - The menu with date strings.
 * @property {Object.<number, ScheduleBranchItem>} items - The list of items.
 * @property {boolean} success - Whether the operation was successful.
 */

/**
 * @typedef {Object} ScheduleBranchNoItems
 * @property {number} current_time - The current time as a Unix timestamp.
 * @property {Object.<string, string>} menu - The menu with date strings.
 */

/**
 * @typedef {Object} ScheduleBranchItem
 * @property {ScheduleBranchEmployee} employee - The details of the employee.
 * @property {Info} info - Information about the company and service.
 * @property {Array.<ScheduleBranchItemSchedule>} schedule - The list of scheduled seances.
 * @property {Object.<number, Review>} reviews - The list of reviews.
 */

const headers = {
    accept: "application/json, text/javascript, */*; q=0.01",
    "accept-language": "ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7",
    "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
    priority: "u=1, i",
    "x-kl-kis-ajax-request": "Ajax_Request",
    "x-requested-with": "XMLHttpRequest",
};

const endpoints = {

    /**
     *
     * @param {string} serviceType
     * @param {string | null | undefined} date
     * @returns {Promise<ScheduleBranch | ScheduleBranchNoItems>}
     */
    getScheduleBranch: async (serviceType = "msk", date = null, employeeId = null) => baseFetch.post("https://geltek.ru/ajax/appYclients.php", {
        headers,
        body: `action=getScheduleBranch&companyId=${yclientsCodes[serviceType]?.companyId}&serviceId=${yclientsCodes[serviceType]?.serviceId}&serviceType=${serviceType}${date ? `&date=${date}` : ""}${employeeId ? `&employeeId=${employeeId}` : ""}`,
    }),

    getBookDates: async (serviceType = "msk", workerId = null) => baseFetch.post("https://geltek.ru/ajax/appYclients.php", {
        headers,
        body: `action=getBookDates&companyId=${yclientsCodes[serviceType]?.companyId}&serviceId=${yclientsCodes[serviceType]?.serviceId}&serviceType=${serviceType}${workerId ? `&workerId=${workerId}` : ""}`,
    }),

    getFreeWaitingListSlot: async (serviceType = "msk", date = null) => baseFetch.post("https://geltek.ru/ajax/appYclients.php", {
        headers,
        body: `action=getWaitingList&companyId=${yclientsCodes[serviceType]?.companyId}&serviceId=${yclientsCodes[serviceType]?.serviceId}&serviceType=${serviceType}&waitingEmployeeId=${yclientsCodes[serviceType]?.waitingEmployeeId}${date ? `&date=${date}` : ""}`,
    }),

    getEmployees: async (serviceType) => baseFetch.post("https://geltek.ru/ajax/appYclients.php", {
        headers,
        body: `action=getEmployees&companyId=${yclientsCodes[serviceType]?.companyId}&serviceId=${yclientsCodes[serviceType]?.serviceId}&serviceType=${serviceType}`,
    }),
    /**
     *
     * @param {FormData} formData
     * @returns
     */
    postScheduleBranch: async (formData) => baseFetch.post("https://geltek.ru/ajax/appYclients.php", {
        body: formData,
        method: "POST",
    }),
};

export default endpoints;
