export function initSmoothScroll() {
    const els = document.querySelectorAll(".smooth-scroll");

    if (!els.length) return;

    els.forEach(el => {
        el.addEventListener("click", e => {
            e.preventDefault();

            const target = document.querySelector(el.getAttribute("data-to"));
            const header = document.querySelector(".header-full");
            let offset = target.offsetTop - header.offsetHeight;

            const pageTabs = document.querySelector(".page-tabs");

            if (pageTabs) {
                offset -= pageTabs.offsetHeight;
            }

            window.scrollTo({
                top: offset,
                left: 0,
                behavior: "smooth",
            });
        });
    });
}
