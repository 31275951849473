const cardItems = document.querySelectorAll(".shopping-cart__item");

function ChangeAmount(n, input, buttonMinus) {
    let amount = parseInt(input.innerHTML, 10);

    switch (n) {
        case "plus":
            amount += 1;
            break;
        case "minus":
            amount -= 1;
            break;
        default:
            break;
    }

    input.innerHTML = amount;
    buttonMinus.disabled = amount === 1;
}
export function initCartPageModules() {
    cardItems?.forEach((cardItem) => {
        const buttonPlus = cardItem.querySelector(".more");
        const buttonMinus = cardItem.querySelector(".less");
        const input = cardItem.querySelector(".shopping-cart__item-amount-total");

        buttonPlus?.addEventListener("click", () => {
            ChangeAmount("plus", input, buttonMinus);
        });
        buttonMinus?.addEventListener("click", () => {
            ChangeAmount("minus", input, buttonMinus);
        });
    });
}
