import { initContactsMap } from "./map.js";

const askQuestionBtn = document.querySelector(".contacts__content-not-found .ask-question");

export function initContactsPageModules() {
    initContactsMap();

    if (askQuestionBtn) {
        askQuestionBtn.addEventListener("click", () => window.openModal("#feedback-modal-common"));
    }
}
