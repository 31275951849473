import Swiper from "swiper";

/**
 *
 * @param {HTMLElement} slider element with class "category-banner-slider__product-list"
 */
function initSlider(slider) {
    const id = slider.id;

    return new Swiper(`#${id} > .category-banner-slider__product-list`, {
        slidesPerView: "auto",
        spaceBetween: 12,
        slideClass: "category-banner-slider__product-list-item",
        navigation: {
            prevEl: `#${id} .category-banner-slider-arrows-left`,
            nextEl: `#${id} .category-banner-slider-arrows-right`,
        },
        breakpoints: {
            992: {
                spaceBetween: 24,
            },
        },
    });
}

export function initCategoryBannerSliders() {
    const wrapper = document.querySelectorAll(".category-banner-slider__product");

    if (!wrapper.length) return;

    wrapper.forEach((wrapper, index) => {
        wrapper.id = `category-banner-slider__wrapper-${index}`;

        initSlider(wrapper);
    });
}
