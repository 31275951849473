import Swiper from "swiper";

function buildSwiperForTabPanels(tabPanel) {
    const tabIdentifier = tabPanel.dataset.value;

    const productList = tabPanel.querySelector(".learning-block__events-list");

    productList.id = `learning-block__events-list-${tabIdentifier}`;

    // eslint-disable-next-line no-undef
    return new Swiper(`#${productList.id}`, {
        slidesPerView: "auto",
        spaceBetween: 13,
        slideClass: "learning-block__events-item",

        breakpoints: {
            576: {
                spaceBetween: 16,
            },
            768: {
                spaceBetween: 16,
            },
            992: {
                slidesPerView: 2,
                spaceBetween: 24,
            },
            1250: {
                slidesPerView: "auto",
                spaceBetween: 0,
            },
        },
    });
}

export function initLearningBlockSlider() {
    const tabPanels = document.querySelectorAll(".learning-block__events-tab-panel");
    tabPanels.forEach((tabPanel) => buildSwiperForTabPanels(tabPanel));
}
