import { getSkinTypeHTML } from "../../modules/dynamic-api-test/getSkinType.js";
import { initDynamicTestByAPI } from "../../modules/dynamic-api-test/index.js";
import { initProductCards } from "../../modules/product-card/index.js";
import { initProductListSlidersWithTabs } from "../../modules/product-list-slider-with-tabs/index.js";
import { API } from "../../services/api/endpoints/index.js";
import { initAccordions } from "../../ui/accordion/index.js";
import { initTabs } from "../../ui/tabs/index.js";
import { initToggleButtonGroups } from "../../ui/toggle-button-group/index.js";
import { initProductSliders } from "../shared/product-slider/index.js";
import { getPresentsHTML } from "../skintest/getPresents.js";
import { getCatalogHTML } from "./getCatalog.js";

const startButton = document.querySelector("#StartSkinDiagnosticTest");
const closeButton = document.querySelector(".popup--skin-diagnostic .popup__close");

const popup = document.querySelector(".popup--skin-diagnostic");

function OpenPopup() {
    popup.classList.add("open");
}
function ClosePopup() {
    popup.classList.remove("open");
}

/**
 * @typedef {{
*      name: string,
*      detail:string,
*      products: Product[],
* }} AdaptedTestResult
*/

/**
 *
 * @param {AdaptedTestResult} data
 */
async function handleFinish(data) {
    const skinType = data.name;

    const main = document.querySelector("main");

    const aboutSection = document.querySelector(".section-about").closest(".section");

    aboutSection.replaceWith(getSkinTypeHTML(skinType));

    main.append(getPresentsHTML());

    main.append(getCatalogHTML(data));

    initProductCards();
    initAccordions();
    initTabs();
    initToggleButtonGroups();
    initProductListSlidersWithTabs();
    initProductSliders();

    ClosePopup();
}

export async function initSkinDiagnosticPageModules() {
    if (!startButton || !closeButton) return;

    await initDynamicTestByAPI("test-layout--skin-diagnostic", API.test.skinTest, handleFinish);

    startButton.addEventListener("click", OpenPopup);
    closeButton.addEventListener("click", ClosePopup);
}
