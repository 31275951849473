export function initCounter() {
    const MIN_VALUE = -1;

    const operations = {
        add: (a, b) => a + b,
        subtract: (a, b) => a - b,
    };

    const counters = document.querySelectorAll(".counter");

    if (!counters.length) return;

    counters.forEach((counter) => {
        const input = counter.querySelector(".counter__input");
        const minusButton = counter.querySelector(".counter__btn--minus");

        const plusButton = counter.querySelector(".counter__btn--plus");

        const maxValue = !isNaN(parseInt(input.max, 10))
            ? parseInt(input.max, 10)
            : Infinity;

        const minValue = !isNaN(parseInt(input.min, 10))
            ? parseInt(input.min, 10)
            : MIN_VALUE;

        let value = !isNaN(parseInt(input.value, 10))
            ? parseInt(input.value, 10)
            : MIN_VALUE;

        function rangeCheck(value) {
            const parsedValue = parseInt(value, 10);
            const isValueEmpty = !value.length;
            const isAtMin = parsedValue <= minValue;
            const isAtMax = parsedValue >= maxValue;

            minusButton.disabled = isAtMin || isValueEmpty;

            plusButton.disabled = isAtMax;

            input.classList.toggle("counter__input--active", !isValueEmpty);
        }

        if (!input.value.length || input.value < minValue) {
            input.value = minValue;
        }

        function createCounterClickHandler(operation) {
            function calculateNewValue(oldValue, step) {
                return Math.max(MIN_VALUE, operation(oldValue, step));
            }

            return () => {
                const step = !isNaN(parseInt(input?.step, 10)) ? parseInt(input?.step, 10) : 1;
                const oldValue = !isNaN(parseInt(input.value, 10)) ? parseInt(input.value, 10) : minValue;
                const newValue = Math.min(calculateNewValue(oldValue, step), maxValue);

                input.value = newValue;
                value = newValue;
                rangeCheck(input.value);
            };
        }

        // minusButton.addEventListener("click", createCounterClickHandler(operations.subtract));

        // plusButton.addEventListener("click", createCounterClickHandler(operations.add));

        input.addEventListener("input", (event) => {
            event.preventDefault();
            event.target.value = value;
        });

        rangeCheck(input.value);
    });
}
