import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";

export function initAboutSectionCertificates() {
    const certificatesSwiper = new Swiper(".about-section__certificates-list", {
        slidesPerView: "auto",
        spaceBetween: 12,
        slideClass: "about-section__certificates-item",
        navigation: {
            nextEl: ".about-section__certificates-button--next",
            prevEl: ".about-section__certificates-button--prev",
        },
        touchEventsTarget: true,
        touchRatio: 1,
        touchAngle: 45,
        resistance: true,
        resistanceRatio: 0.65,
        allowTouchMove: true,
        breakpoints: {
            576: {
                allowTouchMove: true,
                spaceBetween: 12,
            },
            768: {
                allowTouchMove: true,
                spaceBetween: 16,
            },
            992: {
                allowTouchMove: true,
                spaceBetween: 24,
            },
            1250: {
                allowTouchMove: false,
                spaceBetween: 24,
            },
        },
        on: {
            init: function () {
                updateNavigation(this);
            },
            transitionEnd: function () {
                updateNavigation(this);
            },
        },
    });

    const modal = document.querySelector(".modal-certificates");

    if (!modal) return;

    const modalOverlay = modal.querySelector(".modal-certificates__overlay");
    const modalContent = modal.querySelector(".modal-certificates__content");
    const modalSwiper = modal.querySelector(".modal-certificates__swiper");

    let modalSwiperInstance;
    let hideControlsTimeout;

    function openModal(initialSlide) {
        modal.style.display = "block";

        setTimeout(() => {
            modal.classList.add("is-open");
        }, 10);

        if (!modalSwiperInstance) {
            // Копируем только изображения из основного свайпера в модальный
            const modalSlides = certificatesSwiper.slides
                .map((slide) => {
                    const img = slide.querySelector("img");
                    if (img) {
                        return `<div class="swiper-slide"><img src="${img.src}" alt="${img.alt}"/></div>`;
                    }
                    return "";
                })
                .filter((slide) => slide !== "");

            modalSwiper.querySelector(".swiper-wrapper").innerHTML =
                modalSlides.join("");

            modalSwiperInstance = new Swiper(modalSwiper, {
                modules: [Navigation, Pagination],
                slidesPerView: 1,
                spaceBetween: 0,
                loop: true,
                speed: 600,
                navigation: {
                    nextEl: ".modal-certificates__button--next",
                    prevEl: ".modal-certificates__button--prev",
                },
                pagination: {
                    el: ".modal-certificates__pagination",
                    type: "fraction",
                },
            });

            // Добавляем обработчик события для показа элементов управления
            modal.addEventListener("mousemove", handleMouseMove);
        }

        modalSwiperInstance.slideTo(initialSlide, 0);
        showControls(); // Показываем элементы управления при открытии модального окна
    }

    function closeModal() {
        modal.classList.remove("is-open");
        clearTimeout(hideControlsTimeout);
        // Ждем окончания анимации перед скрытием модального окна
        setTimeout(() => {
            modal.style.display = "none";
        }, 300); // Время должно соответствовать длительности перехода в CSS
    }

    function handleMouseMove() {
        showControls();
        resetHideControlsTimer();
    }

    function showControls() {
        modal.classList.add("show-controls");
    }

    function hideControls() {
        modal.classList.remove("show-controls");
    }

    function resetHideControlsTimer() {
        clearTimeout(hideControlsTimeout);
        hideControlsTimeout = setTimeout(hideControls, 1000);
    }

    certificatesSwiper.slides.forEach((slide, index) => {
        slide.addEventListener("click", () => openModal(index));
    });

    // Закрытие модального окна при клике на оверлей
    modalOverlay.addEventListener("click", closeModal);

    modalContent.addEventListener("click", (e) => {
        // Проверяем, не является ли цель клика изображением или элементом навигации
        const isClickableElement =
            e.target.tagName === "IMG" ||
            e.target.closest(".modal-certificates__button") ||
            e.target.closest(".modal-certificates__pagination");

        if (!isClickableElement) {
            closeModal();
        }
    });

    return certificatesSwiper;
}

function updateNavigation(swiper) {
    const nextButton = swiper.navigation.nextEl;
    const prevButton = swiper.navigation.prevEl;

    const hideClass = "about-section__certificates-button--hide";

    if (swiper.isBeginning) {
        prevButton.classList.add(hideClass);
    } else {
        prevButton.classList.remove(hideClass);
    }

    if (swiper.isEnd) {
        nextButton.classList.add(hideClass);
    } else {
        nextButton.classList.remove(hideClass);
    }
}

