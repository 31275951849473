import Swiper from "swiper";
import { breakpoints } from '../settings.js';

const { start, xs, sm, md, lg, xl } = breakpoints;

export function initJournalSectionSlides({ list, slideClass, nextEl, prevEl, slidesPerView }) {
    return new Swiper(list, {
        slidesPerView: slidesPerView.start,
        spaceBetween: start.spaceBetween,
        slideClass: slideClass,
        navigation: {
            nextEl: nextEl,
            prevEl: prevEl,
        },
        touchEventsTarget: true,
        touchRatio: 1,
        touchAngle: 45,
        grabCursor: true,
        resistance: true,
        resistanceRatio: 0.65,
        allowTouchMove: true,
        breakpoints: {
            //[start.pageWidth]: {
            //    allowTouchMove: true,
            //    spaceBetween: start.spaceBetween,
            //    slidesPerView: slidesPerView.start,
            //},
            [xs.pageWidth]: {
                allowTouchMove: true,
                spaceBetween: xs.spaceBetween,
                slidesPerView: slidesPerView.xs,
            },
            [sm.pageWidth]: {
                allowTouchMove: true,
                spaceBetween: sm.spaceBetween,
                slidesPerView: slidesPerView.sm,
            },
            [md.pageWidth]: {
                allowTouchMove: false,
                spaceBetween: md.spaceBetween,
                slidesPerView: slidesPerView.md,
            },
            [lg.pageWidth]: {
                allowTouchMove: false,
                spaceBetween: lg.spaceBetween,
                slidesPerView: slidesPerView.lg,
            },
            [xl.pageWidth]: {
                allowTouchMove: false,
                spaceBetween: xl.spaceBetween,
                slidesPerView: slidesPerView.xl,
            },
        },
        on: {
            init: function () {
                updateNavigation(this);
            },
            transitionEnd: function () {
                updateNavigation(this);
            },
        },
    });
}

function updateNavigation(swiper) {

    //const nextButton = swiper.navigation.nextEl;
    //const prevButton = swiper.navigation.prevEl;

    //const hideClass = "js-slider-hide";

    //if (swiper.isBeginning) {
    //	prevButton.classList.add(hideClass);
    //} else {
    //	prevButton.classList.remove(hideClass);
    //}

    //if (swiper.isEnd) {
    //	nextButton.classList.add(hideClass);
    //} else {
    //	nextButton.classList.remove(hideClass);
    //}
}

