export function defaultOnInputChange() {
    const fieldset = this.parentNode;

    this.setAttribute("value", this.value);

    if (fieldset.tagName !== "FIELDSET") return;

    const legend = fieldset.querySelector("legend");

    if (this.value !== "") {
        legend.style.translate = "0 -70%";
    } else {
        legend.style.removeProperty("translate");
    }
}

export function setDefaultInputListeners() {
    const inputs = document.querySelectorAll("input, textarea");

    inputs.forEach((inputElem) => {
        inputElem.setAttribute("value", inputElem.value);
        inputElem.addEventListener("input", defaultOnInputChange);
    });
}
