import Swiper from "swiper";

function buildSwiperForTabPanels(tabPanel) {
    const tabIdentifier = tabPanel.dataset.value;

    const productList = tabPanel.querySelector(".choose-cosmetics__product-list");

    productList.id = `choose-cosmetics__product-list-${tabIdentifier}`;

    const prevButton = tabPanel.querySelector(".choose-cosmetics__product-list-arrows-left");

    prevButton.id = `choose-cosmetics__product-list-arrows-left-${tabIdentifier}`;

    const nextButton = tabPanel.querySelector(".choose-cosmetics__product-list-arrows-right");

    nextButton.id = `choose-cosmetics__product-list-arrows-right-${tabIdentifier}`;

    // eslint-disable-next-line no-undef
    return new Swiper(`#${productList.id}`, {
        slidesPerView: 2,
        spaceBetween: 12,
        slideClass: "choose-cosmetics__product-item",
        navigation: {
            prevEl: `#${prevButton.id}`,
            nextEl: `#${nextButton.id}`,
        },

        breakpoints: {
            576: {
                slidesPerView: 3,
                spaceBetween: 16,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 16,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 24,
            },
            1250: {
                slidesPerView: 4,
                spaceBetween: 24,
            },
        },
    });
}

export function initChooseCosmeticsProductSlider() {
    const tabPanels = document.querySelectorAll(".choose-cosmetics__product-tab-panel");

    tabPanels.forEach((tabPanel) => buildSwiperForTabPanels(tabPanel));
}
