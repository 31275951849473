export function initAnswers() {
    const containers = document.querySelectorAll(".expandable-questions");

    containers.forEach((container) => {
        container.addEventListener("click", (event) => {
            // Ищем клик по заголовку или кнопке
            const header = event.target.closest(".expandable-question__header");
            const toggle = event.target.closest(".expandable-question__toggle");

            if (!header && !toggle) return;

            const question = event.target.closest(".expandable-question");
            const content = question.querySelector(
                ".expandable-question__content"
            );

            const toggleButton = question.querySelector(
                ".expandable-question__toggle"
            );
            const isExpanded =
                toggleButton.getAttribute("aria-expanded") === "true";

            // Меняем состояние кнопки
            toggleButton.setAttribute("aria-expanded", !isExpanded);
            content.classList.toggle("expanded");

            // Опционально: устанавливаем фокус на контент при его разворачивании
            if (!isExpanded) {
                content.setAttribute("tabindex", "-1");
            } else {
                content.removeAttribute("tabindex");
            }
        });
    });
}

