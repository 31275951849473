import IMask from "imask";

export function initDateMask() {

  const dateInputs = document.querySelectorAll("input.mask-date");

  dateInputs.forEach((input) => {
    IMask(input, {
      mask: Date,
      pattern: 'd{.}`m{.}`Y',
      blocks: {
        d: { mask: IMask.MaskedRange, from: 1, to: 31 },
        m: { mask: IMask.MaskedRange, from: 1, to: 12 },
        Y: { mask: IMask.MaskedRange, from: 1900, to: 9999 }
      },
      format: (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
      },
      parse: (str) => {
        const [day, month, year] = str.split('.');
        return new Date(year, month - 1, day);
      },
    });
  });
}