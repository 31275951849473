export const breakpoints = {
    xl: {
        pageWidth: 1370,
        spaceBetween: 24,
    },
    lg: {
        pageWidth: 1250,
        spaceBetween: 22,
    },
    md: {
        pageWidth: 992,
        spaceBetween: 20,
    },
    sm: {
        pageWidth: 768,
        spaceBetween: 18,
    },
    xs: {
        pageWidth: 576,
        spaceBetween: 16,
    },
    start: {
        pageWidth: 375,
        spaceBetween: 14,
    }
}


export const slides = [
    {
        name: 'blogs',
        slidesPerView:  { xl: 'auto', lg: 'auto', md: 'auto', sm: 'auto', xs: 'auto', start: 'auto' }
    },
    {
        name: 'article-select',
        slidesPerView:  { xl: 'auto', lg: 'auto', md: 'auto', sm: 'auto', xs: 'auto', start: 'auto' }
    },
    {
        name: 'new-cosmetics',
        slidesPerView: { xl: 'auto', lg: 'auto', md: 'auto', sm: 'auto', xs: 'auto', start: 'auto' }
    },
    {
        name: 'news-medicine',
        slidesPerView: { xl: 3, lg: 3, md: 3, sm: 2, xs: 1.5, start: 1.5 }
    },
    {
        name: 'vlog',
        slidesPerView: { xl: 'auto', lg: 'auto', md: 'auto', sm: 'auto', xs: 'auto', start: 'auto' }
    },
    /*{
        name: 'article',
        slidesPerView: { xl: 'auto', lg: 'auto', md: 'auto', sm: 'auto', xs: 'auto', start: 'auto' }
    }*/,
    {
        name: 'profile',
        slidesPerView: { xl: 1, lg: 1, md: 1, sm: 1, xs: 1, start: "auto" }
    }
]

