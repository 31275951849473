import { Calendar } from "fullcalendar";
import dayGridPlugin from "@fullcalendar/daygrid";
import ruLocale from "@fullcalendar/core/locales/ru";

function formatDateToString(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Месяцы с 0
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
}

/**
 *
 * @param {{
 *      calendarEl: HTMLElement,
 *      freeDates: string[]
 * }} param0
 */
export function initCalendar({
    calendarEl,
    freeDates,
    hasWaitingList = false,
    highlitedDates = [],
}) {

    const dateKeeper = document.querySelector(`#${calendarEl.getAttribute("data-for")}`);

    const today = new Date();
    today.setHours(0, 0, 0, 0);


    const maxDays = 62;

    const maxDate = new Date();
    maxDate.setDate(today.getDate() + maxDays);

    const calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        locale: ruLocale,
        headerToolbar: {
            start: "prev", // will normally be on the left. if RTL, will be on the right
            center: "title",
            end: "next",
        },
        titleFormat: {
            year: "numeric",
            month: "long",
        },
        datesSet: (info) => {
            const titleElement = calendarEl.querySelector(".fc-toolbar-title");
            const title = info.view.title.replace(" г.", ""); // Удаляем "г."
            titleElement.textContent = title; // Обновляем содержимое заголовка

            document.querySelectorAll(".fc-daygrid-day").forEach((el) => {
                const date = new Date(el.dataset.date);
                const dateStr = formatDateToString(date);
                const isInFreeDates = freeDates.includes(dateStr);
                const isHighlited = highlitedDates.includes(dateStr);

                if (isHighlited) {
                    el.classList.add("fc-day-highlited");
                }

                if (isInFreeDates) {
                    el.classList.add("fc-day-highlited");
                }

                // Отключаем даты раньше сегодняшнего дня и после максимальной даты
                if (date < today || date > maxDate) {
                    el.classList.add("fc-day-disabled");
                }
                // Лист ожидания
                if (!isInFreeDates && !isHighlited && hasWaitingList) {
                    el.classList.add("fc-day-waiting-list");
                }

                if (isInFreeDates && !isHighlited) {
                    if (hasWaitingList) {
                        el.classList.add("fc-day-waiting-list");
                    } else {
                        el.classList.add("fc-day-disabled");
                    }
                }
                // Если нет листа ожидания, отключаем даты
                if (!isHighlited && !isInFreeDates && !hasWaitingList) {
                    el.classList.remove("fc-day-disabled");
                }
            });
        },
        dateClick: (info) => {
            const date = new Date(info.date);
            const dateStr = formatDateToString(date);
            const isInFreeDates = freeDates.includes(dateStr);
            const isHighlited = highlitedDates.includes(dateStr);
            console.log('dateStr', dateStr)
            console.log('isInFreeDates', isInFreeDates)
            console.log('isHighlited', isHighlited)

            if (date >= today && date <= maxDate && (!info.dayEl.classList.contains("fc-day-disabled"))) {
                calendarEl.querySelectorAll(".fc-daygrid-day").forEach((el) => {
                    el.classList.remove("fc-day-active");
                });

                info.dayEl.classList.add("fc-day-active");

                dateKeeper.value = info.dateStr;

                dateKeeper.dispatchEvent(new Event("change"));
            }
        },
    });
    calendar.render();
}
