const deliveryDetails = document.querySelector(".order-delivery");
// const noAddress = deliveryDetails?.querySelector("#noAddress");
const deliveryCourierAddress = deliveryDetails?.querySelector("#deliveryCourierAddress");
const deliveryPointAddress = deliveryDetails?.querySelector("#deliveryPointAddress");
const centerAddress = deliveryDetails?.querySelector("#centerAddress");
const companyDelivery = deliveryDetails?.querySelector("#companyDelivery");

const selves = document.querySelectorAll(".delivery-self");
const couriers = document.querySelectorAll(".delivery-courier");
const centerSelves = document.querySelectorAll(".delivery-center-self");
const company = document.querySelectorAll(".delivery-company");

// Submit order form
const orderForm = document.querySelector(".order form");

// Image need to change to Iframe
const mapImage = document.querySelector(".order-map__map img");
const mapPopup = document.querySelector(".map-popup");
const closeMapPopup = mapPopup?.querySelector(".map-popup-close");

const pointList = document.querySelectorAll(".order-map__options");

const mapTabs = document.querySelectorAll(".order-map__header-tabs .li");
const mapTabsContentContainer = document.querySelector(".order-map");
const mapTabsContent = document.querySelectorAll(".order-map > div");

export function initOrderPageModules() {
    selves?.forEach((item) => {
        item.addEventListener("click", () => {
            deliveryDetails.querySelector(".card.show")?.classList.remove("show");
            deliveryPointAddress.classList.add("show");
        });
    });
    couriers?.forEach((item) => {
        item.addEventListener("click", () => {
            deliveryDetails.querySelector(".card.show")?.classList.remove("show");
            deliveryCourierAddress.classList.add("show");
        });
    });
    centerSelves?.forEach((item) => {
        item.addEventListener("click", () => {
            deliveryDetails.querySelector(".card.show")?.classList.remove("show");
            centerAddress.classList.add("show");
        });
    });
    company?.forEach((item) => {
        item.addEventListener("click", () => {
            deliveryDetails.querySelector(".card.show")?.classList.remove("show");
            companyDelivery.classList.add("show");
        });
    });

    mapImage?.addEventListener("click", () => mapPopup.classList.add("show"));
    closeMapPopup?.addEventListener("click", () => mapPopup.classList.remove("show"));

    pointList?.forEach((list) => {
        const button = list?.querySelector(".order-map__options-selected");
        const variants = list?.querySelectorAll(".order-map__options-variants .li");

        button?.addEventListener("click", () => list.classList.toggle("open"));
        variants?.forEach((variant) => {
            variant.addEventListener("click", () => {
                const value = variant.dataset.value;
                button.querySelector("span").innerText = value;
                list.classList.toggle("open");
            });
        });
    });
    mapTabs?.forEach((tab) => {
        const button = tab.querySelector(".button");
        const id = button.dataset.id;

        button.addEventListener("click", () => {
            mapTabsContent.forEach((item) => item.classList.remove("active"));
            mapTabs.forEach((item) => item.classList.remove("active"));
            button.parentNode.classList.add("active");
            const t = mapTabsContentContainer?.querySelector(`#${id}`);
            t.classList.add("active");
        });
    });
}
