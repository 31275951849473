/* eslint-disable indent */
import { changeSearchInputValue } from "../index.js";

function initHint(hintEl) {
    hintEl.addEventListener("click", () => {
        changeSearchInputValue(hintEl.innerText);
    });
}

/**
 *
 * @param {{[key: string]:string}[] | string[]} hints
 */
export function generateHintListHTML(hints, title) {
    const hintList = document.createElement("div");

    hintList.classList.add("search-hints-list");

    hintList.innerHTML = `
    <div class="search-hints-list">
        <p class="p search-hints-list__heading">${title}</p>
        <ul class="search-hints-list__items">
            ${
                hints.map((hint) => (
                    typeof hint === "string" ? `
                        <li class="search-hints-list__item">
                            ${hint}
                        </li>
                    ` : `
                        <a href="${hint[Object.keys(hint)[0]]}" class="search-hints-list__item">
                            ${Object.keys(hint)[0]}
                        </a>
                    `
                )).join(" ")
            }
        </ul>
    </div>
    `;

    if (typeof hints[0] === "string") {
        const hintEls = hintList.querySelectorAll(".search-hints-list__item");

        hintEls.forEach(initHint);
    }
    return hintList;
}

export function initHints() {
    const hintEls = document.querySelectorAll(".search-hints-list__item");

    hintEls.forEach(initHint);
}
