import flatpickr from 'flatpickr';
import { Russian } from "flatpickr/dist/l10n/ru.js"
// import IMask from "imask";

export function initInputDate() {
    const els = document.querySelectorAll('.input-date');

    if (!els.length) return;

    flatpickr(".input-date", {
        disableMobile: "true",
        allowInput: true,
        dateFormat: "d-m-Y",
        // allowInvalidPreload: true,
        "locale": Russian,
    });

    // els.forEach((input) => {
    //     IMask(input, {
    //         mask: ["00000000", "00-00-0000"],
    //     });
    // });
}
