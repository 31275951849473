export function initFeedback() {
    const openButton = document.querySelectorAll(".ask-question");
    const popup = document.getElementById("ask-question-popup");
    if (!popup) return;
    const closeButton = popup.querySelector(".popup__close");

    if (!openButton) return;
    if (!closeButton) return;

    function togglePopup() {
        console.log("check toggle Popup");

        popup.classList.toggle("open");
    }

    openButton.forEach((btn) => {
        btn.addEventListener("click", togglePopup);
    });
    // openButton.addEventListener("click", togglePopup);
    closeButton.addEventListener("click", togglePopup);

    // Закрытие попапа при клике вне контента
    popup.addEventListener("click", (event) => {
        if (event.target === popup) {
            closePopup();
        }
    });

    // Предотвращение закрытия при клике на контент
    popup
        .querySelector(".popup__content")
        .addEventListener("click", (event) => {
            event.stopPropagation();
        });
}

