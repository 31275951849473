import { initGeltekJournalSliders } from "./geltek-journal-sliders/index.js";
import { initSetSlider } from "./set-slider/index.js";
import { initArticlesSlider } from "./articles/index.js";
import { initUsefulArticlesSlider } from "./useful-articles/index.js";
import { initProductSliders } from "./product-slider/index.js";
import { initCatalogFilters } from "./catalog-filters/index.js";

export function initSharedPageModules() {
    initProductSliders();
    initGeltekJournalSliders();
    initSetSlider();
    initArticlesSlider();
    initUsefulArticlesSlider();
    initCatalogFilters();
}
