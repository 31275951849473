import Swiper from "swiper";

/**
 *
 * @param {HTMLElement} wrapper element with class "categories-slider__wrapper"
 * @param {Number} top
 */
function changeCategoriesSliderOnScroll(wrapper, top) {
    return function handleScroll() {
        if (wrapper.classList.contains("no-sticky")) {
            window.removeEventListener("scroll", handleScroll);
            return;
        };
        if (window.scrollY > top && window.scrollY < this.oldScroll) {
            wrapper.classList.add("categories-slider__wrapper--scroll");
        } else {
            wrapper.classList.remove("categories-slider__wrapper--scroll");
        }
        this.oldScroll = window.scrollY;
    };
}

/**
 *
 * @param {HTMLElement} slider element with class "categories-slider"
 */
function initSlider(slider) {
    const id = slider.id;

    return new Swiper(`#${id}`, {
        slidesPerView: "auto",
        spaceBetween: 12,
        slideClass: "categories-slider__item",
        navigation: {
            prevEl: `#${id} .categories-slider-arrows-left`,
            nextEl: `#${id} .categories-slider-arrows-right`,
        },
    });
}

export function initCategoriesSliders() {
    const sliders = document.querySelectorAll(".categories-slider");

    if (!sliders.length) return;

    sliders.forEach((slider, index) => {
        slider.id = `categories-slider-${index}`;

        const wrapper = slider.parentElement;

        if (!wrapper.classList.contains("categories-slider__wrapper")) return;

        const wrapperTop = wrapper.offsetTop;

        if (!wrapper.classList.contains("no-sticky")) {
            window.addEventListener("scroll", changeCategoriesSliderOnScroll(wrapper, wrapperTop));
        }

        initSlider(slider);
    });
}
