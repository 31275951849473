import { initDetailMarkets } from "./detail-marketplaces/index.js";
import { initDetailSlider } from "./detail-slider/index.js";
import { initDetailSidebar } from "./detail-sidebar/index.js";
import { initPageTabs } from "./page-tabs/index.js";
import { initDetailAccordion } from "./detail-accordion/index.js";
import { initDescShorter } from "./desc-shorter/index.js";
import { initToCart } from "./detail-to-cart/index.js";

export function initDetailPageModules() {
    initDetailMarkets();
    initDetailSlider();
    initDetailSidebar();
    initPageTabs();
    initDetailAccordion();
    initDescShorter();
    initToCart();
}