/* eslint-disable indent */
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import { getCityCenterCoordinates } from "../../../modules/ymap/city-coordinates.js";
import { BaseYMap } from "../../../modules/ymap/index.js";
import { API } from "../../../services/api/endpoints/index.js";
import { yclientsCodes } from "../../../services/api/endpoints/yclients/codes.js";
import { initTabList } from "../../../ui/tabs/index.js";
import { executeScriptsInElement, htmlDecode } from "../../../utils/html-decode/index.js";
import { generateCenterCardByCenterInfo } from "../center-card.js";
import { generateDoctorCardHTML } from "../doctor.js";
import { generatePopUpBySkinCenterInfo, openAppointmentPopup } from "../popup.js";
import { generateReview, generateReviewsSectionByFeature } from "../review.js";
import { parseCenterInfoFromHTMLStr } from "../util.js";
import { generateGallerySlidesByFeature } from "./gallery.js";
import { setSkinDiagnostictCenterTitles } from "./page-title.js";
import { notifyErrorWithText } from "../../../modules/notifications/index.js";


const main = document.querySelector("main");

function initConsultationCards(serviceType) {
    const cardWrapper = main.querySelector(`#center-${serviceType} .card-wrapper`);

    if (!cardWrapper) return;

    cardWrapper.innerHTML = `
    <div class="card record-card catalog-cosmetics-consultation__record card--image">
        <img src="/img/upload/record-1.png" alt="record-1" class="record-card__image">
        <div class="record-card__content">
            <button class="button button-open-popup button--ghost--light" type="button" data-appointment="${serviceType}">
                <span class="p">Записаться очно в центр диагностики кожи</span>
                <i class="icon-arrow-right" aria-label="icon arrow right"></i>
            </button>
        </div>
    </div>
    <div class="card record-card catalog-cosmetics-consultation__record card--image">
        <img src="/img/upload/record-2.png" alt="record-2" class="record-card__image">
        <div class="record-card__content">
            <button class="button button-open-popup button--ghost--light" type="button" data-appointment="online">
                <span class="p">Записаться на онлайн-консультацию</span>
                <i class="icon-arrow-right" aria-label="icon arrow right"></i>
            </button>
        </div>
    </div>
    `;

    const serviceOpenAppoitmentButton = cardWrapper.querySelector(`button[data-appointment="${serviceType}"]`);

    if (serviceOpenAppoitmentButton) {
        serviceOpenAppoitmentButton.addEventListener("click", () => openAppointmentPopup(serviceType));
    }

    const popup = generatePopUpBySkinCenterInfo({
        address: "",
        phone: "+7-985-840-08-12",
        city: "",
        companyId: yclientsCodes.online.companyId,
        serviceId: yclientsCodes.online.serviceId,
        serviceType: "online",
        workHours: "",
    });

    main.append(popup);

    const onlineOpenAppoitmentButton = cardWrapper.querySelector("button[data-appointment='online']");

    if (onlineOpenAppoitmentButton) {
        onlineOpenAppoitmentButton.addEventListener("click", () => openAppointmentPopup("online"));
    }
}

// function initReviews(reviews) {
//     const reviewList = main.querySelector(".reviews__list");

//     if (!reviewList) return;

//     reviews.forEach((review) => {
//         const reviewEl = generateReview(review);

//         reviewList.append(reviewEl);
//     });
// }

function initPhotosSliders(serviceType) {
    const gallerySwiper = main.querySelector(`#center-${serviceType} .js-slider-photos-list`);
    if (!gallerySwiper) return null;
    const slides = gallerySwiper.querySelectorAll(".swiper-slide");

    const zoomSlider = document.createElement("div");

    zoomSlider.classList.add("modal", "skin-center-gallery");

    zoomSlider.id = "skin-center-gallery";

    zoomSlider.innerHTML = `
            <div data-close-modal class="modal__overlay"></div>
            <button class="modal__close modal-center__close" data-close-modal>
                <i class="modal__close-icon icon-close"></i>
            </button>
            <div class="swiper">
                <div class="skin-center-gallery__pagination"></div>
                <div class="swiper-wrapper">

                </div>
                <div
                    class="skin-center-gallery__button skin-center-gallery__button--prev"
                >
                    <i class="icon-arrow-left"></i>
                </div>
                <div
                    class="skin-center-gallery__button skin-center-gallery__button--next"
                >
                    <i class="icon-arrow-right"></i>
                </div>
            </div>
    `;

    main.append(zoomSlider);

    const zoomModalCloseButton = zoomSlider.querySelector(".modal__close.modal-center__close");

    const zoomModalOverlay = zoomSlider.querySelector(".modal__overlay");

    zoomModalCloseButton.addEventListener("click", window.closeModals);

    zoomModalOverlay.addEventListener("click", window.closeModals);

    const zoomSwiperEl = zoomSlider.querySelector(".swiper");

    const zoomSliderSwiperWrapper = zoomSlider.querySelector(".swiper-wrapper");

    slides.forEach((slide) => {
        const clonedSlide = slide.cloneNode(true);

        zoomSliderSwiperWrapper.append(clonedSlide);
    });

    const zoomSwiper = new Swiper(zoomSwiperEl, {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        speed: 600,
        navigation: {
            nextEl: ".skin-center-gallery__button--next",
            prevEl: ".skin-center-gallery__button--prev",
        },
        pagination: {
            el: ".skin-center-gallery__pagination",
            type: "fraction",
        },
    });

    slides.forEach((slide, index) => {
        slide.addEventListener("click", () => {
            window.openModal("#skin-center-gallery");
            zoomSwiper.slideTo(index, 0);
        });
    });

    return new Swiper(gallerySwiper, {
        spaceBetween: 8,
        slidesPerView: "auto",
        touchEventsTarget: true,
        touchRatio: 1,
        touchAngle: 45,
        resistance: true,
        resistanceRatio: 0.65,
        allowTouchMove: true,
        navigation: {
            prevEl: ".js-slider-photos-slider .slider-navigation-prev",
            nextEl: ".js-slider-photos-slider .slider-navigation-next",
        },
        breakpoints: {
            992: {
                slidesPerView: 2,
                spaceBetween: 24,
                allowTouchMove: true,
            },
        },
    });
}

export async function initSkinDiagnosticCenterCity(serviceType, feature) {
    if (!main) return;

    const cityContainer = document.querySelector(`#center-${serviceType}`);

    if (!cityContainer) return;

    const centerInfo = feature
        ? {
            ...parseCenterInfoFromHTMLStr(feature.properties.balloonContentBody),
            city: feature.city,
        }
        : null;

    const galleryEl = main.querySelector(`#center-${serviceType} .slider.js-slider-photos-slider .slider-wrapper`);

    if (galleryEl) {
        const slidesHTML = generateGallerySlidesByFeature(feature);

        galleryEl.innerHTML = slidesHTML;
        initPhotosSliders(serviceType);
    }

    const centerCardEl = main.querySelector(`#center-${serviceType} .center__card`);

    if (centerCardEl) {
        const card = generateCenterCardByCenterInfo({
            ...centerInfo,
            address: feature.address,
            phone: feature.phone,
            image: feature.image,
            workHours: feature.worktime,
            serviceType,
            city: feature.city,
            metro: feature.metro,
            withLink: false,
        });
        centerCardEl.replaceWith(card);

        initConsultationCards(serviceType);

        // initReviews(Object.values(Object.values(schedule.items)[0].reviews).slice(0, 3));
    }

    try {
        const scheduleResponse = await API.yclients.getScheduleBranch(serviceType);

        const schedule = await scheduleResponse.json();

        const doctorsListEl = document.querySelector(`#center-${serviceType} .js-slider-doctors-list .swiper-wrapper`);

        if (!doctorsListEl) return;

        if (!schedule.items) {
            doctorsListEl.closest(".section").remove();
            return;
        }

        Object.values(schedule.items).forEach((item) => {
            const employee = item.employee;

            const reviews = item.reviews;

            const doctorCard = generateDoctorCardHTML({
                doctor: employee,
                city: feature.city,
                address: feature.address,
                serviceType,
                reviews,
            });

            doctorsListEl.prepend(doctorCard);
        });

        const reviews = generateReviewsSectionByFeature(feature);

        if (reviews) {
            const tabItem = reviews.querySelector(".tabs__tab-list-item, .tabs__tab-list-item--selected");
            if (tabItem) {
                cityContainer.append(reviews);

                executeScriptsInElement(reviews);

                initTabList(reviews);
            }
        }
    } catch (error) {
        notifyErrorWithText(error.message);
    }

    cityContainer.classList.remove("skin-diagnostic-center__content-city--loading");
}
