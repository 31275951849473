import { getCatalogWithCategories } from "../../modules/dynamic-api-test/catalog/getCatalogWithCategories.js";
/**
 * @typedef {{
*      name: string,
*      morning: {
*          title: string,
*          categories: {
*              [key: string]: {id:number, products: Product[]}
*          }
*      },
*      evening: {
*          title: string,
*          categories: {
*              [key: string]: {id:number, products: Product[]}
*          }
*      },
*      all: {
*          title: string,
*          categories: {
*              [key: string]: {id:number, products: Product[]}
*          }
*      }
* }} AdaptedTestResultWithCategories
*/

/**
 *
 * @param {AdaptedTestResultWithCategories} catalog
 * @returns
 */
export function getCatalogHTML(catalog) {
    const section = document.createElement("section");

    section.classList.add("section", "skintest__recommend");

    const container = document.createElement("div");

    container.classList.add("container");

    section.append(container);

    const sectionWrapper = document.createElement("div");

    sectionWrapper.classList.add("section__wrapper");

    container.append(sectionWrapper);

    const catalogMorningHTML = getCatalogWithCategories(catalog.morning);

    const catalogEveningHTML = getCatalogWithCategories(catalog.evening);

    const catalogIntensiveHTML = getCatalogWithCategories(catalog.all);

    sectionWrapper.innerHTML = catalogMorningHTML + catalogEveningHTML + catalogIntensiveHTML;

    return section;
}
