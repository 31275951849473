function vacanciesFilter(filterId) {
    if (typeof filterId !== "string") return;

    const dropdown = document.getElementById(filterId);
    if (!dropdown) {
        console.warn(`Элемент с id "${filterId}" не найден`);
        return;
    }

    const selectedText = dropdown.querySelector(".button-filter__wrapper-text");
    const options = dropdown.querySelectorAll(".vacancies-filter__item input");

    if (!selectedText) {
        console.warn(
            `Элемент с классом "button-filter__wrapper-text" не найден в ${filterId}`
        );
        return;
    }

    options.forEach((option) => {
        option.addEventListener("change", function () {
            if (this.checked) {
                const label = this.closest(
                    ".vacancies-filter__item"
                ).querySelector(".vacancies-filter__label");
                if (label) {
                    selectedText.textContent = label.textContent.trim();
                } else {
                    console.warn(
                        "Элемент с классом 'vacancies-filter__label' не найден"
                    );
                }
            }
        });
    });
}

export function initVacanciesFilter() {
    vacanciesFilter("vacancies-direction");
    vacanciesFilter("vacancies-city");
}

