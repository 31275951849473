export function initEvents() {
    const orders = document.getElementById("profile-orders");
    if (!orders) return;

    // Получаем все кнопки в группе
    const yearButtons = orders.querySelectorAll(".profile-order-filters__year-item");
    const filterSelectBox = orders.querySelector(".profile-order-filters__filter");

    if (filterSelectBox) {
        const filterSelect = filterSelectBox.querySelector(".profile-order-filters__filter-content");
        // Добавляем обработчик события для каждой кнопки
        yearButtons.forEach((button) => {
            button.addEventListener("click", function () {
                // Удаляем класс у всех кнопок
                yearButtons.forEach((btn) => btn.classList.remove("profile-order-filters__year-item--select"));

                // Добавляем класс текущей кнопке
                this.classList.add("profile-order-filters__year-item--select");
            });
        });
        if (window.innerWidth <= 375) {
            filterSelectBox.addEventListener("click", () => {
                // Добавляем класс
                filterSelect.classList.add(".profile-order-filters__filter-content--desktop");
            });
        }
    }
}

export function btnShowScroll() {
    const btn = document.querySelector(".btn-show-scroll");
    if (!btn) return;
    if (window.innerWidth <= 375) {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 450) {
                btn.style.display = "block";
            } else {
                btn.style.display = "none";
            }
        });
    }
}

export function filterSync() {
    const page = document.getElementById("profile-order");
    if (!page) return;

    const titles = page.querySelectorAll(".profile-order-filters__filter-title");
    const mob = page.querySelectorAll('[name="profile-order-filters__sorting-mob"]');
    const des = page.querySelectorAll('[name="profile-order-filters__sorting-des"]');

    if (mob.length !== des.length) {
        console.error("Количество радио-кнопок не совпадает");
        return;
    }

    // Функция для обновления текста заголовков
    function updateTitles(value) {
        titles.forEach((title) => {
            title.textContent = value;
        });
    }

    // Функция синхронизации радиокнопок
    function syncRadio(group1, group2) {
        group1.forEach((radio, index) => {
            radio.addEventListener("change", function () {
                group2[index].checked = this.checked;
                updateTitles(this.value);
            });
        });
    }

    // Синхронизация мобильных и десктопных радиокнопок
    syncRadio(mob, des);
    syncRadio(des, mob);

    // Инициализация заголовков на основе выбранного значения
    const checkedRadio = [...mob, ...des].find((radio) => radio.checked);
    if (checkedRadio) {
        updateTitles(checkedRadio.value);
    }
}
