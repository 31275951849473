import { BodyScroll } from "../../../utils/body-scroll.js";

const profileMenu = document.querySelector(".profile-menu");
const openProfileMenuButton = document.querySelector(".profile-menu-open-button");
const closeProfileMenuButton = document.querySelector(".profile-menu__close-button");

function openProfileMenu() {
    if (!profileMenu) return;

    BodyScroll.lock();

    profileMenu.classList.add("profile-menu--open");
}

function closeProfileMenu() {
    if (!profileMenu) return;

    BodyScroll.unlock();

    profileMenu.classList.remove("profile-menu--open");
}

export function initProfileMenu() {
    if (!profileMenu || !openProfileMenuButton || !closeProfileMenuButton) return;

    openProfileMenuButton.addEventListener("click", openProfileMenu);

    closeProfileMenuButton.addEventListener("click", closeProfileMenu);
}
