export function initDetailSidebar() {
    const detail = document.querySelector(".detail");

    if (!detail) return;

    const sidebar = detail.querySelector(".detail__sidebar");
    const sidebarInner = detail.querySelector(".detail__sidebar-inner");
    const header = document.querySelector(".header-full");
    let offset = 24;

    const pageTabs = document.querySelector(".page-tabs");


    function action() {
        if (window.innerWidth < 992) {
            sidebarInner.style.position = "";
            sidebarInner.style.top = "";
            sidebarInner.style.bottom = "";
            sidebarInner.style.left = "";
            sidebarInner.style.width = "";

            return;
        }

        let topPos = header.offsetHeight + offset;
        let leftPos = sidebar.offsetLeft;
        let sidebarWidth = sidebar.offsetWidth;
        let sidebarHeight = sidebar.offsetHeight;
        let sidebarInnerHeight = sidebarInner.offsetHeight;

        let curOffset = offset;

        if (pageTabs) {
            curOffset += pageTabs.offsetHeight;
            topPos += pageTabs.offsetHeight;
        }

        let topLimit = sidebar.getBoundingClientRect().top - header.offsetHeight - curOffset;
        let bottomLimit = topLimit + sidebarHeight - sidebarInnerHeight;

        if (topLimit <= 0) {
            if (bottomLimit <= 0) {
                sidebarInner.style.position = "absolute";
                sidebarInner.style.top = "";
                sidebarInner.style.bottom = 0;
                sidebarInner.style.left = 0;
                sidebarInner.style.width = "";
            } else {
                sidebarInner.style.position = "fixed";
                sidebarInner.style.top = topPos + "px";
                sidebarInner.style.bottom = "";
                sidebarInner.style.left = leftPos + "px";
                sidebarInner.style.width = sidebarWidth + "px";
            }
        } else {
            sidebarInner.style.position = "";
            sidebarInner.style.top = "";
            sidebarInner.style.bottom = "";
            sidebarInner.style.left = "";
            sidebarInner.style.width = "";
        }
    }

    action();

    window.addEventListener("scroll", () => {
        action();
    });
}
