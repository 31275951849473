/* eslint-disable indent */
import { initFileUploads } from "../../modules/image-upload/image-upload.js";
import { notifyAboutSuccessAppointment, notifyErrorWithText } from "../../modules/notifications/index.js";
import { API } from "../../services/api/endpoints/index.js";
import { yclientsCodes } from "../../services/api/endpoints/yclients/codes.js";
import { setDefaultInputListeners } from "../../ui/input/index.js";
import { initPhoneMask } from "../../utils/masks/phone/index.js";
import { initCalendar } from "./calendar.js";

function resetPopup(serviceType) {
    const popup = document.getElementById(`popup--appointment-${serviceType}`);

    if (!popup) return;

    const contactsStepEl = popup.querySelector(`#contacts_step-${serviceType}`);

    const timeStepEl = popup.querySelector(`#time_step-${serviceType}`);

    const doctorsStepEl = popup.querySelector(`#doctors_step-${serviceType}`);

    if (contactsStepEl) {
        contactsStepEl.remove();
    }

    if (timeStepEl) {
        timeStepEl.remove();
    }

    if (doctorsStepEl) {
        doctorsStepEl.remove();
    }

    const stepIndicators = popup.querySelectorAll(".step-indicator__button.selected");

    stepIndicators.forEach((i) => {
        i.classList.remove("selected");
    });

    popup.querySelector(`#step-indicator__button-calendar-${serviceType}`).classList.add("selected");
    popup.querySelector(`#calendar_step-${serviceType}`).classList.add("show");
}

function closeAllAppointmentPopups() {
    const popups = document.querySelectorAll("popup--appointment");

    popups.forEach((popup) => {
        popup.classList.remove("open");
    });
    sessionStorage.removeItem("doctor-appointment");
}

export function closeAppointmentPopup(serviceType) {
    const popup = document.getElementById(`popup--appointment-${serviceType}`);

    if (popup) {
        popup.classList.remove("open");
        resetPopup(serviceType);
    }
    sessionStorage.removeItem("doctor-appointment");
}

export async function openAppointmentPopup(serviceType) {
    closeAllAppointmentPopups();

    const popup = document.getElementById(`popup--appointment-${serviceType}`);

    if (popup) {
        popup.classList.add("open");

        popup.classList.add("popup--loading");

        const data = await API.yclients.getScheduleBranch(serviceType);

        const storedDoctor = sessionStorage.getItem("doctor-appointment");

        const cardContent = popup.querySelector(".card.card--entity .card__content");

        if (storedDoctor) {
            const doctor = JSON.parse(storedDoctor);

            cardContent.innerHTML += `
            <div class="card__content-info card__content-info--doctor">
                    <i class="icon icon-user" aria-label="icon user"></i>
                    <p class="p">${doctor.doctor.name}</p>
            </div>
            `;
        } else {
            cardContent.querySelector(".card__content-info--doctor")?.remove();
        }

        const schedule = await data.json();
        const dates = Object.keys(schedule.menu);

        const calendarEl = popup.querySelector(`#appointment__calendar-${serviceType}`);

        popup.classList.remove("popup--loading");

        initCalendar({
            calendarEl,
            freeDates: dates,
        });
    }
}

/**
 *
 * @param {{
 *  address:string,
 *  city: string,
 *  schedule: import("../../services/api/endpoints/yclients/index.js").ScheduleBranchItemSchedule[],
 *  serviceType:string
 *  date: string,
 *  employee: import("../../services/api/endpoints/yclients/index.js").ScheduleBranchEmployee,
 *  time: string
 * }} param0
*/
function generateContactsStep({
    address,
    city,
    serviceType,
    date,
    employee,
    time,
    isWaitingList = false,
}) {
    const contactsStepEl = document.createElement("div");

    contactsStepEl.classList.add("appointment__step", "show");

    contactsStepEl.id = `contacts_step-${serviceType}`;

    const serviceId = yclientsCodes[serviceType].serviceId;

    const companyId = yclientsCodes[serviceType].companyId;

    contactsStepEl.innerHTML = `
        <div class="card card--entity">
            <div class="card__head">
                <h4 class="h5">Данные вашего визита</h4>
            </div>
            <div class="card__content">
                <div class="card__content-info">
                    <i class="icon icon-pin" aria-label="icon pin"></i>
                    ${serviceType === "online"
            ? "<p class=\"p\">Онлайн</p>"
            : `<p class="p">г. ${city}, ${address}</p>`
        }
                </div>
                <div class="card__content-info">
                    <i class="icon icon-calendar" aria-label="icon calendar"></i>
                    <p class="p">${date}</p>
                </div>
                ${employee.name ? `
                    <div class="card__content-info">
                        <i class="icon icon-user" aria-label="icon user"></i>
                        <p class="p">${employee.name}</p>
                    </div>
                    ` : ""
        }
                <div class="card__content-info">
                    <i class="icon icon-clock" aria-label="icon clock"></i>
                    <p class="p">${time}</p>
                </div>
            </div>
        </div>
        <div class="step__content">
            <h4 class="h6">Заполните контактные данные</h4>
            <form class="form" method="post" enctype="multipart/form-data">
                <input hidden name="workerId" value="${employee.id}">
                <input hidden name="companyId" value="${companyId}">
                <input hidden name="serviceId" value="${serviceId}">
                <input hidden name="serviceType" value="${serviceType}">
                <input hidden name="globalServiceType" value="${serviceType}">
                <input hidden name="datetime" value="${date}T${time}:00">
                <input hidden name="action" value="setRecord">
                <input hidden name="form" value="schedule">
                ${isWaitingList ? `
                    <input type="hidden" name="isWaitingList" value="Y">
                    ` : ""
        }
                <input hidden name="yclients_agreement" value="Y">
                <input type="file" class="hidden" accept="image/jpeg, image/png" multiple hidden name="faceImages[]">
                <input hidden name="isWaitingList">

                <div class="card card--toast">
                    <i class="icon icon-info" aria-label="icon info"></i>
                    <div class="card__message">
                        <p class="p">В кабинете косметолога ведется запись консультаций на камеру. Это делается исключительно для улучшения качества обслуживания</p>
                    </div>
                </div>
                <div class="form__input input input--text">
                    <label class="label">
                        <input type="text" name="fullname" required>
                        <span class="p">Имя</span>
                    </label>
                </div>
                <div class="form__input input input--text">
                    <label class="label">
                        <input type="email" name="email" required>
                        <span class="p">E-mail</span>
                    </label>
                </div>
                <div class="form__input input input--text">
                    <label class="label">
                        <input class="mask-phone" type="tel" name="phone" required>
                        <span class="p">Телефон</span>
                    </label>
                </div>
                <div class="form__input input input--file card">
                    <div class="input__uploading">
                        <img class="icon" src="/img/icons/Loader.svg" alt="Icon loader"></img>
                        <p class="p">Идет загрузка файла</p>
                    </div>
                    <div class="input__info">
                        <p class="p input__title">Загрузите фотографии средств которыми пользуетесь</p>
                        <p class="p input__hint">До 5 файлов в формате JPG, JPEG, PNG</p>
                        <div class="input__file-list">
                            <div class="input__file">
                                <img src="" alt="">
                                <button class="button button--ghost" type="button" title="Delete image">
                                    <i class="icon icon-close"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <label class="button button--secondary--light input__upload">
                        <input type="file" class="hidden" name="images[]" accept="image/jpeg, image/png" multiple>
                        <i class="icon icon-makephoto" aria-label="Icon photo"></i>
                        <span>Загрузить</span>
                    </label>
                </div>
                <div class="form__input">
                    <button class="button button--primary--light form__submit" type="submit">Записаться</button>
                    <div class="p p--80 form__agree">
                        Нажимая кнопку, вы даете согласие на
                        <a class="button button--ghost" href="/polozhenie-o-khranenii-personalnykh-dannykh/" target="_blank">обработку персональных данных</a>
                        и принимаете
                        <a class="button button--ghost" href="/oferta/" target="_blank">правила продаж товаров через Интернет-магазин</a>
                    </div>
                </div>
                <p class="p p--80 p--r color-text--g-80">
                    Консультация лицам до 18 лет проводится в сопровождении родителей.
                </p>
            </form>
        </div>
    `;

    async function handleSubmit(event) {
        event.preventDefault();
        const form = event.target;
        const popupEl = contactsStepEl.closest(".popup");

        popupEl.classList.add("popup--loading");

        const formData = new FormData(form);

        const response = await API.yclients.postScheduleBranch(formData);

        const responseText = response.clone();

        try {
            const data = await response.json();
            if (data.success) {
                popupEl.classList.remove("popup--loading");
                notifyAboutSuccessAppointment(data.message);
                closeAppointmentPopup(serviceType);
            } else {
                notifyErrorWithText(data.message);
            }
        } catch (error) {
            const data = await responseText.text();

            const jsonStartIndex = data.indexOf("{");
            const jsonString = data.slice(jsonStartIndex);

            try {
                const responseObj = JSON.parse(jsonString);

                notifyErrorWithText(responseObj.message);
            } catch (e) {
                notifyErrorWithText("Что-то пошло не так");
            }
        }
        closeAppointmentPopup(serviceType);
    }

    const submitForm = contactsStepEl.querySelector(".form");

    submitForm.addEventListener("submit", handleSubmit);

    return contactsStepEl;
}

/**
 *
 * @param {{
 *  address:string,
 *  city: string,
 *  schedule: import("../../services/api/endpoints/yclients/index.js").ScheduleBranchItemSchedule[],
 *  serviceType:string
 *  date: string,
 *  employee: import("../../services/api/endpoints/yclients/index.js").ScheduleBranchEmployee
 * }} param0
*/
function generateTimeStep({
    address,
    city,
    schedule,
    serviceType,
    date,
    employee,
}) {
    const timeStepEl = document.createElement("div");

    timeStepEl.classList.add("appointment__step", "show");

    timeStepEl.id = `time_step-${serviceType}`;

    timeStepEl.innerHTML = `
        <div class="card card--entity">
            <div class="card__head">
                <h4 class="h5">Данные вашего визита</h4>
            </div>
            <div class="card__content">
                <div class="card__content-info">
                    <i class="icon icon-pin" aria-label="icon pin"></i>
                    ${serviceType === "online"
            ? "<p class=\"p\">Онлайн</p>"
            : `<p class="p">г. ${city}, ${address}</p>`
        }   
                </div>
                <div class="card__content-info">
                    <i class="icon icon-calendar" aria-label="icon calendar"></i>
                    <p class="p">${date}</p>
                </div>
                <div class="card__content-info">
                    <i class="icon icon-user" aria-label="icon user"></i>
                    <p class="p">${employee.name}</p>
                </div>
            </div>
        </div>
        <div class="step__content">
            <fieldset class="timeslots">
                <legend class="h6">Выберите время приёма</legend>
                ${schedule.map((scheduleItem) => (
            `
                            <div class="timeslot">
                                <input class="hidden" type="radio" name="timeslotOnline" id="time_step-${scheduleItem.time}" value="${scheduleItem.time}">
                                <label class="label button button--secondary--light" for="time_step-${scheduleItem.time}">${scheduleItem.time}</label>
                            </div>
                        `
        )).join(" ")
        }
        </fieldset>
        ${schedule.length <= 2 && yclientsCodes[serviceType].waitingEmployeeId !== undefined ? `
            <br/><button style="margin-top:12px;" class="appointment__waiting-list btn--S btn-primary-light">Записаться в лист ожидания</button>
            ` : ""
        }
        </div>
    `;

    function handleTimeClick(event) {
        event.preventDefault();

        const timeSlot = event.currentTarget;

        const timeInput = timeSlot.querySelector("input");

        const timeStr = timeInput.value;

        const contactsStepEl = generateContactsStep({
            address,
            city,
            schedule,
            serviceType,
            date,
            employee,
            time: timeStr,
        });

        timeStepEl.classList.remove("show");

        timeStepEl.parentNode.append(contactsStepEl);

        setDefaultInputListeners();

        initPhoneMask();

        initFileUploads();

        const popupEl = timeStepEl.closest(".popup");

        const oldBackButton = popupEl.querySelector(".popup__back-btn");

        const backButton = oldBackButton.cloneNode(true);

        const contactsIndicatorEl = popupEl.querySelector(`#step-indicator__button-contacts-${serviceType}`);

        contactsIndicatorEl.classList.add("selected");

        contactsIndicatorEl.previousElementSibling.classList.remove("selected");

        oldBackButton.replaceWith(backButton);

        backButton.addEventListener("click", () => {
            timeStepEl.classList.add("show");
            contactsStepEl.remove("show");

            contactsIndicatorEl.classList.remove("selected");

            contactsIndicatorEl.previousElementSibling.classList.add("selected");

            backButton.replaceWith(oldBackButton);
        });
    }

    const timeSlots = timeStepEl.querySelectorAll(".timeslot");

    timeSlots.forEach((timeSlot) => {
        timeSlot.addEventListener("click", handleTimeClick);
    });

    async function handleAppointmentWaitingList(event) {
        event.preventDefault();
        event.stopPropagation();
        const response = await API.yclients.getFreeWaitingListSlot(serviceType, date);

        const data = await response.json();

        const [responseDate, time] = data.datetime.split("T");

        const contactsStepEl = generateContactsStep({
            address,
            city,
            serviceType,
            date: responseDate,
            employee: {
                id: data.workerId,
            },
            time: time.slice(0, 5),
            isWaitingList: true,
        });
        timeStepEl.classList.remove("show");

        timeStepEl.parentNode.append(contactsStepEl);

        setDefaultInputListeners();

        initPhoneMask();

        initFileUploads();

        const popupEl = timeStepEl.closest(".popup");

        const oldBackButton = popupEl.querySelector(".popup__back-btn");

        const backButton = oldBackButton.cloneNode(true);

        const contactsIndicatorEl = popupEl.querySelector(`#step-indicator__button-contacts-${serviceType}`);

        contactsIndicatorEl.classList.add("selected");

        contactsIndicatorEl.previousElementSibling.classList.remove("selected");

        oldBackButton.replaceWith(backButton);

        backButton.addEventListener("click", () => {
            timeStepEl.classList.add("show");
            contactsStepEl.remove("show");

            contactsIndicatorEl.classList.remove("selected");

            contactsIndicatorEl.previousElementSibling.classList.add("selected");

            backButton.replaceWith(oldBackButton);
        });
    }

    const appointmentWaitingListButton = timeStepEl.querySelector(".appointment__waiting-list");

    if (appointmentWaitingListButton) {
        appointmentWaitingListButton.addEventListener("click", handleAppointmentWaitingList);
    }

    return timeStepEl;
}

/**
 *
 * @param {{
 *      address:string,
 *      city: string,
 *      schedule: import("../../services/api/endpoints/yclients/index.js").ScheduleBranch,
 *      serviceType:string
 *      date: string
 * }} param0
 */
function generateDoctorsStep({
    address,
    city,
    schedule,
    serviceType,
    date,
}) {
    const doctorsStepEl = document.createElement("div");

    doctorsStepEl.classList.add("appointment__step", "show");

    doctorsStepEl.id = `doctors_step-${serviceType}`;

    doctorsStepEl.innerHTML = `
    
        <div class="card card--entity">
            <div class="card__head">
                <h4 class="h5">Данные вашего визита</h4>
            </div>
            <div class="card__content">
                <div class="card__content-info">
                    <i class="icon icon-pin" aria-label="icon pin"></i>
                    ${serviceType === "online"
            ? "<p class=\"p\">Онлайн</p>"
            : `<p class="p">г. ${city}, ${address}</p>`
        }                    
                </div>
                <div class="card__content-info">
                    <i class="icon icon-calendar" aria-label="icon calendar"></i>
                    <p class="p">${date}</p>
                </div>
            </div>
        </div>
        <div class="step__content">
            <div class="doctors" role="radiogroup" aria-labelledby="doctors-appointment">
                <div id="any" class="doctors__item card card--image card--bordered"
                    role="radio" aria-checked="false" tabindex="0"
                    aria-labelledby="doctors-appointment-0" data-value="0">
                    <img class="card__image"
                        src="/img/upload/skin-center/photo-empty.svg" alt="Любой специалист">
                    <div class="card__content">
                        <h5 class="h5">Любой специалист</h5>

                    </div>
                </div>
                ${Object.entries(schedule.items).map(([key, item], index) => (
            `
                        <div id="${key}" class="doctors__item card card--image card--bordered"
                            role="radio" aria-checked="false" tabindex="${index}"
                            aria-labelledby="doctors-appointment-${index}" data-value="${index}">
                            <img class="card__image"
                                src="${item.employee.avatar_big}" alt="${item.employee.name}">
                            <div class="card__content">
                                <h5 class="h5">${item.employee.name}</h5>
                                <p class="p">Рейтинг:${item.employee.rating}</p>

                            </div>
                        </div>
                        `
        )).join(" ")
        }
                
            </div>
        </div>
    `;

    function handleDoctorClick(event) {
        const doctorEl = event.currentTarget;

        let item = null;

        if (doctorEl.id === "any") {
            // get random employee
            const employeeIds = Object.keys(schedule.items);
            item = schedule.items[
                employeeIds[
                Math.floor(Math.random() * employeeIds.length)
                ]
            ];
        } else {
            item = schedule.items[doctorEl.id];
        }

        const employee = item?.employee;

        const employeeSchedule = item?.schedule;

        if (!employee || !employeeSchedule) return;

        const timeStepEl = generateTimeStep({
            address,
            city,
            schedule: employeeSchedule,
            serviceType,
            employee,
            date,
        });

        doctorsStepEl.classList.remove("show");

        doctorsStepEl.parentNode.append(timeStepEl);

        const popupEl = doctorsStepEl.closest(".popup");

        const oldBackButton = popupEl.querySelector(".popup__back-btn");

        const backButton = oldBackButton.cloneNode(true);

        const timeIndicatorEl = popupEl.querySelector(`#step-indicator__button-time-${serviceType}`);

        timeIndicatorEl.classList.add("selected");

        timeIndicatorEl.previousElementSibling.classList.remove("selected");

        oldBackButton.replaceWith(backButton);

        backButton.addEventListener("click", () => {
            doctorsStepEl.classList.add("show");
            timeStepEl.remove("show");
            timeIndicatorEl.classList.remove("selected");

            timeIndicatorEl.previousElementSibling.classList.add("selected");

            backButton.replaceWith(oldBackButton);
        });
    }

    const doctorsEls = doctorsStepEl.querySelectorAll(".doctors__item");

    doctorsEls.forEach((doctorEl) => {
        doctorEl.addEventListener("click", handleDoctorClick);
    });

    return doctorsStepEl;
}

/**
 *
 * @param {{
 * city: string,
 * address:string,
 * phone:string,
 * workHours:string,
 * serviceType:string,
 * }} info
 * @returns
 */
export function generatePopUpBySkinCenterInfo(info) {
    const popupEl = document.createElement("div");
    popupEl.classList.add("popup", "popup--appointment");
    popupEl.id = `popup--appointment-${info.serviceType}`;
    popupEl.innerHTML = `

        <div class="popup__wrapper">
            <button class="button button--ghost--dark popup__close" type="button"
                    data-id="PopupOnlineAppointment"
                    title="Close popup">
                <i class="icon icon-close"></i>
            </button>
            <div class="popup__head">
                <button class="btn--M btn-ghost-light popup__back-btn"><i class="icon-arrow-left"></i><span>Назад</span></button>
                ${info.serviceType === "online"
            ? "<h3 class=\"h3\">Запись на онлайн консультацию</h3>"
            : "<h3 class=\"h3\">Запись на консультацию</h3>"
        }
            </div>
            <div class="popup__content appointment scrollbar">

                <ul class="step-indicator">
                    <li id="step-indicator__button-calendar-${info.serviceType}" class="step-indicator__button selected" data-target="0">
                        <button class="button button--ghost" type="button">
                            <span class="button__icon">
                                <i class="icon icon-calendar" aria-label="Icon calendar"></i>
                            </span>
                            <span class="text">Дата</span>
                        </button>
                    </li>
                    <li id="step-indicator__button-doctors-${info.serviceType}" class="step-indicator__button" data-target="1">
                        <button class="button button--ghost" type="button">
                            <span class="button__icon">
                                <i class="icon icon-user" aria-label="Icon user"></i>
                            </span>
                            <span class="text">Специалист</span>
                        </button>
                    </li>
                    <li id="step-indicator__button-time-${info.serviceType}" class="step-indicator__button" data-target="2">
                        <button class="button button--ghost" type="button">
                            <span class="button__icon">
                                <i class="icon icon-clock" aria-label="Icon clock"></i>
                            </span>
                            <span class="text">Время</span>
                        </button>
                    </li>
                    <li id="step-indicator__button-contacts-${info.serviceType}" class="step-indicator__button" data-target="3">
                        <button class="button button--ghost" type="button">
                            <span class="button__icon">
                                <i class="icon icon-phone" aria-label="Icon phone"></i>
                            </span>
                            <span class="text">Контакты</span>
                        </button>
                    </li>
                </ul>

                <div class="appointment__steps">
                
                    <!-- step date -->                    
                    <div id="calendar_step-${info.serviceType}" class="appointment__step step show" data-target="0">
                        <div class="card card--entity">
                            <div class="card__head">
                                <h4 class="h5">Данные вашего визита</h4>
                            </div>
                            <div class="card__content">
                                <div class="card__content-info">
                                    <i class="icon icon-pin" aria-label="icon pin"></i>
                                    ${info.serviceType === "online"
            ? "<p class=\"p\">Онлайн</p>"
            : `<p class="p">г. ${info.city}, ${info.address}</p>`
        }
                                    
                                </div>
                            </div>
                        </div>
                        <div style="height: unset;" class="notification notification--warning notification--fullwidth">
                            <div  class="notification__title">
                                Нет свободных мест<br/>
                                Вы можете позвонить или написать нам в WhatsApp, администратор внесёт вас в лист ожидания 
                                на удобную дату и предложит варианты, если какая-то запись не подтвердится
                                <br/>
                                Тел. <a href="tel:${info.phone}" >${info.phone}</a>
                                <br/><button style="margin-top:12px;" class="appointment__waiting-list btn--S btn-primary-light">Записаться в лист ожидания</button>
                            </div>
                        </div>
                        <div class="step__content">
                            <h4 class="h6">Выберите дату приёма</h4>
                            <div id="appointment__calendar-${info.serviceType}" class="appointment__calendar" data-for="appointment__calendar-selected-date-${info.serviceType}"></div>
                            <input type="hidden" id="appointment__calendar-selected-date-${info.serviceType}" name="selectedDate" value="">
                        </div>
                    </div>
                
                </div>
            </div>
            <div class="loader" style="width: 20px;height: 20px;margin: 0 auto;left: 50%;"></div>
            
        </div>
    `;

    const stepsEl = popupEl.querySelector(".appointment__steps");

    const backButton = popupEl.querySelector(".popup__back-btn");

    async function calendarDateChange(event) {
        const dateStr = event.target.value;

        const appointmentWaitingListButton = popupEl.querySelector(".appointment__waiting-list");

        async function handleAppointmentWaitingList(e) {
            e.preventDefault();

            const waitingListResponse = await API.yclients.getFreeWaitingListSlot(
                info.serviceType,
                dateStr,
            );

            const data = await waitingListResponse.json();

            const [date, time] = data.datetime.split("T");

            const contactsStepEl = generateContactsStep({
                address: info.address,
                city: info.city,
                serviceType: info.serviceType,
                date,
                employee: {
                    id: data.workerId,
                },
                time: time.slice(0, 5),
                isWaitingList: true,
            });
            stepsEl.append(contactsStepEl);

            setDefaultInputListeners();

            initPhoneMask();

            initFileUploads();

            const calendarStep = popupEl.querySelector(`#calendar_step-${info.serviceType}`);

            calendarStep.classList.remove("show");

            const clendarIndicatorEl = popupEl.querySelector(`#step-indicator__button-calendar-${info.serviceType}`);

            clendarIndicatorEl.classList.remove("selected");
            clendarIndicatorEl.nextElementSibling.nextElementSibling.nextElementSibling.classList.add("selected");

            backButton.addEventListener("click", () => {
                contactsStepEl.remove();
                clendarIndicatorEl.classList.add("selected");
                clendarIndicatorEl.nextElementSibling.nextElementSibling.nextElementSibling.classList.remove("selected");
                calendarStep.classList.add("show");
                backButton.style.display = null;
            });

            backButton.style.display = "flex";
        }

        if (yclientsCodes[info.serviceType].waitingEmployeeId === undefined && appointmentWaitingListButton) {
            appointmentWaitingListButton.remove();
        } else if (appointmentWaitingListButton) {
            const newAppointmentWaitingListButton = appointmentWaitingListButton.cloneNode(true);

            appointmentWaitingListButton.replaceWith(newAppointmentWaitingListButton);

            newAppointmentWaitingListButton.addEventListener("click", handleAppointmentWaitingList);
        }

        popupEl.classList.add("popup--loading");

        const response = await API.yclients.getScheduleBranch(info.serviceType, dateStr);

        const schedule = await response.json();

        popupEl.classList.remove("popup--loading");

        const storedDoctor = sessionStorage.getItem("doctor-appointment");

        let doctor = null;

        if (storedDoctor) {
            doctor = JSON.parse(storedDoctor);
        }

        if (!schedule.items) {
            popupEl.classList.add("popup--no-place");
        } else if (doctor && !Object.keys(schedule?.items)?.includes(doctor.doctor.id.toString())) {
            popupEl.classList.add("popup--no-place");
        } else {
            popupEl.classList.remove("popup--no-place");

            const calendarStep = popupEl.querySelector(`#calendar_step-${info.serviceType}`);

            const clendarIndicatorEl = popupEl.querySelector(`#step-indicator__button-calendar-${info.serviceType}`);

            const doctorsStepEl = generateDoctorsStep({
                address: info.address,
                city: info.city,
                schedule,
                date: dateStr,
                serviceType: info.serviceType,
            });

            stepsEl.append(doctorsStepEl);

            if (doctor) {
                const timeStepEl = generateTimeStep({
                    address: info.address,
                    city: info.city,
                    schedule: schedule.items[doctor.doctor.id].schedule,
                    date: dateStr,
                    serviceType: info.serviceType,
                    employee: doctor.doctor,
                });
                stepsEl.append(timeStepEl);

                doctorsStepEl.classList.remove("show");

                clendarIndicatorEl.classList.remove("selected");

                clendarIndicatorEl.nextElementSibling.nextElementSibling.classList.add("selected");

                backButton.addEventListener("click", () => {
                    timeStepEl.remove();
                    clendarIndicatorEl.classList.add("selected");
                    clendarIndicatorEl.nextElementSibling.nextElementSibling.classList.remove("selected");
                    calendarStep.classList.add("show");
                    backButton.style.display = null;
                });
            } else {
                clendarIndicatorEl.classList.remove("selected");

                clendarIndicatorEl.nextElementSibling.classList.add("selected");

                backButton.addEventListener("click", () => {
                    doctorsStepEl.remove();
                    clendarIndicatorEl.classList.add("selected");
                    clendarIndicatorEl.nextElementSibling.classList.remove("selected");
                    calendarStep.classList.add("show");
                    backButton.style.display = null;
                });
            }
            calendarStep.classList.remove("show");

            backButton.style.display = "flex";
        }
    }

    const closeButton = popupEl.querySelector(".popup__close");

    closeButton.addEventListener("click", () => closeAppointmentPopup(info.serviceType));

    const calendarInputEl = popupEl.querySelector(`#appointment__calendar-selected-date-${info.serviceType}`);

    calendarInputEl.addEventListener("change", calendarDateChange);

    return popupEl;
}
