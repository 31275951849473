import Cookies from "js-cookie";

import { createCodeVerification } from "../../../code-verificaton/index.js";

const signUpModal = document.querySelector(".modal.sign-up");

function byCustomer() {
    const signUpContent = signUpModal.querySelector(".modal-center__content");

    const byCustomerForm = signUpModal.querySelector(".sign-up__customer");

    const phoneInput = byCustomerForm.querySelector("input[name='phone']");

    const phoneField = phoneInput.parentElement.parentElement;

    let codeVerification = null;

    let isAlreadyRegistered = false;

    function correctPhone() {
        delete phoneField.dataset.error;
    }

    function wrongPhone() {
        phoneField.dataset.error = "Телефон указан неверно";
    }

    function resetSignUpModal() {
        signUpModal.classList.remove("sign-up--next");
        signUpModal.classList.remove("sign-up--already");

        if (codeVerification) codeVerification.remove();
    }

    function alreadyRegistered() {
        isAlreadyRegistered = true;

        signUpModal.classList.add("sign-up--already");

        const titleEl = codeVerification.querySelector(".code-verification__title");

        const codeVerificationForm = codeVerification.querySelector(".code-verification__form");

        const codeVerificationText = codeVerification.querySelector(".code-verification__text");

        const footerEl = codeVerification.querySelector(".code-verification__footer");

        const infoblockButton = codeVerification.querySelector(".infoblock__button").cloneNode(true);

        infoblockButton.addEventListener("click", resetSignUpModal);

        codeVerification.insertBefore(infoblockButton, codeVerificationForm);

        codeVerificationText.innerText = "Номер телефона уже зарегистрирован";

        codeVerificationText.classList.add("sign-up__hint");

        codeVerificationText.classList.remove("code-verification__text");

        titleEl.innerText = "Вы уже зарегистрированы";

        const enterButton = document.createElement("button");

        enterButton.classList.add("btn--M", "btn-primary-light", "code-verification__button");
        enterButton.addEventListener("click", window.openSignIn);
        enterButton.style.display = "flex";
        enterButton.innerText = "Войти";

        footerEl.innerHTML = "";

        footerEl.append(enterButton);
    }

    function checkNumber() {
        if (Cookies.get("phone") === phoneInput.value) {
            alreadyRegistered();
        }
    }

    function codeVerified() {
        if (isAlreadyRegistered) return;

        if (codeVerification) codeVerification.remove();

        resetSignUpModal();

        window.closeModals();
        window.openModal("#loyalty-program");
    }

    function codeVerificationStart() {
        signUpModal.classList.add("sign-up--next");

        codeVerification = createCodeVerification({
            code: "0000",
            beforeSuccess: checkNumber,
            onCodeVerified: codeVerified,
            onReset: resetSignUpModal,
            title: "Подтверждение телефона",
            successText: "Регистрация прошла успешно",
            number: phoneInput.value,
        });

        signUpContent.append(codeVerification);
    }

    function getCodeSubmit(event) {
        event.preventDefault();
        const phone = phoneInput.value.replace(/\s/g, "");

        const isPhoneValid = phone.length === 12;

        if (!isPhoneValid) wrongPhone();
        else {
            correctPhone();
            codeVerificationStart();
        }
    }

    byCustomerForm.addEventListener("submit", getCodeSubmit);
}

function byCompany() {
    const signUpContent = signUpModal.querySelector(".modal-center__content");

    const byCompanyForm = signUpModal.querySelector(".sign-up__company");

    const phoneInput = byCompanyForm.querySelector("input[name='phone']");

    const phoneField = phoneInput.parentElement.parentElement;

    const emailInput = byCompanyForm.querySelector("input[type='email']");

    let codeVerification = null;

    function correctPhone() {
        delete phoneField.dataset.error;
    }

    function wrongPhone() {
        phoneField.dataset.error = "Телефон указан неверно";
    }

    function resetSignUpModal() {
        signUpModal.classList.remove("sign-up--next");
        signUpModal.classList.remove("sign-up--already");

        if (codeVerification) codeVerification.remove();
    }

    function codeVerified() {
        if (codeVerification) codeVerification.remove();

        resetSignUpModal();

        window.closeModals();
        window.openModal("#loyalty-program");
    }

    function codeVerificationStart() {
        signUpModal.classList.add("sign-up--next");

        codeVerification = createCodeVerification({
            code: "0000",
            onCodeVerified: codeVerified,
            onReset: resetSignUpModal,
            title: "Подтверждение e-mail",
            successText: "Регистрация прошла успешно",
            email: emailInput.value,
        });

        signUpContent.append(codeVerification);
    }

    function getCodeSubmit(event) {
        event.preventDefault();
        const phone = phoneInput.value.replace(/\s/g, "");

        const isPhoneValid = phone.length === 12;

        if (!isPhoneValid) wrongPhone();
        else {
            correctPhone();
            codeVerificationStart();
        }
    }

    //byCompanyForm.addEventListener("submit", getCodeSubmit);
}
export function initSignUp() {
    if (!signUpModal) return;

    byCustomer();

    byCompany();
}
