export function initModalPersonInfo() {
    console.log("initModalPersonInfo");

    // Функция для открытия модального окна конкретного сотрудника
    function openEmployeeModal(employeeId) {
        // Находим модальное окно
        const modal = document.getElementById("leadership-modal-person-info");

        // Находим все карточки сотрудников внутри модального окна
        const employeeCards = modal.querySelectorAll(".employees-modal-card");

        if (!modal || !employeeCards) return;

        // Скрываем все карточки
        employeeCards.forEach((card) => {
            card.classList.remove("employees-modal-card__open");
        });

        // Находим и показываем карточку с нужным id
        const targetCard = modal.querySelector(
            `#employee-card-modal-${employeeId}`
        );
        if (targetCard) {
            targetCard.classList.add("employees-modal-card__open");

            // Открываем модальное окно
            modal.classList.add("modal-person-info__open");
        }
    }

    // Функция для закрытия модального окна
    function closeEmployeeModal() {
        const modal = document.getElementById("leadership-modal-person-info");
        if (!modal) return;

        modal.classList.remove("modal-person-info__open");

        // Находим все карточки сотрудников и скрываем их
        const employeeCards = modal.querySelectorAll(".employees-modal-card");
        if (!employeeCards) return;
        employeeCards.forEach((card) => {
            card.classList.remove("employees-modal-card__open");
        });
    }

    const employeeCards = document.querySelectorAll(".employees-card");
    // Добавляем обработчики событий для открытия модального окна
    if (!employeeCards) return;
    employeeCards.forEach((card) => {
        card.addEventListener("click", function () {
            const employeeId = this.getAttribute("data-employee");
            openEmployeeModal(employeeId);
        });
    });

    const closeBtns = document.querySelectorAll(".employees-modal-card__close");
    if (!closeBtns) return;
    // Добавляем обработчик события для закрытия модального окна
    closeBtns.forEach((closeBtn) => {
        closeBtn.addEventListener("click", closeEmployeeModal);
    });

    const overlay = document.getElementById("leadership-modal-person-info");
    if (!overlay) return;
    // Закрытие модального окна при клике вне карточки
    overlay.addEventListener("click", function (event) {
        if (event.target === this) {
            closeEmployeeModal();
        }
    });
}

