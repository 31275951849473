/**
 * @typedef {{
*      name: string,
*      detail:string,
*      products: Product[],
* }} AdaptedTestResult
*/

import { getCatalogWithoutCategories } from "../../modules/dynamic-api-test/catalog/getCatalogWithoutCategories.js";

export function getCatalogHTML(catalog) {
    const section = document.createElement("section");

    section.classList.add("section", "skintest__recommend");

    const container = document.createElement("div");

    container.classList.add("container");

    section.append(container);

    const sectionWrapper = document.createElement("div");

    sectionWrapper.classList.add("section__wrapper");

    container.append(sectionWrapper);

    const catalogHTML = getCatalogWithoutCategories(catalog);

    sectionWrapper.innerHTML = catalogHTML;

    return section;
}
