import { SearchCardCounter } from "./counter.js";

const searchCards = document.querySelectorAll(".search-card");

export function initSearchCard(searchCard) {
    if (!searchCard) return;

    const counterEl = searchCard.querySelector(".search-card__counter");

    if (counterEl) {
        const counter = new SearchCardCounter(searchCard);
        const toCartButton = searchCard.querySelector(".search-card__add-to-cart");

        toCartButton.addEventListener("click", counter.open);
    }

    const subscribeButton = searchCard.querySelector(".search-card__subscribe");

    if (subscribeButton) {
        subscribeButton.addEventListener("click", () => {
            window.openModal("#subscribe-product");
        });
    }
}

export function initSearchCards() {
    searchCards.forEach(initSearchCard);
}
