/* eslint-disable indent */
import Swiper from "swiper";
import { getCityCenterCoordinates } from "../../../modules/ymap/city-coordinates.js";
import { BaseYMap } from "../../../modules/ymap/index.js";
import { API } from "../../../services/api/endpoints/index.js";
import { yclientsCodes } from "../../../services/api/endpoints/yclients/codes.js";
import { initTabList } from "../../../ui/tabs/index.js";
import { executeScriptsInElement, htmlDecode } from "../../../utils/html-decode/index.js";
import { generateCenterCardByCenterInfo } from "../center-card.js";
import { generateDoctorCardHTML } from "../doctor.js";
import { generatePopUpBySkinCenterInfo, openAppointmentPopup } from "../popup.js";
import { generateReview, generateReviewsSectionByFeature } from "../review.js";
import { parseCenterInfoFromHTMLStr } from "../util.js";
import { generateGallerySlidesByFeature } from "./gallery.js";
import { setSkinDiagnostictCenterTitles } from "./page-title.js";

const main = document.querySelector("main");

function initMap(feature) {
    const mapContainer = main.querySelector("#map");

    if (!mapContainer) return;

    const map = new BaseYMap(mapContainer, {
        center: getCityCenterCoordinates(feature.city).reverse(),
        zoom: 11,
    });

    const coordinates = feature.geometry.coordinates.reverse();

    const content = {
        name: feature.properties.balloonContentHeader,
        content: feature.properties.balloonContentBody,
    };

    map.addMark(coordinates, content);
}

function initConsultationCards(serviceType) {
    const cardWrapper = main.querySelector(".card-wrapper");

    if (!cardWrapper) return;

    cardWrapper.innerHTML = `
    <div class="card record-card catalog-cosmetics-consultation__record card--image">
        <img src="/img/upload/record-1.png" alt="record-1" class="record-card__image">
        <div class="record-card__content">
            <button class="button button-open-popup button--ghost--light" type="button" data-appointment="${serviceType}">
                <span class="p">Записаться очно в центр диагностики кожи</span>
                <i class="icon-arrow-right" aria-label="icon arrow right"></i>
            </button>
        </div>
    </div>
    <div class="card record-card catalog-cosmetics-consultation__record card--image">
        <img src="/img/upload/record-2.png" alt="record-2" class="record-card__image">
        <div class="record-card__content">
            <button class="button button-open-popup button--ghost--light" type="button" data-appointment="online">
                <span class="p">Записаться на онлайн-консультацию</span>
                <i class="icon-arrow-right" aria-label="icon arrow right"></i>
            </button>
        </div>
    </div>
    `;

    const serviceOpenAppoitmentButton = cardWrapper.querySelector(`button[data-appointment="${serviceType}"]`);

    if (serviceOpenAppoitmentButton) {
        serviceOpenAppoitmentButton.addEventListener("click", () => openAppointmentPopup(serviceType));
    }

    const popup = generatePopUpBySkinCenterInfo({
        address: "",
        phone: "+7-985-840-08-12",
        city: "",
        companyId: yclientsCodes.online.companyId,
        serviceId: yclientsCodes.online.serviceId,
        serviceType: "online",
        workHours: "",
    });

    main.append(popup);

    const onlineOpenAppoitmentButton = cardWrapper.querySelector("button[data-appointment='online']");

    if (onlineOpenAppoitmentButton) {
        onlineOpenAppoitmentButton.addEventListener("click", () => openAppointmentPopup("online"));
    }
}

// function initReviews(reviews) {
//     const reviewList = main.querySelector(".reviews__list");

//     if (!reviewList) return;

//     reviews.forEach((review) => {
//         const reviewEl = generateReview(review);

//         reviewList.append(reviewEl);
//     });
// }

function initPhotosSliders() {
    return new Swiper(".js-slider-photos-list", {
        spaceBetween: 8,
        slidesPerView: "auto",
        navigation: {
            prevEl: ".js-slider-photos-slider .slider-navigation-prev",
            nextEl: ".js-slider-photos-slider .slider-navigation-next",
        },
        breakpoints: {
            992: {
                slidesPerView: 2,
                spaceBetween: 24,
                allowTouchMove: true,
            },
        },
    });
}

export async function initSkinDiagnosticCenterCity(serviceType) {
    if (!main) return;

    const loaderWrapper = document.createElement("div");

    loaderWrapper.style.width = "50wh";
    loaderWrapper.style.height = "50vh";
    loaderWrapper.style.display = "flex";
    loaderWrapper.style.alignItems = "center";
    loaderWrapper.style.justifyContent = "center";

    const loader = document.createElement("div");
    loader.classList.add("loader");
    loader.style.width = "32px";
    loader.style.height = "32px";

    loaderWrapper.append(loader);

    const mainContent = main.innerHTML;

    main.innerHTML = "";

    main.append(loaderWrapper);

    const response = await API.map.getCdkMap();

    const { points: { features } } = await response.json();

    const feature = features.find((f) => {
        const info = parseCenterInfoFromHTMLStr(f.properties.balloonContentBody);
        return info.serviceType === serviceType;
    });

    main.innerHTML = mainContent;

    setSkinDiagnostictCenterTitles(feature.city);

    const centerInfo = feature
        ? {
            ...parseCenterInfoFromHTMLStr(feature.properties.balloonContentBody),
            city: feature.city,
        }
        : null;

    const galleryEl = main.querySelector(".slider.js-slider-photos-slider .slider-wrapper");

    if (galleryEl) {
        const slidesHTML = generateGallerySlidesByFeature(feature);

        galleryEl.innerHTML = slidesHTML;
        initPhotosSliders();
    }

    const centerCardEl = main.querySelector(".center__card");

    if (centerCardEl) {
        const card = generateCenterCardByCenterInfo({
            ...centerInfo,
            address: feature.address,
            phone: feature.phone,
            image: feature.image,
            workHours: feature.worktime,
            serviceType,
            city: feature.city,
            metro: feature.metro,
            withLink: false,
        });
        centerCardEl.replaceWith(card);

        initMap(feature);

        initConsultationCards(serviceType);

        // initReviews(Object.values(Object.values(schedule.items)[0].reviews).slice(0, 3));
    }

    const scheduleResponse = await API.yclients.getScheduleBranch(serviceType);

    const schedule = await scheduleResponse.json();

    const doctorsListEl = document.querySelector(".js-slider-doctors-list .swiper-wrapper");

    if (!doctorsListEl) return;

    if (!schedule.items) {
        doctorsListEl.closest(".section").remove();
        return;
    }

    Object.values(schedule.items).forEach((item) => {
        const employee = item.employee;

        const reviews = item.reviews;

        const doctorCard = generateDoctorCardHTML({
            doctor: employee,
            city: feature.city,
            address: feature.address,
            serviceType,
            reviews,
        });

        doctorsListEl.prepend(doctorCard);
    });

    const reviews = generateReviewsSectionByFeature(feature);

    if (reviews) {
        const tabItem = reviews.querySelector(".tabs__tab-list-item, .tabs__tab-list-item--selected");

        if (!tabItem) return;

        main.append(reviews);

        executeScriptsInElement(reviews);

        initTabList(reviews);
    }
}
