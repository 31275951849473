/* eslint-disable indent */
import { initTabIndicator, initTabList } from "../../ui/tabs/index.js";
import { openAppointmentPopup } from "./popup.js";
import { generateReview } from "./review.js";

const main = document.querySelector("main");

function closeAllDoctorPopups() {
    const popups = document.querySelectorAll(".popup--doctor");

    popups.forEach((popup) => {
        popup.classList.remove("open");
    });
}

export function closeDoctorPopup(doctorId) {
    const popup = document.getElementById(`popup--doctor-${doctorId}`);

    if (popup) {
        popup.classList.remove("open");
    }
}

export async function openDoctorPopup(doctorId) {
    closeAllDoctorPopups();

    const popup = document.getElementById(`popup--doctor-${doctorId}`);

    if (popup) {
        popup.classList.add("open");
    }
}

function generateDoctorPopupHTML({
    doctor, city, address, serviceType, reviews, appointment = true,
}) {
    if (!main) return null;

    const imageSrc = doctor?.avatar_big;

    let addressStr = "";

    if (city === "online") {
        addressStr = "Онлайн-консультации";
    } else if (city) {
        addressStr += `г. ${city}`;
    }

    if (city && address) {
        addressStr += `, ${address}`;
    }
    const popupEl = document.createElement("div");

    popupEl.classList.add("popup", "popup--doctor");

    popupEl.id = `popup--doctor-${doctor.id}`;

    popupEl.innerHTML = `
            <div class="popup__wrapper doctor">
                <button class="button button--ghost--dark popup__close" type="button">
                    <i class="icon icon-close"></i>
                </button>
                <div class="popup__head">
                    <h3 class="h3">${doctor.name}</h3>
                </div>
                <div class="popup__content scrollbar">
                    <div class="tabs">
                        <div class="tabs__tab-list-wrapper">
                            <ul class="tabs__tab-list">
                                <li 
                                    data-value="0" 
                                    class="tabs__tab-list-item--selected"
                                    ${!reviews ? 'style="width:100%"' : ""}
                                >
                                    <button class="tabs__tab-button" type="button">Информация</button>
                                </li>
                                ${reviews ? `
                                        <li data-value="1" class="tabs__tab-list-item">
                                            <button class="tabs__tab-button" type="button">Отзывы</button>
                                        </li>
                                    ` : ""
        }
                            </ul>
                            <i class="tabs__indicator"></i>
                        </div>
                        <ul class="tabs__tab-panel-list">
                            <li data-value="0" class="tabs__tab-panel-item doctor__card card card--entity">
                                <div class="doctor__photo card__image">
                                    <img src="${imageSrc}" alt="${doctor.name}">
                                </div>
                                <div class="doctor__info card__content">
                                    <p class="card__content-info p">
                                    ${doctor.specialization ? doctor.specialization : ""}</p>
                                    ${addressStr ? `
                                        <div class="card__content-info">
                                            <i class="icon icon-pin" aria-label="icon pin"></i>
                                            <p class="p">${addressStr}</p>
                                        </div>
                                        ` : ""
                                    }
                                    ${doctor.description ? `
                                        <div class="card__content-text">
                                            <p class="p">${doctor.description}</p>
                                        </div>
                                        ` : ""
        }
                                </div>
                            </li>
                            <li data-value="1" class="tabs__tab-panel-item doctor__reviews">
                                <div class="reviews__list">
                                    <!-- отзывы -->
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="popup__bottom">
                    ${appointment ? `
                            <button class="button button--primary--light button-open-popup button-open-popup--appointment"
                            type="button" >
                                Записаться
                            </button>
                        ` : ""
        }
                </div>
            </div>
    `;

    const closeButton = popupEl.querySelector(".popup__close");

    closeButton.addEventListener("click", () => closeDoctorPopup(doctor.id));

    if (reviews) {
        const reviewList = popupEl.querySelector(".reviews__list");

        Object.values(reviews).forEach((review) => {
            if (review.master_id === doctor.id) {
                const reviewEl = generateReview(review);

                reviewList.append(reviewEl);
            }
        });
    }

    const tabs = popupEl.querySelector(".tabs");

    initTabList(tabs);
    window.addEventListener("resize", () => initTabIndicator(tabs));

    if (appointment) {
        const appointmentButton = popupEl.querySelector(".button-open-popup--appointment");

        sessionStorage.removeItem("doctor-appointment");

        appointmentButton.addEventListener("click", () => {
            closeAllDoctorPopups();
            openAppointmentPopup(serviceType);

            sessionStorage.setItem("doctor-appointment", JSON.stringify({
                serviceType,
                doctor,
            }));
        });
    }

    return popupEl;
}

export function generateDoctorCardHTML({
    doctor, city, address, serviceType, reviews, appointment = true,
}) {
    const imageSrc = doctor?.avatar_big;

    let addressStr = "";

    if (city === "online") {
        addressStr = "Онлайн-консультации";
    } else if (city) {
        addressStr += `г. ${city}`;
    }

    if (city && address) {
        addressStr += `, ${address}`;
    }

    const card = document.createElement("div");

    const descriptionEl = document.createElement("p");

    descriptionEl.innerHTML = doctor.description ? doctor.description : "";

    const description = descriptionEl.innerText;

    card.classList.add("card", "card--bordered", "card--entity", "card--center", "swiper-slide", "slider-slide", "js-slider-doctors-slide");
    card.innerHTML = `
            <picture class="card__image">
                <img src="${imageSrc}" alt="${doctor.name}">
            </picture>
            <div class="card__content">
                
                <div class="card__content-info">
                    <h4 class="h4">${doctor.name}</h4>
                </div>
                
                ${description ? `
                    <div class="card__content-info">
                        <p class="p p--ellipse">${description}</p>
                    </div>
                    ` : ""
                }
                
                ${addressStr ? `
                <div class="card__content-info">
                    <i class="icon icon-pin" aria-label="icon pin"></i>
                    <p class="p">${addressStr}</p>
                </div>
                ` : ""
                }
                <div class="card__content-buttons">
                    ${appointment ? `
                            <button class="button button--primary--light button-open-popup button-open-popup--appointment" type="button">
                                Записаться
                            </button>
                        ` : ""
        }
                    <button class="button button--secondary--light button-open-popup button-open-popup--doctor" type="button" data-id="PopupDoctor">
                        Информация о специалисте
                    </button>
                </div>
            </div>
    `;

    const popupEl = generateDoctorPopupHTML({
        doctor,
        city,
        address,
        serviceType,
        reviews,
        appointment,
    });

    if (popupEl) {
        main.append(popupEl);

        const openDoctorInfoButton = card.querySelector(".button-open-popup--doctor");

        openDoctorInfoButton.addEventListener("click", () => openDoctorPopup(doctor.id));
    }

    if (appointment) {
        const appointmentButton = card.querySelector(".button-open-popup--appointment");

        appointmentButton.addEventListener("click", () => {
            openAppointmentPopup(serviceType);
            sessionStorage.setItem("doctor-appointment", JSON.stringify({
                serviceType,
                doctor,
            }));
        });
    }

    return card;
}
