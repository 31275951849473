import Swiper from "swiper";
import { initMap } from "./map/map.js";

/**
 *
 * @param {HTMLElement} slider element with class "cities-slider"
 */
function initSlider(slider) {
    const id = slider.id;

    return new Swiper(`#${id}`, {
        slidesPerView: "auto",
        slideClass: "cities-slider__item",
        navigation: {
            prevEl: `#${id} .cities-slider-arrows-left`,
            nextEl: `#${id} .cities-slider-arrows-right`,
        },
        breakpoints: {
            992: {
                allowTouchMove: true,
            },
        },
    });
}

function initCitiesSliders() {
    const sliders = document.querySelectorAll(".cities-slider");
    if (!sliders.length) return;

    sliders.forEach((slider, index) => {
        slider.id = `cities-slider-${index}`;
        initSlider(slider);
    });
}

/**
 *
 * @param {Element} tabs element with class "tabs"
 */
function showCurrentTabPanel(tabs) {
    const tabList = tabs.querySelector(".tabs__tab-list");

    const tabPanelList = tabs.querySelector(".tabs__tab-panel-list");

    if (!tabPanelList) return;

    const tabPanels = tabPanelList.querySelectorAll(".tabs__tab-panel-item, .tabs__tab-panel-item--show");

    const currentTab = tabList.querySelector(".tabs__tab-list-item--selected");

    if (!currentTab) return;

    const currentTabValue = currentTab.dataset.value;

    const currentTabPanel = tabPanelList.querySelector(".tabs__tab-panel-item--show");

    if (currentTabPanel) {
        const currentTabPanelValue = currentTabPanel.dataset.value;

        if (currentTabValue === currentTabPanelValue) return;

        currentTabPanel.classList.replace("tabs__tab-panel-item--show", "tabs__tab-panel-item");
    }

    tabPanels.forEach((tabPanel) => {
        const tabPanelValue = tabPanel.dataset.value;

        if (!tabPanelValue) throw new Error("tabs__tab-panel-item must contain data-value attr");

        if (tabPanelValue === currentTabValue) {
            tabPanel.classList.replace("tabs__tab-panel-item", "tabs__tab-panel-item--show");
        }
    });
}

/**
 *
 * @param {MouseEvent} event
 */
function onTabClick(event) {
    event.stopPropagation();

    const targetTab = event.currentTarget;

    const tabList = targetTab.parentNode;

    const tabs = tabList.parentNode.parentNode;

    const currentTab = tabList.querySelector(".tabs__tab-list-item--selected");

    if (!targetTab.dataset.value) throw new Error("tab must have data-value attr");

    tabList.dataset.value = targetTab.dataset.value;

    if (currentTab) {
        if (targetTab.dataset.value === currentTab.dataset.value) return;

        currentTab.classList.replace("tabs__tab-list-item--selected", "tabs__tab-list-item");
    }

    targetTab.classList.replace("tabs__tab-list-item", "tabs__tab-list-item--selected");

    showCurrentTabPanel(tabs);
}

function initTabList(tabs) {
    if (!tabs) return;

    const tabList = tabs.querySelector(".tabs__tab-list");

    const tabListItems = tabList.querySelectorAll(".tabs__tab-list-item, .tabs__tab-list-item--selected");

    tabListItems.forEach((item, index) => {
        const tabCityCenter = item.dataset.center.split(",").map(Number).reverse();

        const tabsChangeEvent = new CustomEvent("city-change", {
            detail: {
                center: tabCityCenter,
                city: item.dataset.city,
            },
        });

        if (index === 0) {
            tabs.dispatchEvent(tabsChangeEvent);
        }

        item.addEventListener("click", (event) => {
            onTabClick(event);
            tabs.dispatchEvent(tabsChangeEvent);
        });
    });

    showCurrentTabPanel(tabs);
}

export async function initMapSliderWithCitiesTabs({
    endpoint,
    className,
    beforeTabsInit,
}) {
    const container = document.querySelector(`.${className}`);

    if (!container) return null;

    const tabs = document.querySelectorAll(".cities-tabs");

    const geoPoints = await initMap({
        endpoint,
        containerClass: className,
        beforeTabsInit,
    });

    tabs.forEach(initTabList);
    initCitiesSliders();

    return geoPoints;
}
