/* eslint-disable indent */
import { generatePopUpBySkinCenterInfo, openAppointmentPopup } from "./popup.js";

const main = document.querySelector("main");


/**
 * 
 * @param {{
 * address: string,
 * phone: string,
 * serviceType: string,
 * href: string,
 * city: string,
 * image: string,
 * withLink: boolean,
 * workHours: {period: string, time: string}[]
 * metro:string[]
 * }} param0 
 * @returns {HTMLElement}
 */
export function generateCenterCardByCenterInfo({
    address,
    phone,
    workHours,
    serviceType,
    href,
    city,
    image,
    metro,
    withLink = true,
}) {
    const popup = generatePopUpBySkinCenterInfo({
        address,
        phone,
        city,
        serviceType,
        workHours,
    });

    main.append(popup);

    const card = document.createElement("div");

    card.classList.add("center__card");

    card.dataset.serviceType = serviceType;

    card.innerHTML = `
        <div class="card card--bordered card--entity card--center">

            <picture class="card__image">
                <img src="${image || "https://html.v2024.geltek.ru/img/upload/history-history-2016.png"}" alt="Geltek">
            </picture>

            <div class="card__content">
                
                <div class="card__content-info">
                    <i class="icon icon-pin" aria-label="icon pin"></i>
                    <p class="p">г. ${city}, ${address}</p>
                </div>
                
                ${
                    metro ? metro.map((metroStation) => (`
                    <div class="card__content-info">
                        <i class="icon icon-metro" aria-label="icon pin"></i>
                        <p class="p">
                            ${metroStation}
                        </p>
                    </div>
                    `)).join(" ") : ""
                }

                <div class="card__content-info card__content-phone">
                    <i class="icon icon-phone" aria-label="icon phone"></i>
                    <p class="p">
                        <a href="tel:${phone}">${phone}</a>
                        <a href="https://wa.me/${phone.replaceAll("-", "").replace("+", "")}"><i class="icon icon-wa"></i></a>
                    </p>
                </div>
                
                
                ${
                    workHours ? `
                    <div class="card__content-info card__content-worktime">
                        <i class="icon icon-clock" aria-label="icon clock"></i>
                        <p class="p">${workHours?.map((workHour) => (
                            `<span> ${workHour.period} ${workHour.time}</span>`
                        )).join(" ")}
                        </p>
                    </div>
                    ` : ""
                }
                
                <div class="card__content-buttons">
                    
                    <button data-appointment="${serviceType}" class="button button--primary--light" type="button">
                        Записаться на диагностику бесплатно
                    </button>
                    
                    
                    ${
                        withLink ? `
                        <a  class="button button--secondary--light" href="?address=${serviceType}">
                            Подробнее о центре
                        </a>
                        ` : ""
                    }
                    
                </div>
            </div>
        </div>         
    `;

    const cardAppointmentButton = card.querySelector(`[data-appointment="${serviceType}"]`);

    cardAppointmentButton.addEventListener("click", () => openAppointmentPopup(serviceType));

    return card;
}
