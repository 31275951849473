const imageFiles = [];
function initFileDelete(file) {
    imageFiles.splice(file.dataset.id, 1);
    file.remove();
    console.log(imageFiles);
}
function initFileUpload(input) {
    const inputItem = input.querySelector("input[type='file']");
    const inputFiles = input.querySelector(".input__file-list");
    const inputUploading = input.querySelector(".input__uploading");
    const imagePreview = inputFiles.querySelector(".input__file").cloneNode(true);

    inputItem.onchange = (event) => {
        const files = event.target.files;

        if (!files) return;
        inputFiles.innerHTML = "";
        inputUploading.classList.add("show");

        const filesLength = files.length > 5 ? 5 : files.length;

        for (let i = 0; i < filesLength; i++) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const ip = imagePreview.cloneNode(true);
                ip.dataset.id = i;
                console.log(files[i]);
                console.log(reader);

                let res = reader.result;
                
                if (files[i].type == "application/pdf") {
                    res = "pdf";
                }

                ip.querySelector("img").src = res !== "pdf" ? res : "/local/templates/geltek2024/frontend/img/icons/icon-pdf.svg";
                ip.querySelector("img").alt = res;
                ip.querySelector("button").onclick = () => {
                    initFileDelete(ip);
                };
                inputFiles.appendChild(ip);
            };
            reader.readAsDataURL(files[i]);
            // Store img file
            imageFiles.push(files[i]);
        }

        inputFiles.classList.add("show");
        setTimeout(() => {
            inputUploading.classList.remove("show");
        }, 800);
    };
}
export function initFileUploads() {
    const fileInputs = document.querySelectorAll(".input--file");

    fileInputs.forEach((input) => {
        initFileUpload(input);
    });
}

export const ImageFiles = imageFiles;
