export const initDownloadModal = () => {
    const modal = document.getElementById("profile-dowland");
    if (!modal) return;

    const input = modal.querySelector('.js-upload');
    const inputTwo = modal.querySelector('.js-upload-two');
    const form = modal.querySelector('.js-form');
    const start = form.querySelector('.js-start');
    const finish = form.querySelector('.js-finish');
    const loader = form.querySelector('.js-loader');
    const imagePreview = finish.querySelector('.img-preview');

    const handleFileChange = function () {
        start.style.display = "none";
        finish.style.display = "none";
        loader.style.display = "flex";

        setTimeout(() => {
            const file = this.files[0];

            if (file) {
                const reader = new FileReader();

                reader.onload = function (event) {
                    imagePreview.src = event.target.result;
                };

                reader.readAsDataURL(file);
            }
            loader.style.display = "none";
            finish.style.display = "flex";
        }, 800);
    };

    input.addEventListener('change', handleFileChange);
    inputTwo.addEventListener('change', handleFileChange);
};
