export function stars() {
    const stars = document.querySelectorAll('.stars');

    if (!stars.length) return;

    stars.forEach(starsContainer => {
        const starItems = starsContainer.querySelectorAll('.stars__star');
        const valueKeeper = starsContainer.querySelector('.stars__star-value');

        starItems.forEach(star => {
            star.addEventListener('click', () => {
                const value = star.getAttribute('data-value');
                // вот это надо только чтобы при выходе из модалки можно было вернуть предыдущее значение
                valueKeeper.setAttribute('data-prev-value', valueKeeper.value);

                valueKeeper.value = value;

                starItems.forEach(s => {
                    s.classList.remove('selected');
                });

                star.classList.add('selected');
            })
        })
    })
}