/**
 * @typedef {{
 *      id: string,
 *      title: string,
 *      detailPageUrl: string,
 *      imageUrl: string,
 *      price: number,
 *      fullprice: number,
 *      addToCartUrl: string,
 *      marketplaces: {
 *          [key: string]: {
 *              title: string,
 *              link: string,
 *          }
 *      }
 * }} Product
 */

/**
 * @typedef {{
 *      count: number,
 *      count_current: number,
 *      state: string,
 *      name: string,
 *      detail: string,
 *      recom: {
 *          morning: {
 *              title: string,
 *              categories: {[key: string]: string[]}
 *          },
 *          evening: {
 *              title: string,
 *              categories: {[key: string]: string[]}
 *           },
 *          intensive: []
 *          all: {
 *              title: string,
 *              categories: {[key: string]: string[]}
 *           },
 *      },
 *      products: {
 *          [key: number]: Product
 *      }
 * }} TestResultWithCategories
 */

/**
 * @typedef {{
*      count: number,
*      count_current: number,
*      state: string,
*      name: string,
*      detail: string,
*      recom: string[],
*      products: {
*          [key: number]: Product
*     }
* }} TestResult
*/

/**
 * @typedef {{
 *      name: string,
 *      morning: {
 *          title: string,
 *          categories: {
 *              [key: string]: {id:number, products: Product[]}
 *          }
 *      },
 *      evening: {
 *          title: string,
 *          categories: {
 *              [key: string]: {id:number, products: Product[]}
 *          }
 *      },
 *      all: {
 *          title: string,
 *          categories: {
 *              [key: string]: {id:number, products: Product[]}
 *          }
 *      }
 * }} AdaptedTestResultWithCategories
 */

/**
 *
 * @param {TestResultWithCategories} testResult
 * @returns {AdaptedTestResultWithCategories}
 */
export function adaptTestResultWithCatgeories(testResult) {
    const result = {};

    result.name = testResult.name;

    result.morning = {
        title: testResult.recom.morning.title,
        categories: {},
    };

    result.evening = {
        title: testResult.recom.evening.title,
        categories: {},
    };

    result.all = {
        title: testResult.recom.all.title,
        categories: {},
    };

    Object.entries(testResult.recom.morning.categories).forEach(([key, value], index) => {
        result.morning.categories[key] = {
            id: index,
            products: [],
        };
        value.forEach((v) => {
            if (testResult.products[v]) {
                result.morning.categories[key].products.push(testResult.products[v]);
            }
        });
    });

    Object.entries(testResult.recom.evening.categories).forEach(([key, value], index) => {
        result.evening.categories[key] = {
            id: index,
            products: [],
        };
        value.forEach((v) => {
            if (testResult.products[v]) {
                result.evening.categories[key].products.push(testResult.products[v]);
            }
        });
    });

    Object.entries(testResult.recom.all.categories).forEach(([key, value], index) => {
        result.all.categories[key] = {
            id: index,
            products: [],
        };
        value.forEach((v) => {
            if (testResult.products[v]) {
                result.all.categories[key].products.push(testResult.products[v]);
            }
        });
    });

    return result;
}

/**
 * @typedef {{
*      name: string,
*      detail:string,
*      products: Product[],
* }} AdaptedTestResult
*/

/**
 * 
 * @param {TestResult} testResult
 * @returns {AdaptedTestResult}
 */
export function adaptTestResult(testResult) {
    const result = {};

    result.name = testResult.name;

    result.detail = testResult.detail;

    result.products = [];

    testResult.recom.forEach((id) => {
        if (testResult.products[id]) {
            result.products.push(testResult.products[id]);
        }
    });

    return result;
}
