import Swiper from "swiper";
import { Autoplay, Pagination } from "swiper/modules";

export function initClinicBanner() {
    const el = document.querySelector(".clinic-banner-2025__slider");

    if (!el) return false;

    const paginationFrame = el.querySelector('.clinic-banner-2025__pagination-frame')

    const swiper = new Swiper(el.querySelector(".swiper"), {
        // modules: [Autoplay, Pagination],
        modules: [Pagination],
        // autoplay: {
        //     delay: 15000,
        //     disableOnInteraction: false,
        // },
        loop: true,
        slidesPerView: 1,
        pagination: {
            el: el.querySelector(".swiper-pagination"),
            clickable: true,
        },
        on: {
            afterInit: function() {
                updatePaginationPosition();
            },
            slideChange: function() {
                updatePaginationPosition();
            }
        }
    });

    function updatePaginationPosition() {
        const activeDot = el.querySelector('.swiper-pagination-bullet-active');
        if (activeDot && paginationFrame) {
            paginationFrame.style.left = activeDot.getBoundingClientRect().left + 'px';
        }
    }

    return swiper;
}
