/* eslint-disable no-use-before-define */
import { Timer } from "easytimer.js";
import { defaultOnInputChange } from "../../ui/input/index.js";

/**
 *
 * @param {HTMLElement} codeVerificationEl
 * @param {string} code
 * @param {(() => void) | undefined} onCodeVerified
 * @param {((value:string) => void) | undefined} onInput
 * @param {boolean} isEmail
 */
export function initCodeVerification(codeVerificationEl, onCodeVerified = undefined, onInput = undefined, isEmail = false) {
    if (!codeVerificationEl.classList.contains("code-verification")) return;

    const inputs = codeVerificationEl.querySelectorAll("input");

    const resendButton = codeVerificationEl.querySelector(".code-verification__button");

    const form = codeVerificationEl.querySelector(".code-verification__form");

    const continueButton = codeVerificationEl.querySelector(".code-verification__email-button");

    const emailField = codeVerificationEl.querySelector(".code-verification__email-field");

    const timer = new Timer();

    let errorCount = 1;

    const MAX_TRIES_COUNT = 5;

    function handleFocus(event) {
        if (event.target.value) {
            event.target.select();
        }
    }

    function removeCountDown() {
        errorCount = 1;

        codeVerificationEl.classList.add("code-verification--resend");
        codeVerificationEl.classList.remove("code-verification--loading");
        codeVerificationEl.classList.remove("code-verification--timer");
        codeVerificationEl.classList.remove("code-verification--count-down");

        if (continueButton) continueButton.disabled = false;
    }

    function countDown() {
        codeVerificationEl.classList.remove("code-verification--resend");
        codeVerificationEl.classList.remove("code-verification--loading");
        codeVerificationEl.classList.add("code-verification--timer");
        codeVerificationEl.classList.add("code-verification--count-down");
        timer.stop();

        initTimer(3600, removeCountDown);
    }

    // function checkCode() {
    //     const codeValue = [...inputs].map((i) => i.value).join("");
        
    //     switch (true) {
    //         case codeValue === code:
    //             if (errorCount < MAX_TRIES_COUNT) {
    //                 if (!isEmail) delete form.dataset.error;
    //                 else delete emailField.dataset.errorl;

    //                 timer.stop();
    //                 codeVerificationEl.classList.add("code-verification--loading");
    //                 codeVerificationEl.classList.remove("code-verification--timer");
    //                 codeVerificationEl.classList.remove("code-verification--resend");

    //                 setTimeout(() => {
    //                     if (onCodeVerified) onCodeVerified();
    //                 }, 1000);
    //             }
    //             break;

    //         case codeValue.length === code.length && codeValue !== code:
    //             if (!isEmail) form.dataset.error = "Неверный код";
    //             else emailField.dataset.error = "Неверный код";

    //             if (errorCount < MAX_TRIES_COUNT) {
    //                 errorCount += 1;
    //             } else {
    //                 countDown();

    //                 if (continueButton) continueButton.disabled = true;
    //             }

    //             break;

    //         default:
    //             if (!isEmail) delete form.dataset.error;
    //             else delete emailField.dataset.errorl;
    //             break;
    //     }
    // }

    function handleInput(event) {
        const input = event.target;

        const codeValue = [...inputs].map((i) => i.value).join("");

        const inputField = input.parentElement.parentElement;

        const nextInputField = inputField.nextElementSibling;

        if (input.value) {
            if (nextInputField && nextInputField.classList.contains("input")) {
                nextInputField.querySelector(".code-verification__input").focus();
            }
        }

        // if (!isEmail) checkCode();

        if (onInput) {
            onInput(codeValue);
        }
    }

    function handleKeyDown({ key, target }) {
        if (key !== "Backspace") return;

        const input = target;

        input.value = "";

        const inputField = input.parentElement.parentElement;

        const previousInputField = inputField.previousElementSibling;

        if (previousInputField) {
            previousInputField.querySelector(".code-verification__input").focus();
        }
    }

    function initTimer(seconds, cb) {
        const timerEl = codeVerificationEl.querySelector(".code-verification__timer");

        timer.start({ countdown: true, startValues: { seconds } });

        timerEl.innerText = timer.getTimeValues().toString(["minutes", "seconds"]);

        timer.addEventListener("secondsUpdated", () => {
            timerEl.innerText = timer.getTimeValues().toString(["minutes", "seconds"]);
        });

        timer.addEventListener("targetAchieved", () => {
            codeVerificationEl.classList.remove("code-verification--timer");
            codeVerificationEl.classList.add("code-verification--resend");
            if (cb) cb();
        });
    }

    function resendCode() {
        codeVerificationEl.classList.remove("code-verification--timer");
        codeVerificationEl.classList.remove("code-verification--resend");
        codeVerificationEl.classList.add("code-verification--loading");

        setTimeout(() => {
            codeVerificationEl.classList.remove("code-verification--loading");
            codeVerificationEl.classList.add("code-verification--timer");
            initTimer(60);
        }, 2000);
    }

    inputs.forEach((input) => {
        input.setAttribute("value", input.value);
        input.addEventListener("change", defaultOnInputChange);
        input.addEventListener("input", handleInput);
        input.addEventListener("focusn", handleFocus);
        input.addEventListener("keydown", handleKeyDown);
    });

    if (codeVerificationEl.classList.contains("code-verification--timer")) {
        initTimer(60);
    }

    resendButton.addEventListener("click", resendCode);

    // if (isEmail && continueButton) form.addEventListener("submit", checkCode);
}

/**
 *
 * @param {{
 * code: string,
 * onCodeVerified?: () => void,
 * beforeSuccess?: () => void,
 * onInput?: () => void,
 * onReset?: () => void,
 * title?: string,
 * successText?: string,
 * number?: string,
 * email?:string,
 * }} param0
 * @returns
 */
export function createCodeVerification({
    onCodeVerified,
    onInput,
    onReset,
    title,
    successText,
    beforeSuccess,
    number,
    email,
}) {
    const codeVerification = document.createElement("div");

    codeVerification.classList.add("code-verification", "code-verification--timer");

    function createInfoBlock() {
        const infoblock = document.createElement("div");

        if (number) {
			let newNumber = number.replace(/\s/g, "").match(/(\+7{1})(\d{3})(\d{3})(\d{2})(\d{2})/);

            newNumber = `${newNumber[1]} (${newNumber[2]})-${newNumber[3]}-${newNumber[4]}-${newNumber[5]}`;
            infoblock.classList.add("infoblock");

            infoblock.innerHTML = `
            <div class="infoblock__content">
                <h6 class="infoblock__title">СМС-код отправлен на указанный номер</h6>
                <p class="p infoblock__text phoneNumberCheck" data-phone='${number}'>
                    ${newNumber}
                </p>            
                <button class="btn--M btn-ghost-light infoblock__button">
                    Изменить
                </button>
            </div>
        `.trim();

            const infoblockButton = infoblock.querySelector(".infoblock__button");

            if (onReset) infoblockButton.addEventListener("click", onReset);
        } else if (email) {
            infoblock.classList.add("code-verification__email-hint");

            infoblock.innerText = `На ${email} было отправлено письмо с кодом для восстановления доступа к профилю. Код действует 20 минут.`;
        }

        return infoblock;
    }

    function createBackButton() {
        const backButton = document.createElement("button");

        backButton.classList.add("btn--M", "btn-ghost-light", "code-verification__back-btn");

        const backButtonIcon = document.createElement("i");

        backButtonIcon.classList.add("icon-arrow-left");

        const backButtonSpan = document.createElement("span");

        backButtonSpan.innerText = "Назад";

        backButton.append(backButtonIcon, backButtonSpan);

        backButton.addEventListener("click", onReset);

        return backButton;
    }

    function onSuccess() {
        if (beforeSuccess) beforeSuccess();

        if (successText) {
            codeVerification.classList.remove("code-verification--timer", "code-verification--loading", "code-verification--resend");
            codeVerification.classList.add("code-verification--success");

            const hint = document.createElement("p");
            hint.classList.add("p--r", "sign-in__hint", "code-verification__success-hint");
            hint.innerText = successText;

            codeVerification.append(hint);
        }

        if (onCodeVerified) setTimeout(onCodeVerified, 2000); // server delay
    }

    if (number) {
        codeVerification.innerHTML = `
        <div class="code-verification__header">
            <h4 class="h4 code-verification__title"></h4>
        </div>
        <div class="code-verification__infoblock-wrapper"></div>
        <p class="p--100 code-verification__text">Код из СМС</p>
        <div class="code-verification__form">
            <div class="input input--text">
                <label for="" class="label code-verification__label">
                    <input type="text" inputmode="numeric" class="code-verification__input" maxLength="1">
                </label>
            </div>
            <div class="input input--text">
                <label for="" class="label code-verification__label">
                    <input type="text" inputmode="numeric" class="code-verification__input" maxLength="1">
                </label>
            </div>
            <div class="input input--text">
                <label for="" class="label code-verification__label">
                    <input type="text" inputmode="numeric" class="code-verification__input" maxLength="1">
                </label>
            </div>
            <div class="input input--text">
                <label for="" class="label code-verification__label">
                    <input type="text" inputmode="numeric" class="code-verification__input" maxLength="1">
                </label>
            </div>
        </div>
        <div class="notification notification--info notification--fullwidth code-verification__info">
            <div class="notification__title code-verification__info-title">
                Превышено максимальное количество запросов. Попробуйте позднее.
            </div>
        </div>
        <div class="code-verification__footer">
            <div class="code-verification__hint">Отправить код повторно через <span
                    class="code-verification__timer"></span></div>
            <button class="btn--M btn-primary-light code-verification__button">Отправить код повторно</button>
            <div class="loader loader--M"></div>
        </div>
    `;
    } else if (email) {
        codeVerification.innerHTML = `
            <div class="code-verification__header">
            <h4 class="h4 code-verification__title"></h4>
            </div>
            <div class="code-verification__infoblock-wrapper"></div>
            <form class="code-verification__form code-verification__form--email">
                <div class="input input--text code-verification__email-field">
                    <label for="" class="label">
                        <input type="text" class="code-verification__email-field-input" minlength="4" maxLength="4" required>
                        <span class="p">Код из e-mail</span>
                    </label>
                </div>
                <button class="btn--M btn-primary-light code-verification__email-button">Продолжить</button>
            </form>
            <div class="code-verification__footer">
                <div class="code-verification__hint">Отправить код повторно через <span
                        class="code-verification__timer"></span></div>
                <button class="btn--M btn-secondary-light code-verification__button">Отправить код повторно</button>
                <div class="loader loader--M"></div>
            </div>
            <div class="notification notification--info notification--fullwidth code-verification__info">
                <div class="notification__title code-verification__info-title">
                    Превышено максимальное количество запросов. Попробуйте позднее.
                </div>
            </div>
        `;
    } else {
        codeVerification.innerHTML = "";
    }

    initCodeVerification(codeVerification, onSuccess, onInput, email !== undefined);

    const backButton = createBackButton();

    const infoblock = createInfoBlock();

    const infoblockWrapper = codeVerification.querySelector(".code-verification__infoblock-wrapper");

    infoblockWrapper.append(infoblock);

    const headerEl = codeVerification.querySelector(".code-verification__header");

    if (title) {
        const titleEl = headerEl.querySelector(".code-verification__title");

        titleEl.innerText = title;
    }

    headerEl.prepend(backButton);

    return codeVerification;
}

export function initCodeVerifications() {
    const elements = document.querySelectorAll(".code-verification");

    elements.forEach(initCodeVerification);
}
