const userBirthday = '05.03.1997';


export const initDetails = () => {
  let form = document.querySelector('.js-profile-details-form');
  if (!form) return;
  const birthdayWrapper = document.querySelector('.profile-details__birthday');
  let input = birthdayWrapper.querySelector('.js-input');
  let info = birthdayWrapper.querySelector('.profile-section-info');

  input.addEventListener('blur', (e) => {

    if (e.target.value === userBirthday) {
      info.classList.remove("profile-hidden");
    } else {
      info.classList.add("profile-hidden");
    }
  });


  form.addEventListener('submit', function (event) {
    event.preventDefault(); // Предотвращаем отправку формы

    const inputs = this.querySelectorAll('input[type="text"]');
    const textValues = {};


    inputs.forEach(input => {
      textValues[input.name] = input.value ? input.value : null;
    });
    input.reportValidity();
    const selectedGender = this.querySelector('input[name="sex"]:checked');

    console.log(textValues);

    textValues.sex = selectedGender ? selectedGender.value : null;
  });


};