import { notifyAboutMasterClassSubscription, notifyAboutReserveMasterClassSubscription } from "../notifications/index.js";

/**
 *
 * @param {HTMLElement} masterClassWebinar
 */
function initMasterClassWebinar(masterClassWebinar, id) {
    function initMasterClassWebinarDate() {
        const dateEl = masterClassWebinar.querySelector(".master-class-webinar__date");

        const dateNumberEl = dateEl.querySelector(".master-class-webinar__date-number");

        const monthNameEl = dateEl.querySelector(".master-class-webinar__date-month-name");

        const weekdayEl = dateEl.querySelector(".master-class-webinar__date-weekday");

        const dateLocal = new Date(dateEl.getAttribute("data-date")).toLocaleString("ru", {
            day: "numeric",
            month: "long",
            weekday: "short",
        });

        const [weekday, number, monthName] = dateLocal.split(/, | /);

        dateNumberEl.innerText = number;

        monthNameEl.innerText = monthName;

        weekdayEl.innerText = weekday;
    }

    function initMasterClassWebinarDetailModal() {
        const modalEl = masterClassWebinar.querySelector(".modal.master-class-webinar__detail");

        const webinarMoreBtn = masterClassWebinar.querySelector(".master-class-webinar__more");

        const dateEl = masterClassWebinar.querySelector(".master-class-webinar__detail-date");

        const dateLocal = new Date(dateEl.getAttribute("data-date")).toLocaleString("ru", {
            day: "numeric",
            month: "long",
            year: "numeric",
        });

        const [number, monthName, year] = dateLocal.split(/, | /);

        dateEl.innerText = `${number} ${monthName} ${year}`;

        modalEl.id = id;

        webinarMoreBtn.addEventListener("click", () => window.openModal(`#${id}`));
    }

    function initMasterClassSubscribeModal() {
        const modalEl = masterClassWebinar.querySelector(".modal.master-class-webinar__subscribe");

        if (!modalEl) return;

        const form = modalEl.querySelector(".master-class-webinar__subscribe-form");

        const subscribeButton = masterClassWebinar.querySelectorAll(".master-class-webinar__button-subscribe");

        const submitSubscribeButton = modalEl.querySelector("button[type='submit']");

        const dateEl = modalEl.querySelector(".master-class-webinar__detail-date");

        const dateLocal = new Date(dateEl.getAttribute("data-date")).toLocaleString("ru", {
            day: "numeric",
            month: "long",
            year: "numeric",
        });

        const [number, monthName, year] = dateLocal.split(/, | /);

        dateEl.innerText = `${number} ${monthName} ${year}`;

        modalEl.id = `subscribe-${id}`;

        form.id = `form-${id}`;

        submitSubscribeButton.setAttribute("form", `form-${id}`);

        form.addEventListener("submit", (event) => {
            event.preventDefault();
            window.closeModals();
            if (modalEl.classList.contains("master-class-webinar__subscribe--no-places")) {
                notifyAboutReserveMasterClassSubscription();
            } else {
                notifyAboutMasterClassSubscription();
            }
        });

        subscribeButton.forEach((btn) => {
            btn.addEventListener("click", () => window.openModal(`#subscribe-${id}`));
        });
    }

    initMasterClassWebinarDate();
    initMasterClassWebinarDetailModal();
    initMasterClassSubscribeModal();
}

/**
 *
 * @param {HTMLElement} webinar
 */
function initPastWebinar(webinar) {
    const dateEl = webinar.querySelector(".past-webinar__date");

    if (!dateEl) return;

    const dateLocal = new Date(dateEl.getAttribute("data-date")).toLocaleString("ru", {
        day: "numeric",
        month: "long",
        year: "numeric",
    });

    const [number, monthName, year] = dateLocal.split(/, | /);

    dateEl.innerText = `${number} ${monthName} ${year}`;
}

/**
 *
 * @param {HTMLElement} button
 */
function initShowMoreButton(button) {
    const webinarList = button.parentElement;

    const MAX_COUNT_ON_PAGE = 9;

    const webinars = webinarList.querySelectorAll(".webinars-tabs__webinar");

    function onShowMoreClick() {
        webinars.forEach((webinar) => {
            webinar.style.display = "flex";
        });

        button.style.display = "none";
    }

    if (MAX_COUNT_ON_PAGE >= webinars.length) {
        button.style.display = "none";
    }

    const hiddenCount = webinars.length - MAX_COUNT_ON_PAGE;

    button.innerText = `Показать еще ${hiddenCount} вебинаров`;

    button.addEventListener("click", onShowMoreClick);
}

export function initWebinars() {
    const masterClassesWebinars = document.querySelectorAll(".master-class-webinar");

    const pastWebinars = document.querySelectorAll(".past-webinar");

    const showMoreButtons = document.querySelectorAll(".webinars-tabs__more");

    masterClassesWebinars.forEach((webinar, index) => {
        initMasterClassWebinar(webinar, `master-class-webinar-${index}`);
    });

    pastWebinars.forEach(initPastWebinar);

    showMoreButtons.forEach(initShowMoreButton);
}
