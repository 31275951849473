import clamp from "clamp-js";

/**
     *
     * @param {HTMLElement} titleEl
     */
function clampTitle(titleEl) {
    const maxTextLength = 52;
    const text = titleEl.innerText.replace(/\n/g, "").trim();

    if (text.length > maxTextLength) {
        const words = text.split(" ");
        let textLength = 0;
        let wordIndex = 0;

        while (textLength < maxTextLength && wordIndex < words.length - 1) {
            textLength += words[wordIndex].length + 1;
            wordIndex += 1;
        }
        const wordToBreakIndex = wordIndex - 1;

        const wordToBreakEl = document.createElement("span");
        wordToBreakEl.style.wordBreak = "break-all";
        wordToBreakEl.innerText = words[wordToBreakIndex];
        titleEl.innerHTML = words.map((word, index) => {
            if (index === wordToBreakIndex) {
                return wordToBreakEl.outerHTML;
            }
            return word;
        }).join(" ");
    }
}

export function initProductTitle(titleEl) {
    clampTitle(titleEl);

    function mediaChange(mediaQuery) {
        if (mediaQuery.matches) {
            clamp(titleEl, { clamp: 2 });
        } else {
            clamp(titleEl, { clamp: 3 });
        }
    }

    const mediaQuery = window.matchMedia("(min-width: 992px)");
    mediaQuery.addEventListener("change", mediaChange);
    mediaChange(mediaQuery);
}
