import Swiper from "swiper";

export function initOurLinesSlider() {
    // eslint-disable-next-line no-undef
    return new Swiper(".our-lines__cards-slider", {
        slidesPerView: "auto",
        spaceBetween: 16,
        slideClass: "our-lines__cards-slider-card-wrapper",
        navigation: {
            prevEl: ".our-lines__cards-slider-list-arrows-left",
            nextEl: ".our-lines__cards-slider-list-arrows-right",
        },

        breakpoints: {
            576: {
                slidesPerView: 3,
                spaceBetween: 16,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 16,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 24,
            },
            1250: {
                slidesPerView: 4,
                spaceBetween: 24,
            },
        },
    });
}
