import { closeAccordion } from "../../ui/accordion/index.js";
import { ProductCardCounter } from "./product-card-counter.js";
import { initProductTitle } from "./clamp-title.js";
import clamp from "clamp-js";

export function initProductCards() {
    const productCards = document.querySelectorAll(".product-card");
    let lastShowedMore = null;

    function handleShowMore(productCardEl) {
        const showMoreAccordion = productCardEl.querySelector(".product-card__content-collapsed-buttons");

        const isOpening = showMoreAccordion.classList.contains("accordion--open");

        if (!lastShowedMore && isOpening) {
            productCardEl.classList.add("product-card--showed-more");

            lastShowedMore = productCardEl;
        } else if (isOpening) {
            productCardEl.classList.add("product-card--showed-more");

            const lastShowedMoreAccordion = lastShowedMore.querySelector(".product-card__content-collapsed-buttons");

            closeAccordion(lastShowedMoreAccordion, true, () => {
                lastShowedMore.classList.remove("product-card--showed-more");
                lastShowedMore = productCardEl;
            });
        } else {
            const productCardElAccordion = productCardEl.querySelector(".product-card__content-collapsed-buttons");

            closeAccordion(productCardElAccordion, true, () => {
                productCardEl.classList.remove("product-card--showed-more");
                lastShowedMore = null;
            });
        }
    }

    function handleFavorite(productCardEl) {
        const heartIcon = productCardEl.querySelector(".product-card__header-heart i");

        if (heartIcon.classList.contains("icon-heart")) {
            heartIcon.classList.replace("icon-heart", "icon-heart-filled");
        } else if (heartIcon.classList.contains("icon-heart-filled")) {
            heartIcon.classList.replace("icon-heart-filled", "icon-heart");
        }
    }

    /**
     *
     * @param {HTMLLIElement} productCardEl
     */
    function initProductCard(productCardEl) {
        const wrapper = productCardEl.querySelector(".product-card__wrapper");

        const titleEl = productCardEl.querySelector(".product-card__content-heading-title");

        const description = productCardEl.querySelector(".product-card__content-heading-description");

        const favoriteButton = productCardEl.querySelector(".product-card__header-heart");

        const showMoreButton = productCardEl.querySelector(".product-card__content-buttons-more");

        const collapsedButtonsWrapper = productCardEl.querySelector(".product-card__content-collapsed-buttons-wrapper");

        const toCartButton = productCardEl.querySelector(".product-card__content-buttons-to-cart");

        function mediaChange(mediaQuery) {
            if (wrapper) {
                productCardEl.style.minHeight = `${wrapper.scrollHeight}px`;
            }
        }

        if (wrapper) {
            const mediaQuery = window.matchMedia("(min-width: 992px)");
            mediaQuery.addEventListener("change", mediaChange);
            mediaChange(mediaQuery);
        }

        if (titleEl) initProductTitle(titleEl);

        if (description) {
            clamp(description, {clamp: 5})
        }

        const counterEl = productCardEl.querySelector(".counter");

        if (counterEl && toCartButton) {
            const counter = new ProductCardCounter(productCardEl);

            toCartButton.addEventListener("click", counter.open);
        }

        if (showMoreButton) {
            showMoreButton.addEventListener("click", () => handleShowMore(productCardEl));
            collapsedButtonsWrapper.style.maxHeight = 0;
        }

        if (favoriteButton) {
            favoriteButton.addEventListener("click", () => handleFavorite(productCardEl));
        }
    }

    productCards.forEach(initProductCard);
}
