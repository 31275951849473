import { notifyAboutProductSubscription } from "../../notifications/index.js";

export function initProductSubscribeModal() {
    const modal = document.querySelector(".subscribe-product");
    if (!modal) return;
    const subscribeForm = modal.querySelector(".subscribe-product__subscribe-form");
    subscribeForm.addEventListener("submit", (event) => {
        event.preventDefault();
        window.closeModals();
        notifyAboutProductSubscription();
    });
}
