/* eslint-disable indent */

import { htmlDecode } from "../../utils/html-decode/index.js";

/**
 * 
 * @param {import("../../services/api/endpoints/yclients").Review} review
 */
export function generateReview(review) {
    const reviewEl = document.createElement("div");

    reviewEl.classList.add("review");

    const avatarType = review.user_avatar ? 'image' : 'letter';

    const avatarContent = review.user_avatar 
        ? `<img src="${review.user_avatar}" alt="${review.user_name}">`
        : `<p class="p">${review.user_name.charAt(0).toUpperCase()}</p>`;

    const stars = Array(review.rating).fill('<i class="icon icon-star-filled" aria-label="Filled star"></i>').join('');

    const emptyStars = Array(5 - review.rating).fill('<i class="icon icon-star-framed" aria-label="Empty star"></i>').join('');

    reviewEl.innerHTML = `
        <div class="review">
            <div class="review__head">
                <div class="review__author">
                    <div class="review__author-avatar">
                        ${avatarContent}
                    </div>
                    <h6 class="h6">${review.user_name}</h6>
                    <div class="review__rating rating--stars">
                        ${stars}${emptyStars}
                    </div>
                </div>
                <p class="p review__date">${new Date(review.date).toLocaleDateString()}</p>
            </div>
            <div class="review__text">
                <p class="p">${review.text}</p>
                <button class="button button--ghost" type="button">ещё</button>
            </div>
        </div>
    `;

    return reviewEl;
}

export function generateReviewsSectionByFeature(feature) {
    const reviews = document.createElement("section");

    reviews.classList.add("container", "section--reviews");

    reviews.innerHTML = `
        <h1 class="h1">Отзывы о филиале</h1>
        <div class="tabs">
            <div class="tabs__tab-list-wrapper">
                <ul class="tabs__tab-list">
                    ${
                        Object.entries(feature.reviews).map(([key, value], i) => (
                            value ? `<li data-value="${i}" class="tabs__tab-list-item${i === 0 ? "--selected" : ""}">
                                <button class="tabs__tab-button">
                                    ${key}
                                </button>
                            </li>` : ""
                        )).join(" ")
                    }
                </ul>
                <i class="tabs__indicator"></i>
            </div>
        
            <ul class="tabs__tab-panel-list">

                ${
                    Object.entries(feature.reviews).map(([key, value], i) => (
                        value ?`<li data-value="${i}" class="tabs__tab-panel-item">
                            ${htmlDecode(value.TEXT)}
                        </li>` : ""
                    )).join(" ")
                }
            </ul>
        </div>
    `;

    return reviews;
}
