import { initMapSliderWithCitiesTabs } from "../../modules/map-slider-with-cities-tabs/index.js";
import { API } from "../../services/api/endpoints/index.js";
import { initTabsCards } from "./tabs.js";

const tabsWrapper = document.querySelector(".skin-diagnostic-center__map-tabs .cities-tabs");

export async function initMap() {
    if (!tabsWrapper) return null;

    const geoPoints = await initMapSliderWithCitiesTabs({
        endpoint: API.map.getCdkMap,
        className: "skin-diagnostic-center__map-tabs",
        beforeTabsInit: initTabsCards,
    });

    return geoPoints;
}
