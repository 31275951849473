/* eslint-disable indent */
import { initMap } from "../tabs-map.js";
import { API } from "../../../services/api/endpoints/index.js";
import { generateDoctorCardHTML } from "../doctor.js";
import { parseCenterInfoFromHTMLStr } from "../util.js";
import { openAppointmentPopup } from "../popup.js";
import { initMiniatures } from "./miniatures.js";
import { notifyErrorWithText } from "../../../modules/notifications/index.js";

/**
 *
 * @param {{
        address: string,
        phone: string,
        workHours: string,
        serviceType: string,
        href: string,
        city: string
    }[]} cities
 */
function generateChooseCityAppointmentPopup(cities) {
    const modal = document.createElement("div");

    modal.classList.add("modal");

    modal.id = "appointment-choose";

    modal.innerHTML = `
        <div data-close-modal class="modal__overlay"></div>
        <div class="modal__inner modal-center__inner">
            <button class="modal__close modal-center__close" data-close-modal>
                <i class="modal__close-icon icon-close"></i>
            </button>
            <div class="modal-center__header">
                <h4 class="h4 modal-center__title">
                    Выберите в какой филиал вы хотите записаться
                </h4>
            </div>


            <div class="modal-center__content">
                <div class="appointment-choose__buttons">
                    ${cities.map((cityInfo) => (
        `<button data-appointment="${cityInfo.serviceType}" class="appointment-choose__button btn--M btn-secondary-light">
                                ${cityInfo.city}
                            </button>`
    )).join(" ")
        }
                </div>
            </div>
        </div>
    `;

    const buttons = modal.querySelectorAll("[data-appointment]");

    const closeButtons = modal.querySelectorAll("[data-close-modal");

    closeButtons.forEach((closeButton) => {
        closeButton.addEventListener("click", () => window.closeModals());
    });

    buttons.forEach((button) => {
        button.addEventListener("click", () => {
            window.closeModals();
            openAppointmentPopup(button.dataset.appointment);
        });
    });

    return modal;
}

export async function initSkinDiagnosticCenterCities(isOnline = false) {
    const main = document.querySelector(".skin-diagnostic-center.skin-diagnostic-center--cities");

    initMiniatures();

    if (isOnline) {
        const newTitleText = "Бесплатная онлайн консультация";

        const title = main.querySelector(".section-about__content .h2");

        title.innerText = newTitleText;

        const aboutText = main.querySelector(".section-about__text");

        const offlineAppointment = main.querySelector(".section--offline-appointment");

        const card = offlineAppointment.querySelector(".card:nth-child(2)");

        const breadcrumbLastItem = main.querySelector(".breadcrumbs__item:last-child");

        const onlineServiceLinkHref = "/skin-diagnostic-center/online/";

        const onlineServiceLinkEl = document.createElement("a");

        onlineServiceLinkEl.href = onlineServiceLinkHref;

        onlineServiceLinkEl.innerText = newTitleText;

        const toast = main.querySelector(".section--appointment .card--toast");

        toast.style.display = "none";

        breadcrumbLastItem.innerHTML = "";

        breadcrumbLastItem.append(onlineServiceLinkEl);

        aboutText.replaceWith(card);

        offlineAppointment.parentNode.style.display = "none";
    }

    try {
        const { points: { features } } = await initMap();

        const doctorsListEl = document.querySelector(".js-slider-doctors-list .swiper-wrapper");

        if (!doctorsListEl) return;

        const citiesInfo = [];

        const doctorsCards = [];

        if (isOnline) {
            const scheduleResponse = await API.yclients.getScheduleBranch(
                "online",
            );

            const schedule = await scheduleResponse.json();

            if (!schedule.items) {
                doctorsListEl.closest(".section").remove();
                return;
            }

            Object.values(schedule.items).forEach((item) => {
                const employee = item.employee;

                const reviews = item.reviews;

                const doctorCard = generateDoctorCardHTML({
                    doctor: employee,
                    serviceType: "online",
                    reviews,
                    city: "online",
                });

                if (!doctorsCards.find((card) => card.doctor.id === employee.id)) {
                    doctorsCards.push({ doctor: employee, card: doctorCard });
                }
            });
        } else {
            await Promise.all(features.map(async (feature) => {
                const centerInfo = {
                    ...parseCenterInfoFromHTMLStr(feature.properties.balloonContentBody),
                    city: feature.city,
                };

                citiesInfo.push(centerInfo);

                try {
                    const scheduleResponse = await API.yclients.getScheduleBranch(
                        centerInfo.serviceType,
                    );

                    const schedule = await scheduleResponse.json();

                    if (!schedule.items) {
                        doctorsListEl.closest(".section").remove();
                        return;
                    }

                    Object.values(schedule.items).forEach((item) => {
                        const employee = item.employee;

                        const reviews = item.reviews;

                        const doctorCard = generateDoctorCardHTML({
                            doctor: employee,
                            city: feature.city,
                            address: centerInfo.address,
                            serviceType: centerInfo.serviceType,
                            reviews,
                        });

                        if (!doctorsCards.find((card) => card.doctor.id === employee.id)) {
                            doctorsCards.push({ doctor: employee, card: doctorCard });
                        }
                    });
                } catch (error) {
                    notifyErrorWithText(error.message);
                }
            }));

            const scheduleResponse = await API.yclients.getScheduleBranch(
                "online",
            );

            const schedule = await scheduleResponse.json();

            if (schedule.items) {
                Object.values(schedule.items).forEach((item) => {
                    const employee = item.employee;

                    const reviews = item.reviews;

                    const doctorCard = generateDoctorCardHTML({
                        doctor: employee,
                        serviceType: "online",
                        reviews,
                        city: "online",
                    });

                    if (!doctorsCards.find((card) => card.doctor.id === employee.id)) {
                        doctorsCards.push({ doctor: employee, card: doctorCard });
                    }
                });
            }
        }

        doctorsCards.forEach(({ card }) => {
            doctorsListEl.prepend(card);
        });

        if (main) {
            main.append(generateChooseCityAppointmentPopup(citiesInfo));

            const chooseAppointmentButton = main.querySelector("[data-appointment='choose']");

            chooseAppointmentButton.addEventListener("click", () => window.openModal("#appointment-choose"));
        }
    } catch (error) {
        notifyErrorWithText(error.message);
    }
}
