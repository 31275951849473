import { initAccordions } from "./accordion/index.js";
import { setDefaultInputListeners } from "./input/index.js";
import { initNestedLists } from "./nested-list/index.js";
import { initTabs } from "./tabs/index.js";
import { initSelect } from "./select/index.js";
import { initSmoothScroll } from "./smooth-scroll/index.js";
import { initCounter } from "./counter/index.js";
import { initModals } from "./modals/index.js";
import { initToggleButtonGroups } from "./toggle-button-group/index.js";
import { initAnswers } from "./answer/index.js";
import { initSegmentControl } from "./segmented-control/index.js";
import { initInputPasswords } from "./input-password/index.js";
import { initFancybox } from "./fancybox/index.js";

export function initUI() {
    setDefaultInputListeners();
    initTabs();
    initAccordions();
    initNestedLists();
    initSelect();
    initSmoothScroll();
    initCounter();
    initModals();
    initToggleButtonGroups();
    initAnswers();
    initSegmentControl();
    initInputPasswords();
    initFancybox();
}

