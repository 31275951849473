import { BodyScroll } from "../../utils/index.js";
import { closeCatalogMenu } from "../catalog-menu/index.js";
import { initHints } from "./search/hints.js";
import { handleSearch } from "./search/index.js";

const header = document.querySelector(".header-full");

const headerSearch = document.querySelector(".header-full__bottom-center-search");

const headerSearchInput = document.querySelector(".header-full__bottom-center-search-input");

const searchMenu = document.querySelector(".search-menu");

const searchButton = document.querySelector(".header-full__search-button");

const searchMenuBackdrop = searchMenu?.querySelector(".search-menu__backdrop");

const catalogMenu = document.querySelector(".catalog-menu");

const closeButton = document.querySelector(".search-menu__close");

const searchMenuInputs = document.querySelectorAll(".search-menu__search-input");

const inputInSearchMenu = document.querySelector(".search-menu .search-menu__search-input");

export function openSearchMenu() {
    searchMenu.classList.add("search-menu--open");
    header.classList.add("header-full--search");
    if (window.innerWidth > 992) {
        headerSearchInput.focus();
    } else {
        inputInSearchMenu.focus();
    }

    if (catalogMenu && catalogMenu.classList.contains("catalog-menu--open")) {
        closeCatalogMenu();
    }
    BodyScroll.lock();
}

export function closeSearchMenu() {
    BodyScroll.unlock();
    searchMenu.classList.remove("search-menu--open");
    header.classList.remove("header-full--search");
}

function searchButtonClick() {
    openSearchMenu();
}

/**
 *
 * @param {string} newValue
 */
export function changeSearchInputValue(newValue) {
    const event = new Event("input");
    searchMenuInputs.forEach((input) => {
        input.value = newValue;
        input.dispatchEvent(event);
    });
}

export function initSearchMenu() {
    if (!headerSearch || !searchMenu) return;

    headerSearch.addEventListener("click", openSearchMenu);

    searchMenuBackdrop.addEventListener("click", closeSearchMenu);

    closeButton.addEventListener("click", closeSearchMenu);

    searchButton.addEventListener("click", searchButtonClick);

    searchMenuInputs.forEach((input) => {
        input.addEventListener("input", (event) => handleSearch(event.target.value));
    });

    initHints();

    handleSearch("");
}
