
import { initEvents, btnShowScroll,filterSync } from './order/index.js';
import { initDownloadModal } from './sale/modal/dowland/index.js';
import { initValidEmail } from './detail/modal/email/index.js';
import { initDetails } from './detail/index.js';
import { initValidPhone } from './detail/modal/phone/index.js';
import { initValidPassword } from './detail/modal/password/index.js';
import { initSwitchBtn } from './addresses/initSwitchBtn/index.js';
import { initSearch } from './addresses/initSearch/index.js';
import { initProfileMenu } from './menu/index.js';
import { initAiSkin } from './ai-skin/index.js';

export function initEventInput() {
  btnShowScroll();
  initEvents();
  initDownloadModal();
  initValidEmail();
  initValidPhone();
  initValidPassword();
  initSwitchBtn();
  initSearch();
  initDetails();
  filterSync();
  initProfileMenu();
  initAiSkin();

  const btnBack = document.querySelector(".profile-section-header__button-back");
  if (!btnBack) return;
  const menu = document.querySelector(".profile-menu");
  const banner = document.querySelector(".profile-banner");
  const header = document.querySelector(".profile-header");
  const content = document.querySelectorAll("[back-btn-hidden]");
  const sectionHeader = document.querySelector(".profile-section-header");
  
  btnBack.addEventListener("click", function () {
    menu.style.display = 'flex';
    header.style.display = 'flex';
    banner.style.display = 'block';
    content.forEach(el => {
      el.style.display = 'none';
    });
    sectionHeader.style.display = 'none';
  });

}
