const BASE_URL = "https://v2024.geltek.ru";

/**
 *
 * @param {string} baseUrl
 * @returns {{
 *      get: typeof fetch,
 *      post: typeof fetch,
 *      put: typeof fetch,
 *      patch: typeof fetch,
 *      delete: typeof fetch,
 * }}
 */
export function fetchAbsolute(baseUrl) {
    /**
     *
     * @param {"GET" | "POST" | "PUT" | "PATCH" | "DELETE"} method 
     * @returns {typeof fetch}
     */
    const methodFetch = (method) => async (url, options) => {
        if (url.startsWith("/")) {
            return fetch(baseUrl + url, {
                ...options,
                method,
            });
        }
        return fetch(url, {
            ...options,
            method,
        });
    };

    return {
        get: methodFetch("GET"),
        post: methodFetch("POST"),
        put: methodFetch("PUT"),
        patch: methodFetch("PATCH"),
        delete: methodFetch("DELETE"),
    };
}

export const baseFetch = fetchAbsolute(BASE_URL);
