import { Fancybox } from "@fancyapps/ui";

export function initFancybox() {
    const els = document.querySelectorAll("[data-fancybox]");

    if (!els.length) return;

    const options = {
        Thumbs: false,
    };

    const galleryNames = [];

    els.forEach(el => {
        const attr = el.getAttribute('data-fancybox');
        if (!galleryNames.includes(attr)) galleryNames.push(attr);
    });

    galleryNames.forEach(galleryName => {
        Fancybox.bind(`[data-fancybox=${galleryName}]`, options);
    });
}
