window.openNoEmail = () => window.openModal(".modal.no-email");

window.openFeedbackModal = () => window.openModal(".modal.feedback-modal");

const feedbackModals = document.querySelectorAll(".modal.feedback-modal");

function initFeedbackModal(feedbackModal) {
    if (!feedbackModal) return;

    const feedbackForm = feedbackModal.querySelector(".feedback-modal__form");

    function onSubmit(event) {
        event.preventDefault();
        feedbackModal.classList.add("feedback-modal--success");

        const timer = setTimeout(() => {
            feedbackModal.classList.remove("feedback-modal--success");
            window.closeModals();
            clearInterval(timer);
        }, 2000);
    }

    feedbackForm.addEventListener("submit", onSubmit);
}

export function initFeedbackModals() {
    feedbackModals.forEach(initFeedbackModal);
}
