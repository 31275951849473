import { openCatalogMenu } from "../catalog-menu/index.js";
import { closeMainMenu } from "../main-menu/index.js";

export function initFooterMobileMenu() {
    const catalogIcon = document.querySelector(".footer__mobile-bottom-menu .icon-menu-cat");

    // const diagnosticCard = document.getElementById("diagnostic-card");

    function catalogClick(event) {
        event.preventDefault();
        closeMainMenu();
        openCatalogMenu();
    }

    // function diagnosticClick(event) {
    //     event.preventDefault();
    //     closeCatalogMenu();
    //     openMainMenu();
    //     if (diagnosticCard) {
    //         diagnosticCard.click();
    //     }
    // }

    if (catalogIcon) {
        const catalogButton = catalogIcon.closest(".footer__mobile-bottom-menu-list-item");

        catalogButton.addEventListener("click", catalogClick);
    }

    const diagnosticIcon = document.querySelector(".footer__mobile-bottom-menu .icon-AI");

    if (diagnosticIcon) {
        const diagnosticButton = diagnosticIcon.closest(".footer__mobile-bottom-menu-list-item");

//        diagnosticButton.addEventListener("click", diagnosticClick);
    }
}
