import { vacanciesForm } from "./form/index.js";
import { initVacanciesFilterModal } from "./vacancies-filter-modal/index.js";
import { initVacanciesFilter } from "./vacancies-filter/index.js";

export function initCareersPageModules() {
    initVacanciesFilter();
    vacanciesForm();
    initVacanciesFilterModal();
}

