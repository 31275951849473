export function initProductOrderOption() {
    const items = document.querySelectorAll('.product-order__info-option');

    if (!items.length) return;

    let activeItem = document.querySelector('.product-order__info-option_active');
    let activeItemImage = document.querySelector('.product-order__images-item_active');

    items.forEach(item => {
        item.addEventListener('click', function(e) {
            activeItem.classList.remove('product-order__info-option_active');
            activeItem = item;
            activeItem.classList.add('product-order__info-option_active');

            activeItemImage.classList.remove('product-order__images-item_active');
            activeItemImage = document.querySelector(item.getAttribute('data-for'));
            activeItemImage.classList.add('product-order__images-item_active');
        })
    })
}