import Swiper from "swiper";

export function initUsefulArticlesSlider() {
    return new Swiper(".useful-articles__articles-list", {
        slidesPerView: "auto",
        spaceBetween: 10,
        slideClass: "useful-articles__articles-list-item",
        breakpoints: {
            992: {
                spaceBetween: 24,
            },
        },
        navigation: {
            prevEl: ".useful-articles-arrows-left",
            nextEl: ".useful-articles-arrows-right",
        },
    });
}
