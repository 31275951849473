export const handleTogglePasswordVisibility = (button) => {
    const passwordInput = button.previousElementSibling.previousElementSibling;
    if (passwordInput.getAttribute('type') === 'password') {
        passwordInput.setAttribute('type', 'text');
        button.querySelector('i').classList.remove('icon-eye-show');
        button.querySelector('i').classList.add('icon-eye-show-off');
    } else {
        passwordInput.setAttribute('type', 'password');
        button.querySelector('i').classList.remove('icon-eye-show-off');
        button.querySelector('i').classList.add('icon-eye-show');
    }
};