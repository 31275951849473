const detectClickOutside = function(element, callback) {
    function handleClick(event) {
        if (!element.contains(event.target)) {
            callback();
        }
    }

    document.addEventListener('click', handleClick);

    return function() {
    document.removeEventListener('click', handleClick);
    };
};

export const lkMore = () => {
    const els = document.querySelectorAll('.profile-ai-skin__more');

    if (!els.length) return;

    els.forEach(el => {
        const btn = el.querySelector('.profile-ai-skin__more-btn');
        const content = el.querySelector('.profile-ai-skin__more-content')

        btn.addEventListener('click', () => {
            content.classList.toggle('profile-ai-skin__more-content_active');
        })

        detectClickOutside(el, () => {
            content.classList.remove('profile-ai-skin__more-content_active');
        })
    })
};
