export const validFormatConfirmPhone = (element, key) => {
    return new Promise((resolve) => {
        const inputs = element.querySelectorAll('.js-profile-confirm-code');
        let code = '';

        inputs.forEach((input, index) => {

            input.addEventListener('input', () => {
                // Ограничиваем ввод одной цифры и перемещаем фокус на следующий инпут
                if (input.value.length > 1) {
                    input.value = input.value.slice(0, 1);
                }

                // Если это не последний инпут, перемещаем фокус на следующий
                if (input.value.length === 1 && index < inputs.length - 1) {
                    inputs[index + 1].focus();
                }

                // Собираем код из всех инпутов
                code = Array.from(inputs).map(input => input.value).join('');

                // Если все инпуты заполнены, возвращаем код через resolve
                if (code.length === inputs.length) {
                    resolve(Number(code));
                }
            });

            // Перемещение назад при нажатии Backspace
            input.addEventListener('keydown', (e) => {
                if (e.key === "Backspace" && input.value.length === 0 && index > 0) {
                    inputs[index - 1].focus();
                }
            });
        });
    });
};
