import * as Yup from 'yup';
import { handleTogglePasswordVisibility } from "./handleTogglePasswordVisibility.js";
let password = "123qwe";

let errors = {
  number: true,
  letter: true,
  minLength: true,
  repeat: true,
};
const minLengthSchema = Yup.string().min(6, 'Длина пароля должна быть не менее 6 символов');
const letterSchema = Yup.string().matches(/[a-zA-Z]/, 'Пароль должен содержать хотя бы одну букву');
const numberSchema = Yup.string().matches(/\d/, 'Пароль должен содержать хотя бы одну цифру');

const repeatPasswordValid = Yup.string()
  .test('is-not-existing', 'Пароль не совпадают', function (value) {
    const { mewPass } = this.options.context;
    return value === mewPass;
  });

export const initValidPassword = () => {

  const modal = document.getElementById("profile-update-password");
  if (!modal) return;

  const togglePasswordVisibilityButtons = modal.querySelectorAll('.js-visible-password');
  const OK = modal.querySelector('.js-password-OK');
  const btn = modal.querySelector('.js-btn');
  const newPasswordTextfield = modal.querySelector('.js-new-password-textfield');
  const newPassword = newPasswordTextfield.querySelector('[new-password]');
  const repeatPasswordTextfield = modal.querySelector('.js-repeat-password-textfield');
  const repeatPassword = repeatPasswordTextfield.querySelector('[repeat-password]');
  const list = modal.querySelector('.js-profile-validation');
  const letter = list.querySelector('.js-valid-letter');
  const minLength = list.querySelector('.js-valid-minLength');
  const number = list.querySelector('.js-valid-number');




  newPassword.addEventListener("input", async function (e) {
    let mewPass = e.target.value;
    let repeatPass = repeatPassword.value;

    try {
      await numberSchema.validate(mewPass);
      errors.number = false;
      number.classList.remove('valid-error');
      number.classList.add('valid-success');
    } catch (error) {
      number.classList.remove('valid-error');
      number.classList.add('valid-success');
      repeatPasswordTextfield.setAttribute("data-error", ' ');
      newPasswordTextfield.setAttribute("data-error", " ");
      errors.number = true;
    }
    try {
      await letterSchema.validate(mewPass);
      letter.classList.remove('valid-error');
      letter.classList.add('valid-success');
      errors.letter = false;
    } catch (error) {
      letter.classList.remove('valid-success');
      letter.classList.add('valid-error');
      repeatPasswordTextfield.setAttribute("data-error", ' ');
      newPasswordTextfield.setAttribute("data-error", " ");
      errors.letter = true;
    }
    try {
      await minLengthSchema.validate(mewPass);
      minLength.classList.remove('valid-error');
      minLength.classList.add('valid-success');
      errors.minLength = false;
    } catch (error) {
      minLength.classList.remove('valid-success');
      minLength.classList.add('valid-error');
      repeatPasswordTextfield.setAttribute("data-error", ' ');
      newPasswordTextfield.setAttribute("data-error", " ");
      errors.minLength = true;
    }
    try {
      await repeatPasswordValid.validate(repeatPass, { context: { mewPass } });
      if (!errors.letter && !errors.minLength && !errors.number) {
        repeatPasswordTextfield.removeAttribute("data-error");
        newPasswordTextfield.removeAttribute("data-error");
      }
      errors.repeat = false;
    } catch (error) {
      repeatPasswordTextfield.setAttribute("data-error", error.message);
      newPasswordTextfield.setAttribute("data-error", " ");
      errors.repeat = true;
    }
  });


  repeatPassword.addEventListener("input", async function (e) {
    let repeatPass = e.target.value;
    let mewPass = newPassword.value;
    try {
      if (errors.repeat) {
        repeatPasswordTextfield.setAttribute("data-error", " ");
      }
      await repeatPasswordValid.validate(repeatPass, { context: { mewPass } });
      errors.repeat = false;
      if (!errors.letter && !errors.minLength && !errors.number) {
        repeatPasswordTextfield.removeAttribute("data-error");
        newPasswordTextfield.removeAttribute("data-error");
      }

    } catch (error) {
      console.log(errors.repeat);
      errors.repeat = true;
      repeatPasswordTextfield.setAttribute("data-error", error.message);
      newPasswordTextfield.setAttribute("data-error", " ");
    }
  });

  togglePasswordVisibilityButtons.forEach(button => {
    button.addEventListener('click', () => handleTogglePasswordVisibility(button));
  });

  btn.addEventListener("click", function () {
    const hasError = Object.values(errors).some(value => value === true);
    if (!hasError) {
      OK.click();
      newPassword.value = '';
      repeatPassword.value = '';
      minLength.classList.remove('valid-success');
      letter.classList.remove('valid-success');
      number.classList.remove('valid-success');
      newPasswordTextfield.removeAttribute("data-error");
      repeatPasswordTextfield.removeAttribute("data-error");
    }
  });
};