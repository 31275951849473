import Swiper from "swiper";

function initSlider(wrapper) {
    const id = wrapper.id;

    return new Swiper(`#${id} .product-slider__list`, {
        slidesPerView: "auto",
        spaceBetween: 12,
        slideClass: "product-slider__list-item",
        navigation: {
            prevEl: `#${id} .product-slider__arrows-left`,
            nextEl: `#${id} .product-slider__arrows-right`,
        },
        breakpoints: {
            992: {
                spaceBetween: 24,
            },
        },
    });
}

export function initProductSliders() {
    const wrappers = document.querySelectorAll(".product-slider");

    wrappers.forEach((wrapper, index) => {
        wrapper.id = `product-slider-${index}`;

        initSlider(wrapper);
    });
}
