import { baseFetch } from "../../base/index.js";

const endpoints = {
    searchProducts: async (queryString) => baseFetch.post("/api/ibc/offer/index.php", {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `method=search&query=${queryString}`,
    }),
};

export default endpoints;
