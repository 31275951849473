
const openedAccordions = document.querySelectorAll(".accordion--open");

/**
 *
 * @param {HTMLElement} accordion element with class accordion
 * @param {Boolean} isTransition if true accordion opens with transion
 */
export function openAccordion(accordion, isTransition = true) {
    const details = accordion.querySelector(".accordion__details");

    if (!details) return;

    const detailsWrapper = details.querySelector(".accordion__details-wrapper");

    if (!detailsWrapper) throw new Error("accordion details wrapper not provided");

    if (isTransition) {
        details.style.transition = "max-height .3s ease";
    } else {
        details.style.transition = "none";
    }

    accordion.classList.add("accordion--open");
    details.style.maxHeight = `${detailsWrapper.clientHeight}px`;
}

/**
 *
 * @param {HTMLElement} accordion element with class accordion
 * @param {Boolean} isTransition if true accordion opens with transion
 */
export function closeAccordion(accordion, isTransition = true, afterTransitionCb = null) {
    const details = accordion.querySelector(".accordion__details");

    if (!details) return;

    const detailsWrapper = details.querySelector(".accordion__details-wrapper");

    if (!detailsWrapper) throw new Error("accordion details wrapper not provided");

    if (isTransition) {
        details.style.transition = "max-height .3s ease";

        const handleTransiotionEnd = function(event) {
            if (afterTransitionCb) afterTransitionCb();
            console.log(event.propertyName);
            details.removeEventListener("transitionend", handleTransiotionEnd);
        };

        details.addEventListener("transitionend", handleTransiotionEnd);
    } else {
        details.style.transition = "none";
    }

    accordion.classList.remove("accordion--open");
    details.style.maxHeight = 0;
}

function onClick(event) {
    const button = event.currentTarget;

    const accordion = button.parentNode;

    if (!accordion) throw new Error("wrong target element");

    const isOpen = accordion.classList.contains("accordion--open");

    if (isOpen) {
        closeAccordion(accordion);
    } else {
        openAccordion(accordion);
    }
}

function initOpenedAccordions() {
    openedAccordions.forEach((accordion) => {
        openAccordion(accordion, false);
    });
}

export function initAccordions() {
    const accordions = document.querySelectorAll(".accordion");

    accordions.forEach((accordion) => {
        const button = accordion.querySelector(".accordion__button");

        if (!button) throw new Error("accordion button not provided");

        button.addEventListener("click", onClick);
    });

    initOpenedAccordions();
}
