import { PasswordCriteries, validatePassword } from "../../../../utils/validation/password/index.js";

window.openNewPass = () => window.openModal(".modal.new-pass");

export function initNewPassModal() {
    const newPassModal = document.querySelector(".modal.new-pass");

    if (!newPassModal) return;

    const password = newPassModal.querySelector(".new-pass__password");

    const repeatPassword = newPassModal.querySelector(".new-pass__repeat-password");

    const saveButton = newPassModal.querySelector(".new-pass__save");

    const elementsByTests = {
        [PasswordCriteries.minLength]: newPassModal.querySelector(".new-pass__min-length"),
        [PasswordCriteries.lowerAndUppercaseLetter]: newPassModal.querySelector(".new-pass__letters"),
        [PasswordCriteries.oneNumber]: newPassModal.querySelector(".new-pass__numbers"),
    };

    function toggleValidationItem(testName, status) {
        const validationItem = elementsByTests[testName];

        if (status) {
            validationItem.classList.remove("valid-error");
            validationItem.classList.add("valid-success");
        } else {
            validationItem.classList.add("valid-error");
            validationItem.classList.remove("valid-success");
        }
    }

    function handleInputValidation() {
        const { isPasswordValid, tests } = validatePassword(password.value);

        Object.entries(tests).forEach(([testName, status]) => {
            toggleValidationItem(testName, status);
        });

        const isRepeatPasswordValid = password.value === repeatPassword.value && repeatPassword.value !== "";

        const repeatPasswordWrapper = repeatPassword.parentElement.parentElement;

        if (isRepeatPasswordValid || repeatPassword.value === "") {
            delete repeatPasswordWrapper.dataset.error;
        } else {
            repeatPasswordWrapper.dataset.error = "Пароли не совпадают";
        }

        if (isPasswordValid && isRepeatPasswordValid) {
            saveButton.disabled = false;
        } else {
            saveButton.disabled = true;
        }
    }

    password.addEventListener("input", handleInputValidation);
    repeatPassword.addEventListener("input", handleInputValidation);
}
