export function initVacanciesFilterModal() {
    const buttons = document.querySelectorAll(".xs-sort-button");
    const modals = document.querySelectorAll(".modal-light");
    if (!buttons) return;
    if (!modals) return;

    function closeAllSortButton() {
        buttons.forEach((button) => {
            button.classList.remove("open");
        });
    }
    function closeAllModal() {
        modals.forEach((modal) => {
            if (modal.classList.contains("open")) {
                modal.classList.remove("open");
            }
        });
    }

    buttons.forEach((button) =>
        button.addEventListener("click", function () {
            const button = this;
            button.classList.toggle("open");

            if (button.classList.contains("open")) {
                // находим нужную модлку сортировки
                const buttonId = button.id.split("-");
                const id = `sort-modal-careers-${
                    buttonId[buttonId.length - 1]
                }`;

                //получаем модалку по Id
                const modal = document.getElementById(id);
                if (!modal) return;
                modal.classList.toggle("open");

                // логика Options
                const options = modal.querySelectorAll(
                    ".vacancies-filter__item input"
                );
                if (!options) return;
                options.forEach((option) => {
                    option.addEventListener("change", function () {
                        if (this.checked) {
                            const label = this.closest(
                                ".vacancies-filter__item"
                            ).querySelector(".vacancies-filter__label");
                            // Изменяем только текстовый узел кнопки
                            button.childNodes[0].textContent =
                                label.textContent.trim();
                        } else {
                            console.warn(
                                "Элемент с классом 'vacancies-filter__label' не найден"
                            );
                        }
                    });
                });
            }
        })
    );

    const modalCloseButtons = document.querySelectorAll(
        ".vacancies-modal-filter__close"
    );

    if (!modalCloseButtons) return;

    modalCloseButtons.forEach((button) => {
        button.addEventListener("click", function () {
            closeAllModal();
            closeAllSortButton();
        });
    });

    // закрытие по оверлею
    modals.forEach((modal) => {
        modal.addEventListener("click", function (event) {
            if (event.target === this) {
                closeAllModal();
                closeAllSortButton();
            }
        });
    });
}

