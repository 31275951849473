export function initClinicOrder() {
    const el = document.querySelector("#clinic-form");

    if (!el) return;

    const errBlock  = document.querySelector('#clinic-modal-err .modal-bottom__err-msg');
    let formIsSending = false;

    function validate(el) {
        let err = 0;

        const clinicFree = el.querySelector('#clinic-consult:not(.visually-hidden) input');
        clinicFree?.classList.toggle('input--checkbox--err', !clinicFree.checked)
        if (clinicFree && !clinicFree.checked) err++;

        const agree = el.querySelector('[name="agree"]');
        agree.classList.toggle('input--checkbox--err', !agree.checked)
        if (!agree.checked) err++;

        const selects = el.querySelectorAll('.select');
        selects.forEach(select => {
            const realSelect = select.querySelector('select')
            select.classList.toggle('select--err', !realSelect.value.length)
            if (!realSelect.value.length) err++;
        })

        const calendar = el.querySelector('.modal-clinic__calendar-value');
        const calendarErrText = el.querySelector('.modal-clinic__calendar-invalid-text');

        if (!calendar.value.length) {
            calendarErrText.textContent = 'Выберите дату';
        }
        else {
            calendarErrText.textContent = '';
        }

        // если валиден то вернет true
        return (err==0);
    }

    
    // const services = el.querySelector('#clinic-service');
    // const openService = initiator.getAttribute('data-service-id');

    // async function setServices() {
    // 	async function fetchServices() {
    // 		const url = '/clinic/api/client/services';
    // 		// const url = 'files/days-by-period.json';
            
    // 		try {
    // 				const response = await fetch(url);
    // 				if (!response.ok) {
    // 						throw new Error(`HTTP error! status: ${response.status}`);
    // 				}
    
    // 				const data = await response.json();
    // 				if (data.err === 0) {
    // 						return data.data.map(data => {return {value: data.id, label: data.name}});
    // 				} else {
    // 						console.error('Ошибка в ответе API:', data.err);
    // 				}
    // 		} catch (error) {
    // 				console.error('Ошибка при запросе:', error);
    // 		}
    // 	}

    // 	const servicesList = await fetchServices();

    // 	services.choices.clearChoices();
    // 	services.choices.setChoices(
    // 		servicesList,
    // 		'value',
    // 		'label',
    // 		false,
    // 	);

    // 	// Это надо если услуги прогрузились уже после открытия модалки

    // 	const openService = services.openService;

    // 	if (services.querySelector('.choices__placeholder')) {
    // 		services.querySelector('.choices__placeholder').textContent = 'Загрузка услуг...';
    // 	}

    // 	if (openService) {
    // 		services.choices.setChoiceByValue(parseInt(openService));
    // 	}
    // 	else {
    // 		if (services.querySelector('.choices__placeholder'))
    // 			services.querySelector('.choices__placeholder').textContent = 'Выберите услугу';
    // 	}

    // 	// console.log('Услуги заполнены');
    // 	// console.log(openService);
        
    // }

    // setServices();

    el.addEventListener('submit', e => {
        e.preventDefault();

        if (!validate(el)) {
            errBlock.textContent = 'Проверьте корректность введенных вами данных';
            window.openModal('#clinic-modal-err');

            return;
        }

        if (formIsSending) return;

        formIsSending = true;

        const btn = el.querySelector('#clinic-form-btn');
        const btnText = btn.textContent;
        const loader = el.querySelector('.modal-clinic__loader')

        btn.textContent = 'Отправка...';
        loader.classList.add('modal-clinic__loader_active')
        
        const url = '//geltek.ru/clinic/api/booking/';
        
        const formData = new FormData(el);
        const jsonData = {};
        
        // Преобразуем данные формы в JSON
        formData.forEach((value, key) => {
            jsonData[key] = value;
        });

        try {
            // Отправляем запрос
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': "application/json; charset=UTF-8"
                },
                body: JSON.stringify(jsonData),
            })
            .then(resp => {
                const result = resp.json();
                return result;
            })
            .then(result => {
                btn.textContent = btnText;
                formIsSending = false;
                loader.classList.remove('modal-clinic__loader_active')
                
                if (result.err == 0) {
                    // успех
                    window.openModal('#success');
                    try { ym(98540075,'reachGoal','FULLFORM_SUCCESS'); } catch(e) {};
                }
                else {
                    if (result.err == 109) {
                        console.log('дата уже забронирована');
                        window.initCalendar()
                    }
                    // console.log('result:', result);
                    // console.log('result:', result.clientMessage);
                    errBlock.textContent = result.clientMessage;
                    window.openModal('#clinic-modal-err');
                }
            })
        } catch (error) {
            console.error('Error:', error);
            // document.getElementById('responseMessage').textContent = 'Error: Failed to send data.';
        }
    })
}
