import * as Yup from "yup";

import { initConfirmPhone } from "./initConfirmPhone.js";

const existingPhoneNumber = "+79998889999";
const phoneSchema = Yup.string()
    .matches(/^\+7\d{10}$/, "Пожалуйста, введите корректный номер телефона ")
    .test(
        "is-not-existing",
        "Этот номер уже зарегистрирован в системе. Пожалуйста, используйте другой номер телефона.",
        function (value) {
            return value !== existingPhoneNumber;
        }
    );

export const initValidPhone = () => {
    const modal = document.getElementById("profile-update-phone");
    if (!modal) return;
    const btn = modal.querySelector(".js-btn");
    const wrapperInput = modal.querySelector(".profile-input--text");
    const input = wrapperInput.querySelector(".js-input");

    input.addEventListener("input", async function () {
        const phone = input.value;
        try {
            await phoneSchema.validate(phone.replace(/\s+/g, ""));
            wrapperInput.removeAttribute("data-error");
            btn.disabled = false;
        } catch (error) {
            btn.disabled = true;
            wrapperInput.setAttribute("data-error", error.message);
        }
    });

    btn.addEventListener("click", function () {
        initConfirmPhone();
        input.value = ""; 
        input.removeAttribute("value");
    });
};
