import Swiper from "swiper";

export function initArticlesSlider() {
    return new Swiper(".articles__slider .swiper", {
        slidesPerView: "auto",
        spaceBetween: 12,

        navigation: {
            prevEl: ".articles__slider-prev",
            nextEl: ".articles__slider-next",
        },

        breakpoints: {
            992: {
                slidesPerView: 3,
                spaceBetween: 24,
            }
        }
    });
}
