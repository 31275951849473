import { Calendar } from 'fullcalendar';
import dayGridPlugin from '@fullcalendar/daygrid';
import ruLocale from '@fullcalendar/core/locales/ru';

export function initClinicCalendar() {
    const el = document.querySelector(".modal-clinic__calendar");

    if (!el) return;

    const calendarView = el.querySelector('.modal-clinic__calendar-view');

    const dateKeeper = el.querySelector('.modal-clinic__calendar-value');
    const loader = el.querySelector('.modal-clinic__calendar-loader');
    let lastDateRange = null;

    async function fetchDates(isSpecialist) {
        const url = '/clinic/api/client/schedules/days-by-period-new';
        // const url = 'files/days-by-period-new.json';

        const service = document.querySelector('#clinic-service');
        const doctor = document.querySelector('#clinic-doctors');
        // service.choices;
        // console.log();

        const params = isSpecialist?
            {
                doctor_id: doctor.choices.getValue(true)
            }:
            {
                service_id: service.choices.getValue(true)
            };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams(params)
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            if (data.err === 0) {
                return data.data;
            } else {
                console.error('Ошибка в ответе API:', data.err);
            }
        } catch (error) {
            console.error('Ошибка при запросе:', error);
        }
    }

    function formatDateToString(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Месяцы с 0
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    async function initCalendar() {
        loader.classList.add('modal-clinic__calendar-loader_active')
        let availableDatas;

        // const timeEl = document.querySelector('#clinic-time');
        // timeEl.choices.destroy();
        // timeEl.choices.init();

        // dateKeeper.removeAttribute("value");
        const timeContainer = document.querySelector('#clinic-time-block');
        const timeEl = document.querySelector('#clinic-time');
        const doctorsContainer = document.querySelector('#clinic-doctors-block');
        const doctorsEl = document.querySelector('#clinic-doctors');
        const serviceContainer = document.querySelector('#clinic-service-block');
        const serviceEl = document.querySelector('#clinic-service');
            
        const clinicConsult = document.querySelector('#clinic-consult');

        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                    if (mutation.attributeName === 'class') {
                            const hasClass = serviceContainer.classList.contains('visually-hidden');
                            if (hasClass) {
                                clinicConsult.classList.add('visually-hidden');
                            }
                    }
            }
        });
        
        observer.observe(serviceContainer, {
            attributes: true, // следить за изменениями атрибутов
            attributeFilter: ['class'], // отслеживать только изменения класса
        });
        
        const calendar = new Calendar(calendarView, {
            plugins: [dayGridPlugin],
            initialView: "dayGridMonth",
            locale: ruLocale,
            headerToolbar: {
                start: "prev", // will normally be on the left. if RTL, will be on the right
                center: "title",
                end: "next",
            },
            titleFormat: {
                year: "numeric",
                month: "long",
            },
            datesSet: async (info) => {
                const titleElement = el.querySelector('.fc-toolbar-title');
                const title = info.view.title.replace(' г.', ''); // Удаляем "г."
                titleElement.textContent = title; // Обновляем содержимое заголовка

                const newDateRange = { start: info.startStr, end: info.endStr };

                const days = document.querySelectorAll('.fc-daygrid-day');

                days.forEach((el) => {
                    const date = new Date(el.dataset.date);
                    const dateStr = formatDateToString(date);

                    el.classList.add('fc-day-disabled');
                    el.classList.remove('fc-day-active');
                });

                loader.classList.add('modal-clinic__calendar-loader_active');

                async function getAvailableDatas() {
                    const isSpecialist = !!document.querySelector('.modal-clinic__dynamic-part--specialist');

                    if (!lastDateRange || lastDateRange && lastDateRange.start === newDateRange.start && lastDateRange.end === newDateRange.end) {
                        // console.log('грузим даты');
                        
                        availableDatas = await fetchDates(isSpecialist);

                        // console.log('даты поменялись из-за загрузки календаря');

                        return availableDatas;
                    }
                    else {
                        availableDatas = availableDatas?availableDatas:await fetchDates(isSpecialist);
                        // console.log('даты поменялись из-за переключения месяца');

                        serviceContainer.classList.remove('visually-hidden');
                        doctorsContainer.classList.remove('visually-hidden');
        
                        // let isSpecialist = !!initiator.getAttribute('data-specialist-id');
        
                        if (isSpecialist) {
                                serviceContainer.classList.add('visually-hidden');
                        } else {
                                doctorsContainer.classList.add('visually-hidden');
                        }
        
                        timeContainer.classList.add('visually-hidden');
                        
                        return availableDatas;
                    }
                }

                availableDatas = await getAvailableDatas();

                const availableDates = availableDatas.map(obj => Object.keys(obj)[0]);
                // console.log(availableDates);
                dateKeeper.removeAttribute("value");

                days.forEach((el) => {
                    const date = new Date(el.dataset.date);
                    const dateStr = formatDateToString(date);

                    if (availableDates.includes(dateStr)) {
                        el.classList.remove('fc-day-disabled');
                    }
                    else {
                        el.classList.remove("fc-day-active");
                    }
                });

                loader.classList.remove('modal-clinic__calendar-loader_active')

                lastDateRange = newDateRange;
            },
            dateClick: async (info) => {
                if (info.dayEl.classList.contains("fc-day-disabled")) return;

                el.querySelectorAll(".fc-daygrid-day").forEach((el) => {
                    el.classList.remove("fc-day-active");
                });

                info.dayEl.classList.add("fc-day-active");

                dateKeeper.value = info.dateStr;

                
                let isSpecialist = !!document.querySelector('.modal-clinic__dynamic-part--specialist');


                if (isSpecialist) {
                    // console.log(availableDatas);

                    async function fetchServices() {
                        const url = '/clinic/api/client/services';
                        // const url = 'files/services.json';
                        
                        try {
                            const response = await fetch(url, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                },
                                body: new URLSearchParams({
                                    doctor_id: doctorsEl.choices.getValue(true)
                                })
                            });
                            if (!response.ok) {
                                throw new Error(`HTTP error! status: ${response.status}`);
                            }
            
                            const data = await response.json();
                            if (data.err === 0) {
                                return data.data.map(data => {return {value: data.id, label: data.name}});
                            } else {
                                console.error('Ошибка в ответе API:', data.err);
                            }
                        } catch (error) {
                            console.error('Ошибка при запросе:', error);
                        }
                    }

                    const doctorServices = await fetchServices();
                    
                    // console.log(doctorServices);
                    const mappedDoctorServices = doctorServices.map(service => {
                        return {value: service.value, label: service.label}
                    })
                    

                    const doctorsInDate = availableDatas.find(obj => obj[info.dateStr])[info.dateStr];

                    const mappedDoctorsInDate = doctorsInDate.map(doctor => {
                        return {value: doctor.name, label: doctor.name}
                    })

                    // console.log(mappedServicesInDate);
                    

                    timeContainer.classList.add('visually-hidden')
                    timeEl.choices.destroy();
                    timeEl.choices.init();

                    serviceEl.choices.clearChoices();

                    serviceEl.choices.destroy();
                    serviceEl.choices.init();

                    serviceEl.choices.setChoices(
                        mappedDoctorServices,
                        'value',
                        'label',
                        false,
                    );

                    serviceEl.choices.passedElement.element.removeEventListener(
                        'change',
                        serviceEl.choicesChangeFunc,
                        false,
                    );
									
                    clinicConsult.classList.add('visually-hidden')

                    function choicesChangeFunc(value) {
                        let doctorDates = doctorsInDate.find(obj => obj.name === doctorsEl.choices.getValue().label);
                        
                        console.log(doctorDates);

                        doctorDates = doctorDates?doctorDates.dates:false;

                        console.log(doctorDates);

                        const mappedDoctorDates = doctorDates.map(date => {
                            return {value: date, label: date}
                        })
                        timeEl.choices.clearChoices();

                        timeEl.choices.destroy()
                        timeEl.choices.init()
                        
                        timeEl.choices.setChoices(
                            mappedDoctorDates,
                            'value',
                            'label',
                            false,
                        );

                        if (isSpecialist) {
                            timeContainer.classList.remove('visually-hidden')
                        }

                        if (isSpecialist) {
                            console.log(value);
                            
                                if (value) {
                                        const selectedValue = value.target.value; // Получаем значение выбранного элемента
                                     
                                        clinicConsult.classList.add('visually-hidden')

                                        if (selectedValue == 10 || selectedValue == 15) {
                                                clinicConsult.classList.remove('visually-hidden')
                                        }
                                }
                                
                        }
                    };

                    serviceEl.choicesChangeFunc = choicesChangeFunc;

                    serviceEl.choices.passedElement.element.addEventListener(
                        'change',
                        serviceEl.choicesChangeFunc,
                        false,
                    );

                    serviceContainer.classList.remove('visually-hidden')
                }
                else {
                    const doctorsInDate = availableDatas.find(obj => obj[info.dateStr])[info.dateStr];

                    const mappedDoctorsInDate = doctorsInDate.map(doctor => {
                        return {value: doctor.name, label: doctor.name}
                    })

                    timeContainer.classList.add('visually-hidden')
                    timeEl.choices.destroy();
                    timeEl.choices.init();

                    doctorsEl.choices.clearChoices();

                    doctorsEl.choices.destroy();
                    doctorsEl.choices.init();

                    doctorsEl.choices.setChoices(
                        mappedDoctorsInDate,
                        'value',
                        'label',
                        false,
                    );

                    doctorsEl.choices.passedElement.element.removeEventListener(
                        'change',
                        doctorsEl.choicesChangeFunc,
                        false,
                    );

                    function choicesChangeFunc(value) {
                        let doctorDates = doctorsInDate.find(obj => obj.name === value.detail.value);

                        doctorDates = doctorDates.dates?doctorDates.dates:false;

                        const mappedDoctorDates = doctorDates.map(date => {
                            return {value: date, label: date}
                        })
                        timeEl.choices.clearChoices();

                        timeEl.choices.destroy()
                        timeEl.choices.init()
                        
                        timeEl.choices.setChoices(
                            mappedDoctorDates,
                            'value',
                            'label',
                            false,
                        );

                        if (!isSpecialist) {
                            timeContainer.classList.remove('visually-hidden')
                        }
                    };

                    doctorsEl.choicesChangeFunc = choicesChangeFunc;

                    doctorsEl.choices.passedElement.element.addEventListener(
                        'change',
                        doctorsEl.choicesChangeFunc,
                        false,
                    );

                    doctorsContainer.classList.remove('visually-hidden')
                }
            },
        });

        el.calendar = calendar;

        // calendar.render();
    }

    window.initCalendar = initCalendar;

    initCalendar();
};