import { initHeader } from "./header/index.js";
import { initMainMenu } from "./main-menu/index.js";
import { initNotifications } from "./notifications/index.js";
import { initCatalogMenu } from "./catalog-menu/index.js";
import { initCategoriesSliders } from "./categories-slider/index.js";
import { initBannerSliders } from "./banner-slider/index.js";
import { initCategoryBannerSliders } from "./category-banner-slider/index.js";
import { initProductListSlidersWithTabs } from "./product-list-slider-with-tabs/index.js";
import { initCategoryArticlesSliders } from "./category-articles-slider/index.js";
import { initFileUploads } from "./image-upload/image-upload.js";
import { initProductCards } from "./product-card/index.js";
import { initCommonModals } from "./common-modals/index.js";
import { initCustomRadioInput } from "./radio-group/index.js";
import { initCodeVerifications } from "./code-verificaton/index.js";
import { initWebinars } from "./master-class-webinar/index.js";
import { initStorysetList } from "./storyset/index.js";
import { initSearchMenu } from "./search-menu/index.js";
import { initSearchCards } from "./search-card/index.js";
import { initMobileCatalogMenu } from "./catalog-menu/mobile/index.js";
import { initFooterMobileMenu } from "./footer-mobile-menu/index.js";
import { initSignInWidget } from "./sign-in/index.js";
import { initBannerTextSlider } from "./banner-text/index.js";
import { initClinicHeader } from "./header-clinic/index.js";

export function initModules() {
    initHeader();
    initMainMenu();
    initCatalogMenu();
    initMobileCatalogMenu();
    initNotifications();
    initCategoriesSliders();
    initBannerSliders();
    initCategoryBannerSliders();
    initProductListSlidersWithTabs();
    initCategoryArticlesSliders();
    initFileUploads();
    initCustomRadioInput();
    initProductCards();
    initCommonModals();
    initCodeVerifications();
    initWebinars();
    initStorysetList();
    initSearchMenu();
    initSearchCards();
    initFooterMobileMenu();
    initSignInWidget();
    initBannerTextSlider();
    initClinicHeader();
}
