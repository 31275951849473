import * as Yup from "yup";

const emailSchema = Yup.string()
    .email("Пожалуйста, введите корректный e-mail.")
    .required("Пожалуйста, введите e-mail");

export const initValidEmail = () => {
    const modal = document.getElementById("profile-update-email");
    if (!modal) return;
    const valuePage = document.querySelector(".js-profile-show-email");
    const valueModal = modal.querySelector(".js-current-email");

    const btn = modal.querySelector(".js-btn");
    const wrapperInput = modal.querySelector(".profile-input--text");
    const input = wrapperInput.querySelector(".js-input");

    input.addEventListener("input", async function () {
        try {
            await emailSchema.validate(input.value);
            wrapperInput.removeAttribute("data-error");
            btn.disabled = false;
        } catch (error) {
            btn.disabled = true;
            wrapperInput.setAttribute("data-error", error.message);
        }
    });
	
};
