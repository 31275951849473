export function initDescShorter() {
    const btn = document.querySelector(".detail__accordion-desc-shorter-btn");

    if (!btn) return;

    const btnTextBlock = btn.querySelector(".detail__accordion-desc-shorter-btn-text");
    const btnText = btnTextBlock.textContent;
    const btnAlternate = btn.getAttribute("data-alternate");
    const el = document.querySelector(".detail__accordion-desc-shorter");
    let show = false;

    btn.addEventListener("click", () => {
        show = !show;

        btnTextBlock.textContent = show ? btnAlternate : btnText;
        btn.classList.toggle("detail__accordion-desc-shorter-btn--alternate", show);
        el.classList.toggle("detail__accordion-desc-shorter--short", !show);
    });
}
