export const initSearch = () => {
  const modal = document.getElementById("profile-add-address");
  if (!modal) return;

  const searchInput = modal.querySelector('.js-input-search');
  const searchResult = modal.querySelector('.js-result-search');
  const searchWrapper = modal.querySelector('.js-wrapper-search');

  const array = [
    'Москва, ул. Аеговая, дом 1А',
    'Москва, ул. Беговая, дом 23',
    'Москва, ул. Веговая, дом 26',
    'Москва, ул. Геговая, дом 26',
    'Москва, ул. Деговая, дом 27',
    'Москва, ул. Ееговая, дом 29',
    'Москва, ул. Ёговая, дом 29',
    'ул. Беговая, Нижегородская область, Нижний новогород'
  ];

  console.log("Список адресов", array);

  searchInput.addEventListener('input', function () {

    const query = searchInput.value.toLowerCase();
    searchResult.innerHTML = '';

    let hasMatches = false;
    if (query.length >= 2) {
      array.forEach(item => {
        if (item.toLowerCase().includes(query)) {
          const highlighted = highlightMatch(item, query);
          const listItem = document.createElement('li');
          listItem.innerHTML = highlighted;
          listItem.classList.add("p");
          listItem.addEventListener('click', () => {
            searchInput.value = item;
            searchResult.innerHTML = '';
          });
          searchResult.appendChild(listItem);
          hasMatches = true;
        }
      });

      if (!hasMatches) {
        searchWrapper.setAttribute("data-error", ' ');
      } else {
        searchWrapper.removeAttribute("data-error");

      }
    }
  });

  function highlightMatch(text, match) {
    const regex = new RegExp(`(${match})`, 'gi');
    return text.replace(regex, '<span>$1</span>');
  }
}; 