/* eslint-disable indent */
/* eslint-disable max-len */
/**
 * @typedef {{
*      id: string,
*      title: string,
*      detailPageUrl: string,
*      imageUrl: string,
*      price: number,
*      fullprice: number,
*      addToCartUrl: string,
*      marketplaces: {
*          [key: string]: {
*              title: string,
*              link: string,
*          }
*      },
*      classStr: string 
* }} Product
*/
// `
//         <li id="${id}" class="product-card 
//             ${product.pieces_per_pack ? "product-card--piece-per-pack" : ""} 
//             ${product.not_available ? "product-card--not-available" : ""} 
//             ${product.is_favorite ? "product-card--favorite" : ""} 
//             ${product.class || ""}">
            
//             <div class="card product-card__wrapper">
//                 <div class="product-card__header">
//                     ${product.is_new ? `
//                     <div class="chip product-card__header-status">
//                         Новинка
//                     </div>` : ""}
//                     <button class="button--ghost product-card__header-heart">
//                         ${product.is_favorite
//         ? "<i class=\"icon-heart-filled\"></i>"
//         : "<i class=\"icon-heart\"></i>"}
//                     </button>
//                 </div>
                
//                 <div class="product-card__image-wrapper">
//                     <a href="${product.detail_href}" class="product-card__link">
//                         <img src="${product.image_url}" alt="${product.title}" class="product-card__image">
//                     </a>
//                 </div>
                
//                 <div class="product-card__content">
//                     <div class="product-card__content-heading">
//                         ${!product.hide_raiting ? `
//                         <div class="product-card__content-heading-raiting">
//                             <div class="product-card__content-heading-raiting-star">
//                                 <i class="icon-star-filled product-card__content-heading-raiting-icon"></i>
//                                 ${product.raiting.raiting_star}
//                             </div>
//                             <div class="product-card__content-heading-raiting-reviews">
//                                 <span>${product.raiting.raiting_rewiews}</span> <span>отзывов</span>
//                             </div>
//                         </div>` : ""}
                        
//                         <a href="${product.detail_href}" class="product-card__link">
//                             <h5 class="h5 product-card__content-heading-title">
//                                 ${product.title}
//                             </h5>
//                         </a>
//                     </div>

//                     <div class="product-card__content-info">
//                         <div class="product-card__content-price">
//                             ${product.pieces_per_pack ? `
//                             <div class="product-card__content-pieces-per-pack-notice">
//                                 Цена за шт.
//                             </div>` : ""}
//                             <div class="product-card__content-price-wrapper">
//                                 <div class="product-card__content-price-main">${product.price}</div>
//                                 <div class="product-card__content-price-discount">${product.discount || ""}</div>
//                             </div>
//                             ${product.pieces_per_pack ? `
//                             <div class="product-card__content-pieces-per-pack">
//                                 <span>${product.pieces_per_pack} шт. в упаковке</span>
//                                 <span>|</span>
//                                 <span>${product.pack_price || ""} ₽ / уп.</span>
//                             </div>
//                             <div class="product-card__content-pieces-per-pack--mobile"> 
//                                 <span>${product.pieces_per_pack} шт. в уп.</span>
//                                 <span>${product.pack_price || ""} ₽ / уп.</span>
//                             </div>` : ""}
//                         </div>

//                         <div class="toggle-button-group product-card__content-variants">
//                             ${
//                                 product.variants.map((variant,index) => (
//                                     `
//                                     <button class="toggle-button-group__button ${index === 0 && "toggle-button-group__button--selected"} product-card__content-variants-item">
//                                         <span>${variant}</span>
//                                     </button>
//                                     `
//                                 )).join(" ")
//                             }
                    
//                             <a href="${product.detail_href}" class="toggle-button-group__button toggle-button-group__hidden-count product-card__content-variants-item">
//                                 <span>+3</span>
//                             </a>
//                         </div>

//                         <div class="product-card__content-buttons">
//                             <div class="product-card__content-buttons-wrapper">
//                                 ${product.not_available ? `
//                                 <button data-open-modal="#subscribe-product" class="btn--S btn-secondary-light product-card__content-buttons-button">
//                                     <span>Подписаться</span>
//                                 </button>` : `
//                                 <button class="btn--S btn-primary-light product-card__content-buttons-button product-card__content-buttons-to-cart add-to-cart">
//                                     <i class="icon-bag"></i>
//                                     <span>В корзину</span>
//                                 </button>
//                                 <div class="counter product-card__counter">
//                                     <button class="counter__btn counter__btn--minus" disabled="">
//                                         minus
//                                     </button>
//                                     <input type="text" class="counter__input" value="1">
//                                     <button class="counter__btn counter__btn--plus">
//                                         plus
//                                     </button>
//                                 </div>`}
                                
//                                 ${product.ozon ? `<a href="${product.ozon}" class="btn--S btn-primary-light product-card__content-buttons-button product-card__content-buttons-ozon">Ozon</a>` : ""}
//                                 ${product.wildberries ? `<a href="${product.wildberries}" class="btn--S btn-primary-light product-card__content-buttons-button product-card__content-buttons-wildberries">Wildberries</a>` : ""}
//                             </div>
                            
//                             <div class="accordion product-card__content-collapsed-buttons">
//                                 <div class="accordion__details product-card__content-collapsed-buttons-wrapper">
//                                     <div class="accordion__details-wrapper">
//                                         ${product.goldapple ? `<a href="${product.goldapple}" class="btn--S btn-primary-light product-card__content-buttons-button product-card__content-buttons-goldapple">Золотое Яблоко</a>` : ""}
//                                         ${product.yandex ? `<a href="${product.yandex}" class="btn--S btn-primary-light product-card__content-buttons-button product-card__content-buttons-yandex">Яндекс Маркет</a>` : ""}
//                                         ${product.letu ? `<a href="${product.letu}" class="btn--S btn-primary-light product-card__content-buttons-button product-card__content-buttons-letu">Лэтуаль</a>` : ""}
//                                         ${product.rivegauche ? `<a href="${product.rivegauche}" class="btn--S btn-primary-light product-card__content-buttons-button product-card__content-buttons-rivegauche">Рив Гош</a>` : ""}
//                                         ${product.megamarket ? `<a href="${product.megamarket}" class="btn--S btn-primary-light product-card__content-buttons-button product-card__content-buttons-megamarket">Мегамаркет</a>` : ""}
//                                         ${product.apteka ? `<a href="${product.apteka}" class="btn--S btn-primary-light product-card__content-buttons-button product-card__content-buttons-apteka">Аптека.ru</a>` : ""}
//                                         ${product.detmir ? `<a href="${product.detmir}" class="btn--S btn-primary-light product-card__content-buttons-button product-card__content-buttons-detmir">Детский мир</a>` : ""}
//                                     </div>
//                                 </div>
//                                 <button class="btn--S btn-ghost-light accordion__button product-card__content-buttons-button product-card__content-buttons-more">
//                                     <span>Показать ещё</span>
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </li>
//     `;
/**
 *
 * @param {Product} product
 */
export function generateProductCardHTML({
    id,
    title,
    detailPageUrl,
    imageUrl,
    price,
    fullprice,
    addToCartUrl,
    marketplaces,
    classStr,
}) {
    return `
        <li id="${id}" class="product-card ${classStr}"> 
            <div class="card product-card__wrapper">
                <div class="product-card__header">
                
                </div>
                
                <div class="product-card__image-wrapper">
                    <a href="${detailPageUrl}" target="_blank" class="product-card__link">
                        <img src="https://v2024.geltek.ru${imageUrl}" alt="${title}" class="product-card__image">
                    </a>
                </div>
                
                <div class="product-card__content">
                    <div class="product-card__content-heading">
                        <a href="${detailPageUrl}" class="product-card__link">
                            <h5 class="h5 product-card__content-heading-title">
                                ${title}
                            </h5>
                        </a>
                    </div>

                    <div class="product-card__content-info">
                        <div class="product-card__content-price">
                            
                            <div class="product-card__content-price-wrapper">
                                <div class="product-card__content-price-main">${price} ₽</div>
                                <div class="product-card__content-price-discount">${fullprice} ₽</div>
                            </div>
                        
                        </div>

                        

                        <div class="product-card__content-buttons">
                            <div class="product-card__content-buttons-wrapper">
                                <a href="${detailPageUrl}" target="_blank" class="btn--S btn-secondary-light product-card__content-buttons-button">Подробнее</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    `;
}
